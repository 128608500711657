import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { AccountApprovalRequest } from '../../types/account'
import api from '../../api'

const useAcceptAccount = (accountRequestID: string) => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (accountApproval: AccountApprovalRequest) => {
			return api.accounts.acceptAccount(accountApproval)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['accounts'] })
			queryClient.invalidateQueries({
				queryKey: ['accounts', accountRequestID]
			})
		}
	})
}

export default useAcceptAccount
