import { FC, useEffect } from "react";
import { Form, Container, SpaceBetween, Button, ColumnLayout } from '@cloudscape-design/components';
import { FormProps } from "./FormProps";
import { RhocpTenantApprovalRequest } from "../../../types/rhocpTenant";
import { useOktaAuth } from "@okta/okta-react";
import { useForm } from "react-hook-form";
import { useApprovalFormStore } from "../../../stores/rhocpTenants/approvalFormStore";
import FormInput from "../../../components/ControlledFormInputs/Input";
import useAcceptRhocpTenant from "../../../hooks/mutate/useAcceptRhocpTenant";

export const ApprovalForm: FC<FormProps> = ({ showForm, tenantID }) => {
  const { authState } = useOktaAuth()
  const userEmail = authState?.idToken?.claims.email as string

  const {
    formValues,
    actions: {
      clearFormValues,
      setFormValues
    }
  } = useApprovalFormStore()

  const { mutate: acceptRhocpTenant, isLoading } = useAcceptRhocpTenant(tenantID)

  const {
    control,
    trigger,
    setValue,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    setValue('approverID', userEmail)
  }, [setValue, userEmail])

  const handleApproval = () => {
    trigger()

    if (isValid) {
      const { approverID, ...finalFormValues } = formValues
      const approvalRequest: RhocpTenantApprovalRequest = {
        rhocpTenantID: tenantID,
        approverID: userEmail,
        action: 'APPROVE',
        details: finalFormValues,
        timestamp: new Date().toISOString()
      }

      acceptRhocpTenant(approvalRequest, {
        onSuccess: () => {
          showForm(false)
          clearFormValues()
        }
      })
    }
  }

  return (
    <Form
      actions={<SpaceBetween direction="horizontal" size="xs">
        <Button variant="link" onClick={() => showForm(false)}>Cancel</Button>
        <Button variant="primary" onClick={handleApproval} loading={isLoading}>Approve</Button>
      </SpaceBetween>}>
      <Container>
        <SpaceBetween direction="vertical" size="l">
          <ColumnLayout columns={2} variant="default">
            <FormInput
              name="jiraTicket"
              label="Jira Ticket"
              control={control}
              setFormValues={setFormValues}
              rules={{
                required: 'Please enter a Jira Ticket',
                pattern: {
                  value: /[0-0A-Z]+-[0-9]/,
                  message: 'Must be a valid Jira Ticket'
                }
              }} />
            <FormInput
              name="approverID"
              label="Approver"
              control={control}
              setFormValues={setFormValues}
              disabled />
          </ColumnLayout>
        </SpaceBetween>
      </Container>
    </Form>
  );
};
