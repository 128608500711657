import { useQuery } from "@tanstack/react-query";

import type { RhocpTenant } from "../../types/rhocpTenant";
import api from "../../api";

const useSingleRhocpTenant = (rhocpTenantID: string) => {
  return useQuery({
    queryKey: ['rhocpTenant', rhocpTenantID],
    queryFn: () => api.rhocpTenants.getSingleRhocpTenant(rhocpTenantID, true),
    select(data) {
      if (data.body !== undefined) {
        return data.body as RhocpTenant
      }
    }
  })
}

export default useSingleRhocpTenant