import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { UpdateAccessRequest } from '../../types/access'
import api from '../../api'

const useUpdatePermissionsForUser = (userEmail: string) => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (updateAccessRequest: UpdateAccessRequest) => {
			return api.accessManagement.updatePermissionsForUser(updateAccessRequest)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['allUserPermissions']
			})
			queryClient.invalidateQueries({
				queryKey: ['userPermissions', userEmail]
			})
		}
	})
}

export default useUpdatePermissionsForUser
