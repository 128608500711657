import { FC } from 'react'
import { KeyValuePairs } from '@aws-northstar/ui'
import {
    Box,
    Container,
    Header,
    SpaceBetween
} from '@cloudscape-design/components'
 
import type { ProjectApproval } from '../../../types/projects'
 
interface ApprovalDetailsProps {
    approvalDetails: ProjectApproval
}
 
const ApprovalDetails: FC<ApprovalDetailsProps> = ({ approvalDetails }) => {
    const approvalDetailsKeyValuePairsItems = [
        [{ label: 'Approver', value: approvalDetails?.approverID }],
        [{ label: 'Approved Date', value: approvalDetails?.timestamp }]
    ]
 
    const platformNametags = [
        [{ label: 'Platform Name', value: approvalDetails?.details?.platformName }]
    ]
 
    const generalDetailsKeyValuePairsItems = [
        [{ label: 'DNS Zone Apex', value: approvalDetails?.details?.dnsZoneApex }],
        [{ label: 'VPC Value', value: approvalDetails?.details?.vpcValue }],
        [
            {
                label: 'VPC Identifier',
                value: approvalDetails?.details?.vpcIdentifier
            }
        ]
    ]
 
    const kmsDetailsKeyValuePairsItems = [
        [{ label: 'Role Name', value: approvalDetails?.details?.roleName || approvalDetails?.details?.kms[0]?.roleName }],
        [
            {
                label: 'KMS AWS Account ID',
                value: approvalDetails?.details?.awsAccountId?.value || approvalDetails?.details?.kms[0]?.awsAccountId
            }
        ],
        [
            {
				label: 'Delegate AWS Account ID(s)',
				value: approvalDetails?.details?.delegateAWSAccountIDs?.join(', ') || approvalDetails?.details?.kms[0].delegateAWSAccountIDs
				.map(
					(delegateAwsAccountId: { dismissLabel: string; label: string }) =>
						delegateAwsAccountId.label
				)
				.join(', ')

			}
        ]
    ]
 
    const renderTFProvidersDetails = () => {
        return approvalDetails?.details?.tfProviders?.map(
            (
                tfProvider: {
                    alias: string
                    externalId: string
                    region: string
                    arn: string
                },
                i: number
            ) => {
                return (
                    <Box variant="awsui-key-label" key={i}>
                        <p key={i}>{tfProvider.alias}</p>
                        <KeyValuePairs
                            key={`tfProvidersDetails${i}`}
                            items={[
                                [{ label: 'External ID', value: tfProvider.externalId }],
                                [{ label: 'Region', value: tfProvider.region }],
                                [{ label: 'ARN', value: tfProvider.arn }]
                            ]}
                        />
                    </Box>
                )
            }
        )
    }
 
    const renderSubnetAliasesDetails = () => {
        return approvalDetails?.details?.subnetAliases?.map(
            (
                subnetAlias: {
                    count: string
                    alias: string
                    identifier: string
                    value: string
                },
                i: number
            ) => {
                return (
                    <Box variant="awsui-key-label" key={i}>
                        <p key={i}>{subnetAlias.alias}</p>
                        <KeyValuePairs
                            key={`subnetAliasesDetails${i}`}
                            items={[
                                [{ label: 'Count', value: subnetAlias.count }],
                                [{ label: 'Identifier', value: subnetAlias.identifier }],
                                [{ label: 'Value', value: subnetAlias.value }]
                            ]}
                        />
                    </Box>
                )
            }
        )
    }
 
    const securityAliasesKeyValuePairsItems = [
        approvalDetails?.details?.securityAliases.map(
            (securityAlias: { value: string; existing: boolean; key: string }) => {
                return {
                    label: securityAlias.key,
                    value: securityAlias.value
                }
            }
        )
    ]
 
    const tagsKeyValuePairsItems = [
        approvalDetails?.details?.tags.map(
            (tag: { value: string; existing: boolean; key: string }) => {
                return {
                    label: tag.key,
                    value: tag.value
                }
            }
        )
    ]
 
    return (
        <Container header={<Header variant="h3">Approval Details</Header>}>
            <SpaceBetween direction="vertical" size="l">
                <KeyValuePairs
                    key={'approvalDetails'}
                    items={approvalDetailsKeyValuePairsItems}
                />
                <Box variant="h5">
                    <KeyValuePairs key={'Platform Name'} items={platformNametags} />
                </Box>
 
                <Box variant="h5">
                    <p>General Details</p>
                    <KeyValuePairs
                        key={'generalDetails'}
                        items={generalDetailsKeyValuePairsItems}
                    />
                </Box>
 
                <Box variant="h5">
                    <p>KMS</p>
                    <KeyValuePairs
                        key={'kmsDetails'}
                        items={kmsDetailsKeyValuePairsItems}
                    />
                </Box>
 
                <Box variant="h5">
                    <p>TF Providers</p>
                    {renderTFProvidersDetails()}
                </Box>
 
                <Box variant="h5">
                    <p>Subnet Aliases</p>
                    {renderSubnetAliasesDetails()}
                </Box>
 
                <Box variant="h5">
                    <p>Security Aliases</p>
                    <KeyValuePairs
                        key={'securityAliasesDetails'}
                        items={securityAliasesKeyValuePairsItems}
                    />
                </Box>
 
                <Box variant="h5">
                    <p>Tags</p>
                    <KeyValuePairs key={'tagsDetails'} items={tagsKeyValuePairsItems} />
                </Box>
            </SpaceBetween>
        </Container>
    )
}
 
export default ApprovalDetails