import CONSTANT from '../../constants'
import type { gcpProjectEditRequest } from '../../types/gcpProject'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
const { GCPPROJECTS_API_URL } = CONSTANT

const editgcpProject = async (editgcpProject: gcpProjectEditRequest): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(`${GCPPROJECTS_API_URL}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			action: 'UPDATE'
		},
		body: JSON.stringify(editgcpProject)
	})

	return response.json()
}

export default editgcpProject
