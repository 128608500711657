import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'

const { APPLICATIONS_API_URL } = CONSTANT

const getSingleApplication = async (
	applicationID: string,
	getTags: boolean = false
): Promise<{
	status: number
	body: Array<object>
}> => {
	const response = await fetchWithAuthorizationHeader(
		`${APPLICATIONS_API_URL}?applicationID=${applicationID}&getTags=${getTags.toString()}`
	)
	return response.json()
}

export default getSingleApplication
