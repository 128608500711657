import { useQuery } from "@tanstack/react-query";

import api from "../../api";

import type { gcpProjectIndexItem } from "../../types/gcpProject";

const useApprovedGcpProjects = () => {
  return useQuery({
    queryKey: ['approvedGcpProjects'],
    queryFn: () => api.gcpProjects.getgcpProjects(true),
    select(data) {
      return data.body as Array<gcpProjectIndexItem>
    }
  })
}

export default useApprovedGcpProjects