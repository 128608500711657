import { produce } from "immer"
import { mountStoreDevtool } from "simple-zustand-devtools"
import { create } from "zustand"

type NewRhocpTenantFormState = {
  isFormValid: boolean
  formValues: Record<string, any>
}

type NewRhocpTenantFormActions = {
  actions: {
    clearFormValues: () => void
    setFormValues: (fieldName: string, value: any) => void
    setFormValidity: (isValid: boolean) => void
  }
}

const initialFormValues = {
  platformName: '',
  projectKey: '',
  environments: [{ label: 'Non-prod', value: 'nprd' }],
  envAdGroups: [],
  services: ['default'],
  platformAlias: '',
  sandbox: false,
  scc: false,
  platformOwner: '',
  costCenter: '',
  WBSCode: '',
  domain: '',
}

const newRhocpTenantFormStore = create<NewRhocpTenantFormState 
& NewRhocpTenantFormActions>((set, _get) => ({
  isFormValid: false,
  formValues: initialFormValues,
  actions: {
    clearFormValues: () => set(() => ({formValues: initialFormValues})),
    setFormValidity: (isValid) => set(() => ({isFormValid: isValid})),
    setFormValues: (fieldName, value) =>
      set((state) =>
        produce(state, (draft) => {
          switch (fieldName) {
            case 'services':
              const services = value.split(',').map((service: string) => service.trim())
              draft.formValues[fieldName] = services
              break;
            case 'domain':
              draft.formValues[fieldName] = value.value
              break;
            case 'platformAlias':
              draft.formValues['projectKey'] = value
              draft.formValues[fieldName] = value
              break;         
            default:
              draft.formValues[fieldName] = value
              break;
          }
        }))
  }
}))

export const useNewRhocpTenantFormStore = () =>
  newRhocpTenantFormStore((state) => state)

if (process.env.NODE_ENV !== 'production') {
  mountStoreDevtool('NewRhocpTenantFormStore', newRhocpTenantFormStore)
}