import React, { FC, useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useForm } from 'react-hook-form'

import {
	Button,
	Container,
	Form,
	Icon,
	Popover,
	SpaceBetween
} from '@cloudscape-design/components'

import FormInput from '../../ControlledFormInputs/Input'

import { useApprovalFormStore } from '../../../stores/applications/approvalFormStore'
import type { ApplicationApprovalRequest } from '../../../types/applications'
import useAcceptApplication from '../../../hooks/mutate/useAcceptApplication'
import FormMultiselect from '../../ControlledFormInputs/Multiselect'
import usePlatforms from '../../../hooks/query/usePlatforms'
import useApplications from '../../../hooks/query/useApplications'

interface ApprovalFormProps {
	thisApplicationID: string
	shouldShowApprovalForm: (shouldShow: boolean) => void
}
interface Option {
	label: string
	value: string
	description: string
}

const ApprovalForm: FC<ApprovalFormProps> = ({
	thisApplicationID,
	shouldShowApprovalForm
}) => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const {
		formValues,
		actions: { setFormValues, clearFormValues }
	} = useApprovalFormStore()

	useEffect(() => {
		const approvervalue = thisUserEmail
		setFormValues('approverID', approvervalue)
	}, [thisUserEmail])
	const {
		control,
		trigger,
		setValue,
		formState: { isValid }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: formValues
	})
	const { data: platforms } = usePlatforms()
	const { data: applications } = useApplications()

	useEffect(() => {
		if (
			applications &&
			applications.length > 0 &&
			platforms &&
			platforms.length > 0
		) {
			const selectedApplication = applications.find(
				(application) => application.applicationID === thisApplicationID
			)

			if (selectedApplication) {
				const filteredPlatforms = platforms.filter(
					(platform) =>
						platform.platformID ===
						selectedApplication.details.platformName.value
				)
				const jsonDataString = JSON.stringify(filteredPlatforms)
				const jsonData = JSON.parse(jsonDataString)
				const projectID = jsonData[0].gcpProjectID

				let env = jsonData[0]?.environment
				if (env === 'staging') {
					env = 'stg'
				}

				const hostname = `${selectedApplication.details.applicationName}.elz-gcp-${env}-globetel.com`

				setValue('projectId', projectID)
				setFormValues('projectId', projectID)
				const wkldClusterName = jsonData[0].namePrefix
				if (!selectedApplication.details.additionalEnvName) {
					setValue('hostname', hostname)
					setFormValues('hostname', hostname)
				}

				setValue('wkldClusterName', wkldClusterName)
				setFormValues('wkldClusterName', wkldClusterName)
			}
			setValue('region', 'Singapore (asia-southeast1)')
		}
	}, [thisApplicationID, platforms, applications, setValue])

	const { mutate: acceptApplication, isLoading } =
		useAcceptApplication(thisApplicationID)

	const _handleApprove = () => {
		trigger()

		if (!isValid) return

		const approvalRequest: ApplicationApprovalRequest = {
			action: 'APPROVE',
			applicationID: thisApplicationID,
			approverID: thisUserEmail,
			details: formValues
		}

		acceptApplication(approvalRequest, {
			onSuccess: () => {
				shouldShowApprovalForm(false)
				clearFormValues()
				window.location.reload()
			}
		})
	}

	return (
		<Form
			actions={
				<SpaceBetween direction="horizontal" size="xs">
					<Button variant="link" onClick={() => shouldShowApprovalForm(false)}>
						Cancel
					</Button>
					<Button
						variant="primary"
						loading={isLoading}
						onClick={_handleApprove}
					>
						Approve
					</Button>
				</SpaceBetween>
			}
		>
			<Container>
				<SpaceBetween direction="vertical" size="l">
					<div style={{ maxWidth: 550 }}>
						<FormInput
							name="region"
							label="Region"
							type="text"
							control={control}
							placeholder="Select a region"
							setFormValues={setFormValues}
							disabled={true}
						/>
					</div>
					<div style={{ maxWidth: 550 }}>
						<FormInput
							name="projectId"
							label="Workload Project ID"
							type="text"
							control={control}
							setFormValues={setFormValues}
							placeholder=""
							disabled={true}
						/>
					</div>
					<div style={{ maxWidth: 550 }}>
						<FormInput
							name="wkldClusterName"
							label="Workload Cluster Name"
							type="text"
							control={control}
							setFormValues={setFormValues}
							placeholder=""
							disabled={true}
						/>
					</div>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div style={{ width: 550 }}>
							<FormInput
								name="hostname"
								label="Internal Domain Name"
								placeholder="Enter Application's Internal Domain Name"
								type="text"
								control={control}
								setFormValues={setFormValues}
								rules={{
									required: `Please enter the Application's Internal Domain Name`,
									validate: (value) =>
										value.endsWith('.com') ||
										'The domain name must end with .com'
								}}
							/>
						</div>
						<div style={{ marginLeft: '10px', marginTop: '25px' }}>
							<Popover
								fixedWidth
								position="right"
								triggerType="custom"
								content={
									<p>
										These are the automatically generated DNS names for the
										application, but you can edit them as needed. i.e
										applicationname.elz-gcp-env-globetel.com
									</p>
								}
							>
								<Icon name="status-info" />
							</Popover>
						</div>
					</div>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div style={{ width: 550 }}>
							<FormInput
								name="externalIP"
								label="Domain Name Mapped IP Name"
								placeholder="Enter Application's Domain Name Mapped IP Name"
								type="text"
								control={control}
								setFormValues={setFormValues}
								rules={{
									required: `Please enter the Application's Domain Name Mapped IP Name`
								}}
							/>
						</div>
						<div style={{ marginLeft: '10px', marginTop: '25px' }}>
							<Popover
								fixedWidth
								position="right"
								triggerType="custom"
								content={
									<p>
										Add the external IP name you created for the above
										application DNS, ensuring the IP is already present in the
										GCP project. i.e.
										glo-isg-workload-env-applicationname-xxxxxx-xxxx
									</p>
								}
							>
								<Icon name="status-info" />
							</Popover>
						</div>
					</div>
				</SpaceBetween>
			</Container>
		</Form>
	)
}

export default ApprovalForm
