import { FC } from 'react';
import { useOktaAuth } from '@okta/okta-react';

import {
    Button,
    Container,
    Form,
    SpaceBetween
} from '@cloudscape-design/components';

import LoadingSpinner from '../../LoadingSpinner';

import { useApprovalFormStore } from '../../../stores/gcpProjects/approvalFormStore';
import useSingleGcpProject from '../../../hooks/query/useSingleGcpProject';
import useAcceptGcpProject from '../../../hooks/mutate/useAcceptGcpProject';
import type { gcpProjectApprovalRequest } from '../../../types/gcpProject';
import useApproveTags from '../../../hooks/mutate/useApproveTags';
import { ApproveTags } from '../../../types/tags';

interface ApprovalFormProps {
    thisgcpProjectID: string;
    shouldShowApprovalForm: (shouldShow: boolean) => void;
}

const ApprovalForm: FC<ApprovalFormProps> = ({
    thisgcpProjectID,
    shouldShowApprovalForm
}) => {
    const { authState } = useOktaAuth();
    const thisUserEmail = authState?.idToken?.claims.email as string;

    const { actions: { clearFormValues } } = useApprovalFormStore();

    const { mutate: approveTagsById } = useApproveTags('123');
    const { mutate: acceptgcpProject } = useAcceptGcpProject(thisgcpProjectID);

    const { data: thisgcpProject, isLoading: singlegcpProjectDataLoading } =
        useSingleGcpProject(thisgcpProjectID);

    if (!thisgcpProject || singlegcpProjectDataLoading) return <LoadingSpinner />;

    const _handleApprove = () => {
        const approvalRequest: gcpProjectApprovalRequest = {
            gcpProjectID: thisgcpProjectID,
            action: 'APPROVE',
            approverID: thisUserEmail,
            details: {}
        };

        const tagRequest: ApproveTags = {
            action: 'UPDATE_APPROVE_STATUS',
            status: 'APPROVED',
            tagsID: thisgcpProject?.tagsID || ''
        };

        approveTagsById(tagRequest, {
            onSuccess: () => {
                console.log('approveTagsById- Success');
            },
            onError: () => {
                console.log('approveTagsById Error');
            }
        });

        acceptgcpProject(approvalRequest, {
            onSuccess: () => {
                shouldShowApprovalForm(false);
                clearFormValues();
            }
        });
    };

    return (
        <Form
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={() => shouldShowApprovalForm(false)}>
                        No
                    </Button>
                    <Button variant="primary" loading={false} onClick={_handleApprove}>
                        Yes
                    </Button>
                </SpaceBetween>
            }
        >
            <Container>
                <SpaceBetween direction="vertical" size="l">
                    <h3>Do you want to approve this GCP project?</h3>
                </SpaceBetween>
            </Container>
        </Form>
    );
};

export default ApprovalForm;