import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { ProjectApprovalRequest } from '../../types/projects'
import api from '../../api'

const useAcceptProject = (projectID: string) => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (projectApproval: ProjectApprovalRequest) => {
			return api.projects.acceptProject(projectApproval)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['projects'] })
			queryClient.invalidateQueries({ queryKey: ['projects', projectID] })
		}
	})
}

export default useAcceptProject
