import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
import type { AccountRejectionRequest } from '../../types/account'

const { ACCOUNTS_API_URL } = CONSTANT

const rejectAccount = async (
	accountRejection: AccountRejectionRequest
): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(`${ACCOUNTS_API_URL}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			action: 'REJECT'
		},
		body: JSON.stringify(accountRejection)
	})

	return response.json()
}

export default rejectAccount
