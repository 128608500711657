import { FC, useEffect } from 'react';
import {
	Container,
	FormField,
	Link,
	Select,
	SpaceBetween
} from '@cloudscape-design/components';
import { useForm, Controller } from 'react-hook-form';
import { usegcpProjectCreationFormStore } from '../../../../stores/gcpProjects/newgcpProjectCreationFormStore';

const gcpProjectTypeOptions = [
	{ label: 'Sandbox', value: 'Sandbox' },
	{ label: 'Develop', value: 'Develop' },
	{ label: 'Staging', value: 'Staging' },
	{ label: 'Production', value: 'Production' }
];

const Step1: FC = () => {
	const {
		formValues,
		actions: { setFormValues, setFormValidity }
	} = usegcpProjectCreationFormStore();

	const {
		control,
		formState: { isValid }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: formValues
	});

	useEffect(() => {
		console.log('Form validity changed:', isValid);
		setFormValidity(isValid);
	}, [isValid, setFormValidity]);

	useEffect(() => {
		console.log('Form values:', formValues);
	}, [formValues]);

	return (
		<Container>
			<SpaceBetween direction="vertical" size="l">
				<Controller
					name="gcpProjectType"
					control={control}
					rules={{ required: 'Select a GCP project type' }}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<FormField
							label="GCP Project Type"
							description="Production GCP project will not be provisioned without a non-production GCP project"
							errorText={error?.message}
						>
							<Select
								selectedOption={gcpProjectTypeOptions.find(opt => opt.value === value) || null}
								options={gcpProjectTypeOptions}
								placeholder="Select Project Environment"
								onChange={({ detail }) => {
									console.log('Selected option:', detail.selectedOption);
									onChange(detail.selectedOption.value);
									setFormValues('gcpProjectType', detail.selectedOption.value);
								}}
							/>
						</FormField>
					)}
				/>
			</SpaceBetween>
		</Container>
	);
};

export default Step1