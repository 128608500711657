import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'

const {ACCOUNTS_API_URL} = CONSTANT

const getSingleAccount = async (
    accountRequestID: string,
    getTags: boolean = false
): Promise<{
    status: number
    body: Array<object>
}> => {
    const response = await fetchWithAuthorizationHeader(
        `${ACCOUNTS_API_URL}?accountRequestID=${accountRequestID}&getTags=${getTags.toString()}`
    )
    return response.json()
}

export default getSingleAccount
