import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Table } from '@aws-northstar/ui'
import { Button, StatusIndicator } from '@cloudscape-design/components'

import LoadingSpinner from '../../components/LoadingSpinner'

import type { gcpProject } from '../../types/gcpProject'
import useGcpProjects from '../../hooks/query/useGcpProject'

import { usegcpProjectCreationFormStore } from '../../stores/gcpProjects/newgcpProjectCreationFormStore'
import { useGenericTagsManagementFormStore } from '../../stores/tags/genericTagsManagementFormStore'

const GcpProjects: FC = () => {
	const navigate = useNavigate()

	const { data: gcpProjects, isLoading } = useGcpProjects()
	const gcpProjectCreationFormStore = usegcpProjectCreationFormStore()
	const tagCreationFormStore = useGenericTagsManagementFormStore()

	if (!gcpProjects || isLoading) return <LoadingSpinner />

	const columnDefinitions = [
		{
			id: 'gcpProjectID',
			width: 100,
			header: 'ID',
			cell: (data: any) => (
				<Button variant="link" onClick={() => navigate(data.gcpProjectID)}>
					{data.gcpProjectID}
				</Button>
			),
			sortingField: 'gcpProjectID'
		},
		{
			id: 'requestor',
			width: 100,
			header: 'Requested by',
			cell: (data: gcpProject) => data.requestorID || ''
			// sortingField: "requestor",
		},
		{
			id: 'type',
			width: 100,
			header: 'Project Types',
			cell: (data: gcpProject) => data.type || ''
			// sortingField: "requestor",
		},
		{
			id: 'time',
			width: 100,
			header: 'Created Time',
			//sortingField: 'time',
			cell: (data: gcpProject) => {
				if (data.creationTime) {
					const date = new Date(data.creationTime);
					const year = date.getFullYear();
					const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
					const day = date.getDate().toString().padStart(2, '0');
		
					return `${day}-${month}-${year}`;
				}
				return '';
    		}
		},
		{
			id: 'status',
			width: 100,
			header: 'Status',
			cell: (data: gcpProject) => {
				const status = data.status
				switch (status) {
					case 'PENDING_APPROVAL':
						return (
							<StatusIndicator type="in-progress" colorOverride="blue">
								Pending Approval
							</StatusIndicator>
						)
					case 'APPROVED':
						return <StatusIndicator type="success">Approved</StatusIndicator>
					case 'REJECTED':
						return <StatusIndicator type="error">Rejected</StatusIndicator>
					default:
						return null
				}
			}
		}
	]

	const tableActions = (
		<Button
			variant="primary"
			onClick={() => {
				gcpProjectCreationFormStore.actions.clearFormValues()
				gcpProjectCreationFormStore.actions.setCurrentStep(0)
				tagCreationFormStore.actions.clearFormValues()
				navigate('/gcpProjects/new')
			}}
		>
			New GCP Project
		</Button>
	)

	return (
		<Table
			header="Projects"
			columnDefinitions={columnDefinitions}
			items={gcpProjects}
			actions={tableActions}
			disableRowSelect
			sortingDisabled
		/>
	)
}

export default GcpProjects
