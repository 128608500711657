import CONSTANTS from "../../constants";
import fetchWithAuthorizationHeader from "../../helper/fetchWithAuthorizationHeader";

const { RHOCPTENANTS_API_URL } = CONSTANTS

const getSingleRhocpTenant = async(rhocpTenantID: string, getTags: boolean): Promise<{status: number, body: object}> => {
  let url = `${RHOCPTENANTS_API_URL}?rhocpTenantID=${rhocpTenantID}`
  if (getTags === true) {
    url += '&getTags=true'
  }
  const response = await fetchWithAuthorizationHeader(url)
  if (!response.ok) {
    return {
      status: response.status,
      body: {}
    }
  }

  return response.json()
}

export default getSingleRhocpTenant