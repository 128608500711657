import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'

const { GCPPROJECTS_API_URL } = CONSTANT

const getSinglegcpProject = async (
    gcpProjectID: string,
    getTags: boolean = false
): Promise<{
    status: number
    body: Array<object>
}> => {
    const response = await fetchWithAuthorizationHeader(
        `${GCPPROJECTS_API_URL}?gcpProjectID=${gcpProjectID}&getTags=${getTags.toString()}`
    )
    return response.json()
}

export default getSinglegcpProject
