import { FC, ReactElement, useEffect, useState, cloneElement } from 'react'

import { Button, FormField, SpaceBetween } from '@cloudscape-design/components'

interface FormDynamicFieldsProps {
	name: string
	label: string
	matcher: RegExp
	inputComponents: Array<ReactElement<any>>
	allowAdditionalChunks?: boolean
	setFormValues: (name: string, value: any) => void
}

const FormDynamicFields: FC<FormDynamicFieldsProps> = ({
	label,
	name,
	matcher,
	inputComponents,
	setFormValues,
	allowAdditionalChunks = true
}) => {
	const [numberOfChunks, setNumberOfChunks] = useState(1)
	const [fieldValues, setFieldValues] = useState([])

	const addChunk = () => {
		setNumberOfChunks(numberOfChunks + 1)
	}

	const _setFormValues = (_name: string, _value: string) => {
		setFieldValues((prevState) => {
			const updatedState = JSON.parse(JSON.stringify(prevState))
			const matches = _name.match(matcher)

			if (matches) {
				const index = parseInt(matches[1])
				const key = matches[2]

				if (updatedState[index] === undefined) {
					updatedState[index] = {}
				}

				updatedState[index][key] = _value
			}

			return updatedState
		})
	}

	useEffect(() => {
		setFormValues(name, fieldValues)
	}, [fieldValues])

	return (
		<SpaceBetween size="m">
			<FormField label={label}>
				<SpaceBetween size="l">
					{Array.from(Array(numberOfChunks).keys()).map((i) => {
						return (
							<div>
								{inputComponents.map((InputComponent) =>
									cloneElement(InputComponent, {
										key: `${name}.${i}.${InputComponent.props.name}`,
										name: `${name}.${i}.${InputComponent.props.name}`,
										setFormValues: _setFormValues
									})
								)}
							</div>
						)
					})}
				</SpaceBetween>
			</FormField>
			{allowAdditionalChunks && <Button onClick={addChunk}>Add {name}</Button>}
		</SpaceBetween>
	)
}

export default FormDynamicFields
