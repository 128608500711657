import { FC, useEffect } from 'react'
import { Controller, useForm, RegisterOptions } from 'react-hook-form'

import {
	Container,
	FormField,
	Input,
	Link,
	RadioGroup,
	SpaceBetween,
	Header
} from '@cloudscape-design/components'

import FormInput from '../../../ControlledFormInputs/Input'

import { useAccountCreationFormStore } from '../../../../stores/accounts/newAccountCreationFormStore'
import { StepConfig } from '../steps_config'

const step2Fields: Array<StepConfig> = [
	// fieldName are capitalized becuase we want to concat it with accountType
	// see accountTypeFieldName below
	{
		label: 'Distribution List',
		// e.g stagingDistributionList, nonProductionDistributionList, productionDistributionList
		fieldName: 'DistributionList',
		description:
			"The Distribution List will be used as the AWS account email and the owner of the DL will have root access. Here's standard format for the AWS Tower 2 account email: aws-glo02-<group_name>-<platform_name>-<environment>@globe.com.ph",
		info: (
			<Link
				external
				href="https://globetelecom.atlassian.net/wiki/spaces/globeinfra/pages/4512187069/Account+Management"
			>
				Valid Distribution List Link
			</Link>
		),
		type: 'text'
	},
	{
		label: 'Budget Threshold Alert',
		// e.g: stagingMonthlyBudgetCap, nonProductionMonthlyBudgetCap, productionMonthlyBudgetCap
		fieldName: 'MonthlyBudgetCap',
		description:
			'Kindly place the Initial Monthly Budget Cap that you would like each account to have as part of Account Creation. The Service Owner can still update the Budget Cap as necessary via the pipeline after account creation. Kindly ensure that the Budget Cap indicated here is aligned with the approved budget allocated for your particular Project/Workload.',
		type: 'text',
		constraintText: 'Value should be in USD. Example: 100 (means 100USD)'
	}
	// {
	// 	label: 'Platform Infrastructure Repository Structure (optional)',
	// 	// e.g: stagingRepositoryStructure, nonProductionRepositoryStructure, productionRepositoryStructure
	// 	fieldName: 'RepositoryStructure',
	// 	description:
	// 		'The account will be vended with a Mono-repository in the absence of any option selection',
	// 	info: (
	// 		<Link
	// 			external
	// 			href="https://globetelecom.atlassian.net/wiki/spaces/globeinfra/pages/4599710542/GitLab+SaaS+-+Repository+Structure#Platform"
	// 		>
	// 			Repository Structure Details Link
	// 		</Link>
	// 	),
	// 	type: 'radio',
	// 	items: [
	// 		{ label: 'Mono-Repository', value: 'mono' },
	// 		{ label: 'Multi-Repositories', value: 'multi' }
	// 	]
	// }
]

const getFieldTypeRules = (fieldType: string, fieldName: string): RegisterOptions => {
	switch (fieldType) {
		case 'text':
			return {
				required: true,
				pattern: {
					value: fieldName === 'MonthlyBudgetCap' ? /^\d+(\.\d{1,2})?$/ : /^aws-glo02-[a-zA-Z]+-[a-zA-Z0-9]+-[a-zA-Z]+@globe\.com\.ph$/,
            		message: fieldName === 'MonthlyBudgetCap' ? 'Invalid Budget' : 'Invalid email address'
				}
			}
		case 'number':
			return {
				required: true,
				min: {
					value: 0,
					message: 'Value should be greater than 0'
				},
				max: {
					value: 100000,
					message: 'Value should be less than 1000000'
				}
			}
		case 'radio':
			return {}
		default:
			return {
				required: true
			}
	}
}

const Step2: FC = () => {
	const {
		formValues,
		actions: { setFormValues, setFormValidity }
	} = useAccountCreationFormStore()

	const {
		control,
		formState: { isValid }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: formValues
	})

	useEffect(() => {
		setFormValidity(isValid)
	}, [isValid, setFormValidity])

	return (
		<SpaceBetween direction="vertical" size="l">
			{formValues.accountTypes?.map(
				(accountType: { label: string; value: string }, i: number) => (
					<Container
						header={<Header variant="h3">{accountType.label}</Header>}
						key={i}
					>
						<SpaceBetween direction="vertical" size="l">
							{step2Fields.map((field: StepConfig, i) => {
								// e.g: stagingDistributionList, nonProductionDistributionList, productionDistributionList
								const accountTypeFieldName = `${accountType.value}${field.fieldName}`

								return (
									<Controller
										name={accountTypeFieldName}
										control={control}
										key={i}
										rules={getFieldTypeRules(field.type, field.fieldName)}
										render={({
											field: { name, onChange, value },
											fieldState: { error }
										}) => {
											return (
												<FormField
													label={field.label}
													description={field.description}
													info={field.info}
													constraintText={field.constraintText}
													errorText={error?.message}
												>
													{field.type === 'text' && (
														<Input
															placeholder={field.fieldName === 'MonthlyBudgetCap' ? 'Enter Monthly Budget' : 'aws-glo02-<group_name>-<platform_name>-<environment>@globe.com.ph'}
															name={name}
															value={value}
															onChange={({ detail }) => {
																onChange(detail.value)
																setFormValues(
																	accountTypeFieldName,
																	detail.value
																)
															}}
														/>
													)}

													{field.type === 'number' && (
														<Input
															name={name}
															value={value}
															type="number"
															onChange={({ detail }) => {
																onChange(detail.value)
																setFormValues(
																	accountTypeFieldName,
																	+detail.value
																)
															}}
														/>
													)}

													{field.type === 'radio' && (
														<RadioGroup
															name={name}
															value={value}
															items={field.items}
															onChange={({ detail }) => {
																onChange(detail.value)
																setFormValues(
																	accountTypeFieldName,
																	detail.value
																)
															}}
														/>
													)}
												</FormField>
											)
										}}
									/>
								)
							})}
							<FormInput
								label="SSO user-names"
								name={`${accountType.value}SSOUserNames`}
								description="Please provide the SSO user-names that will be added to the AWS account. Please separate each user-name with a comma (,)"
								constraintText="Example: <network-id>@globetel.com, <network-id-2>@globetel.com"
								control={control}
								placeholder="<network_id_1>@globetel.com, <network_id_2>@globetel.com"
								setFormValues={setFormValues}
								rules={{
									required: true,
									pattern: {
										value:
											/^([a-zA-Z0-9._%+-]+@globetel\.com)(, [a-zA-Z0-9._%+-]+@globetel\.com)*$/i,
										message: 'Invalid email address(es)'
									}
								}}
							/>
						</SpaceBetween>
					</Container>
				)
			)}
		</SpaceBetween>
	)
}

export default Step2
