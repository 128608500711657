import { create } from 'zustand'
import { produce } from 'immer'
import { mountStoreDevtool } from 'simple-zustand-devtools'

type AccountCreationFormState = {
	currentStep: number
	isFormValid: boolean
	formValues: Record<string, any>
}

type AccountCreationFormActions = {
	actions: {
		setFormValues: (fieldName: string, value: any) => void
		setCurrentStep: (step: number) => void
		setFormValidity: (isValid: boolean) => void
		clearFormValues: () => void
	}
}

const initialFormValues = {
	//projectName: 'demo-project-name',
	accountTypes: [
		{
			label: 'Sandbox',
			value: 'Sandbox'
		}
	],
	sandboxDistributionList: '',
	sandboxMonthlyBudgetCap: 1,
	//sandboxRepositoryStructure: 'monoRepository',
	sandboxSSOUserNames: ''
}

const accountCreationFromStore = create<
	AccountCreationFormState & AccountCreationFormActions
>((set, _get) => ({
	currentStep: 0,
	isFormValid: false,
	formValues: initialFormValues,
	actions: {
		clearFormValues: () => set(() => ({ formValues: initialFormValues })),

		setFormValidity: (isValid) => set(() => ({ isFormValid: isValid })),

		setFormValues: (fieldName, value) =>
			set((state) =>
				produce(state, (draft) => {
					draft.formValues[fieldName] = value
				})
			),

		setCurrentStep: (step) => {
			set(() => ({
				currentStep: step
			}))
		}
	}
}))

export const useAccountCreationFormStore = () =>
	accountCreationFromStore((state) => state)

if (process.env.NODE_ENV !== 'production') {
	mountStoreDevtool('AccountCreationFormStore', accountCreationFromStore)
}
