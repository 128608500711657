import { produce } from 'immer'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'

type NewApplicationFormState = {
	isFormValid: boolean
	formValues: Record<string, any>
}

type NewApplicationFormActions = {
	actions: {
		clearFormValues: () => void
		setFormValues: (fieldName: string, value: any) => void
		setFormValidity: (isValid: boolean) => void
	}
}

const initialFormValues = {
	applicationName: '',
	environment: '',
	platformName: '',
	applicationViewer: [],
	creationTime:''
}

const newApplicationFromStore = create<
	NewApplicationFormState & NewApplicationFormActions
>((set, _get) => ({
	isFormValid: false,
	formValues: initialFormValues,
	actions: {
		clearFormValues: () => set(() => ({ formValues: initialFormValues })),

		setFormValidity: (isValid) => set(() => ({ isFormValid: isValid })),

		setFormValues: (fieldName, value) =>
			set((state) =>
				produce(state, (draft) => {
					if (fieldName === 'applicationViewer') {
						const emails = value.split(',').map((email: string) => email.trim())
						draft.formValues[fieldName] = emails
					} else {
						draft.formValues[fieldName] = value
					}
				})
			)
	}
}))

export const useNewApplicationFormStore = () =>
	newApplicationFromStore((state) => state)

if (process.env.NODE_ENV !== 'production') {
	mountStoreDevtool('NewApplicationFormStore', newApplicationFromStore)
}
