import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Table } from '@aws-northstar/ui'
import { Button, StatusIndicator } from '@cloudscape-design/components'

import LoadingSpinner from '../../components/LoadingSpinner'

import type { Account } from '../../types/account'
import useAccounts from '../../hooks/query/useAccounts'

import { useAccountCreationFormStore } from '../../stores/accounts/newAccountCreationFormStore'
import { useGenericTagsManagementFormStore } from '../../stores/tags/genericTagsManagementFormStore'

const Accounts: FC = () => {
	const navigate = useNavigate()

	const { data: accounts, isLoading } = useAccounts()
	const accountCreationFormStore = useAccountCreationFormStore()
	const tagCreationFormStore = useGenericTagsManagementFormStore()

	if (!accounts || isLoading) return <LoadingSpinner />

	const columnDefinitions = [
		{
			id: 'distributionList',
			width: 100,
			header: 'Distribution List',
			sortingField: 'distributionList',
			cell: (data: Account) => (
				<Button
					variant="link"
					onClick={() => navigate(`/accounts/${data.details.distributionList}`)}
				>
					{data.details.distributionList}
				</Button>
			)
		},
		{
			id: 'requestor',
			width: 100,
			header: 'Requested by',
			cell: (data: Account) => data.requestorID || ''
			// sortingField: "requestor",
		},
		{
			id: 'type',
			width: 100,
			header: 'Account Type',
			cell: (data: Account) => data.type || ''
			// sortingField: "requestor",
		},
		{
			id: 'time',
			width: 100,
			header: 'Created Time',
			//sortingField: 'time',
			cell: (data: Account) => {
				if (data.creationTime) {
					const date = new Date(data.creationTime);
					const year = date.getFullYear();
					const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
					const day = date.getDate().toString().padStart(2, '0');
		
					return `${day}-${month}-${year}`;
				}
				return '';
    		}
		},
		{
			id: 'status',
			width: 100,
			header: 'Status',
			cell: (data: Account) => {
				const status = data.status
				switch (status) {
					case 'PENDING_APPROVAL':
						return (
							<StatusIndicator type="in-progress" colorOverride="blue">
								Pending Approval
							</StatusIndicator>
						)
					case 'APPROVED':
						return <StatusIndicator type="success">Approved</StatusIndicator>
					case 'REJECTED':
						return <StatusIndicator type="error">Rejected</StatusIndicator>
					default:
						return null
				}
			}
		}
	]

	const tableActions = (
		<Button
			variant="primary"
			onClick={() => {
				accountCreationFormStore.actions.clearFormValues()
				accountCreationFormStore.actions.setCurrentStep(0)
				tagCreationFormStore.actions.clearFormValues()
				navigate('/accounts/new')
			}}
		>
			New Account
		</Button>
	)

	return (
		<Table
			header="Accounts"
			columnDefinitions={columnDefinitions}
			items={accounts}
			actions={tableActions}
			disableRowSelect
			sortingDisabled
		/>
	)
}

export default Accounts
