import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'

const { APPLICATIONS_API_URL } = CONSTANT

const getApplications = async (): Promise<{
	status: number
	body: Array<object>
}> => {
	const response = await fetchWithAuthorizationHeader(`${APPLICATIONS_API_URL}`)
	if (response.status !== 200) {
		return {
			status: response.status,
			body: []
		}
	}
	return response.json()
}

export default getApplications
