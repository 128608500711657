import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Table } from '@aws-northstar/ui/components'
import { Button, Icon, Link, Popover, StatusIndicator } from '@cloudscape-design/components'

import LoadingSpinner from '../../components/LoadingSpinner'

import usePlatforms from '../../hooks/query/usePlatforms'
import type { Platform } from '../../types/platforms'
import CONSTANTS from '../../constants'

const PlatformOnboarding: FC = () => {
	const navigate = useNavigate()

	const { data: platforms, isLoading } = usePlatforms()

	// if (!platforms || isLoading) return <LoadingSpinner />
	const columnDefinitions = [ 
		{
			id: 'platformID',
			width: 100,
			header: 'ID',
			cell: (data: any) => (
				<Link onFollow={() => { navigate(data.platformID) }}>
					{data.platformID}
				</Link>
			),
			sortingField: 'platformID'
		},
		{
			id: 'platformName',
			width: 100,
			header: 'Platform Name',
			cell: (data: Platform) => data.platformName || '',
			sortingField: 'platformName'
		},
		{
			id: 'environment',
			width: 100,
			header: 'Environment',
			cell: (data: Platform) => data.environment || '',
			sortingField: 'environment'
		},
		{
			id: 'requestor',
			width: 100,
			header: 'Requested by',
			cell: (data: Platform) => data.requestorID || '',
			sortingField: 'requestorID'
		},
		{
			id: 'time',
			width: 100,
			header: 'Created Time',
			cell: (data: Platform) => {
				if (data.creationTime) {
					const date = new Date(data.creationTime);
					const year = date.getFullYear();
					const month = (date.getMonth() + 1).toString().padStart(2, '0');
					const day = date.getDate().toString().padStart(2, '0');
					const hours = date.getHours().toString().padStart(2, '0');
					const minutes = date.getMinutes().toString().padStart(2, '0');
					const seconds = date.getSeconds().toString().padStart(2, '0');
				
					return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
				}
				return ''
			},
			sortingField: 'creationTime'
		},
		{
			id: 'status',
			width: 100,
			header: 'Status',
			cell: (data: Platform) => {
				const status = data.status	// if (!applications || isLoading) return <LoadingSpinner />
				switch (status) {
					case 'PENDING_APPROVAL':
						return (
							<StatusIndicator type="in-progress" colorOverride="blue">
								Pending Approval
							</StatusIndicator>
						)
					case 'APPROVED':
						return <StatusIndicator type="success">Approved</StatusIndicator>
					case 'REJECTED':
						return <StatusIndicator type="error">Rejected</StatusIndicator>
					default:
						return null
				}
			},
			sortingField: 'status'
		}
	]

	const tableActions = (
		<div
			style={{
				display: 'flex',
				width: 170,
				marginRight: 5
			}}
		>
			<Button variant="primary" onClick={() => navigate('/platforms/new')}>
			New Platform
		</Button>
			<div
				style={{
					marginLeft: 7,
					marginTop: 4
				}}
			>
				<Popover
					fixedWidth
					position="right"
					triggerType="custom"
					content={
						<p>
							Click the following link for a step-by-step guide on platform
							onboarding:
							<a
								href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/asp/developer-enablement/platform-onboarding/platform_onboarding/`}
								target="_blank"
								rel="noopener noreferrer"
								aria-label="Plus"
								style={{
									marginLeft: '5px',
									marginTop: '25px'
								}}
							>
								LINK
							</a>
						</p>
					}
				>
					<Icon name="status-info" />
				</Popover>
			</div>
		</div>
	)
	return (
		<Table
			header="Platforms"
			columnDefinitions={columnDefinitions}
			items={platforms || []}
			actions={tableActions}
			disableRowSelect
			sortingDisabled={false}
			loading={isLoading}
		/>
	)
}

export default PlatformOnboarding
