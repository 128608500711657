import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import {
	Form,
	SpaceBetween,
	Button,
	Header,
	Container
} from '@cloudscape-design/components'

import FormInput from '../../../components/ControlledFormInputs/Input'
import FormTokenGroup from '../../../components/ControlledFormInputs/InputTokenGroup'

import { useNewAccessManagementFormStore } from '../../../stores/access-management/newAccessManagementFormStore'
import type { CreateAccessRequest } from '../../../types/access'
import useCreatePermissionsForUser from '../../../hooks/mutate/useCreatePermissionsForUser'

const NewAccessManagementForm: FC = () => {
	const navigate = useNavigate()

	const {
		formValues,
		actions: { setFormValues, setFormValidity }
	} = useNewAccessManagementFormStore()

	const {
		control,
		trigger,
		formState: { isValid }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange'
	})

	useEffect(() => {
		setFormValidity(isValid)
	}, [isValid, setFormValidity])

	const { mutate: createPermissionsForUser, isLoading } =
		useCreatePermissionsForUser()

	const handleSubmit = () => {
		trigger()

		if (!isValid) return

		const createAccessRequest: CreateAccessRequest = {
			userEmail: formValues.email,
			permissions: formValues.permissions.map(
				(permission: { label: string }) => permission.label
			),
			action: 'CREATE'
		}

		createPermissionsForUser(createAccessRequest, {
			onSuccess: () => navigate('/access-management')
		})
	}

	return (
		<Form
			actions={
				<SpaceBetween direction="horizontal" size="xs">
					<Button variant="link" onClick={() => navigate(-1)}>
						Cancel
					</Button>
					<Button variant="primary" onClick={handleSubmit} loading={isLoading}>
						Submit
					</Button>
				</SpaceBetween>
			}
			header={
				<Header variant="h1" description="">
					Add New Access
				</Header>
			}
		>
			<Container>
				<SpaceBetween direction="vertical" size="l">
					<FormInput
						name="email"
						label="Email"
						type="email"
						control={control}
						rules={{
							required:
								'Please enter the email address of the user you want to add',
							pattern: {
								value: /^[^\s@]+@globe\.com\.ph$/,
								message:
									'Please enter a valid Globe email address. Example: name@globe.com.ph'
							}
						}}
						setFormValues={setFormValues}
					/>

					<FormTokenGroup
						label="Permissions"
						name="permissions"
						control={control}
						items={[]}
						placeholder="Type permission and press 'Enter'"
						rules={
							{
								// TODO: validate that there is at least one token
							}
						}
						setFormValues={setFormValues}
					/>
				</SpaceBetween>
			</Container>
		</Form>
	)
}

export default NewAccessManagementForm
