import { useMutation, useQueryClient } from "@tanstack/react-query"

import api from "../../api"
import { RhocpTenantRejectionRequest } from "../../types/rhocpTenant"

const useRejectRhocpTenant = (rhocpTenantID: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (tenantRejection: RhocpTenantRejectionRequest) => {
      return api.rhocpTenants.rejectRhocpTenant(tenantRejection)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['rhocpTenants'] })
      queryClient.invalidateQueries({ queryKey: ['rhocpTenant', rhocpTenantID] })
    },
    onError: (e) => {
      console.log(e)
    }
  })
}

export default useRejectRhocpTenant