import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
import type { ApplicationRejectionRequest } from '../../types/applications'

const { APPLICATIONS_API_URL } = CONSTANT

const rejectApplication = async (
	applicationRejection: ApplicationRejectionRequest
): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(
		`${APPLICATIONS_API_URL}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				action: 'REJECT'
			},
			body: JSON.stringify(applicationRejection)
		}
	)

	return response.json()
}

export default rejectApplication
