import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'

import Wizard, { WizardProps } from '@cloudscape-design/components/wizard'

import Step1 from '../../../components/AccountCreation/Steps/Step1'
import Step2 from '../../../components/AccountCreation/Steps/Step2'
import Tags from '../../../components/AccountCreation/Steps/Tags'
import Review from '../../../components/AccountCreation/Steps/Review'

import { useAccountCreationFormStore } from '../../../stores/accounts/newAccountCreationFormStore'
import { AccountRequest } from '../../../types/account'
import useCreateNewAccount from '../../../hooks/mutate/useCreateNewAccount'

import { useGenericTagsManagementFormStore } from '../../../stores/tags/genericTagsManagementFormStore'
import useCreateNewTags from '../../../hooks/mutate/useCreateNewTags'
import { TagsRequest, getTagsApprovalObject } from '../../../types/tags'
import { v4 as uuidv4 } from 'uuid'

const i18nStrings: WizardProps.I18nStrings = {
	stepNumberLabel: (stepNumber: number) => `Step ${stepNumber}`,
	collapsedStepsLabel: (stepNumber: number, stepsCount: number) =>
		`Step ${stepNumber} of ${stepsCount}`,
	skipToButtonLabel: (step: { title: string }, _stepNumber: number) =>
		`Skip to ${step.title}`,
	navigationAriaLabel: 'Steps',
	cancelButton: 'Cancel',
	previousButton: 'Previous',
	nextButton: 'Next',
	submitButton: 'Submit',
	optional: 'optional'
}

const steps: Array<WizardProps.Step> = [
	{
		title: 'Account Types',
		content: <Step1 />
	},
	{
		title: 'Account Details',
		content: <Step2 />
	},
	{
		title: 'Tags Details',
		content: <Tags />
	},
	{
		title: 'Review',
		content: <Review />
	}
]

const convertFormValuesToAccountRequestDetails = (
	formValues: Record<any, any>
): Record<string, any> => {
	const details: Record<string, any> = {}

	formValues.accountTypes.forEach((accountType: any) => {
		details[accountType.value] = {
			//projectName: formValues['projectName'],
			distributionList: formValues[`${accountType.value}DistributionList`],
			monthlyBudgetCap: formValues[`${accountType.value}MonthlyBudgetCap`],
			repositoryStructure:
				formValues[`${accountType.value}RepositoryStructure`],
			SSOUserNames: formValues[`${accountType.value}SSOUserNames`]
		}
	})

	return details
}

const AccountCreationForm: FC = () => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const navigate = useNavigate()

	const accountCreationFormStore = useAccountCreationFormStore()
	const tagCreationFormStore = useGenericTagsManagementFormStore()

	const { mutate: createNewAccount, isLoading: isLoadingAccount } =
		useCreateNewAccount()
	const { mutate: createTags, isLoading: isLoadingTags } = useCreateNewTags()

	const handleSubmit = () => {
		let tagsID = ''

		const accountList = Object.keys(
			convertFormValuesToAccountRequestDetails(
				accountCreationFormStore.formValues
			)
		)
		accountList.forEach((account) => {
			tagsID = tagsID === '' ? uuidv4() : tagsID + ',' + uuidv4()
		})

		const accountRequest: AccountRequest = {
			requestorID: thisUserEmail,
			action: 'CREATE',
			status: 'PENDING_APPROVAL',
			tagsID: tagsID,
			details: convertFormValuesToAccountRequestDetails(
				accountCreationFormStore.formValues
			)
		}

		const tagRequest: TagsRequest = {
			requestorID: thisUserEmail,
			action: 'CREATE',
			status: 'PENDING_APPROVAL',
			details: tagCreationFormStore.formValues,
			tagsID: tagsID,
			approvalStatus: getTagsApprovalObject()
		}

		createTags(tagRequest, {
			onSuccess: () => {
				createNewAccount(accountRequest, {
					onSuccess: () => navigate('/accounts')
				})
			}
		})
	}

	const handleCancel = () => {
		accountCreationFormStore.actions.clearFormValues()
		accountCreationFormStore.actions.setCurrentStep(0)
		navigate(-1)
	}

	const handleWizardNavigate = ({
		detail
	}: {
		detail: WizardProps.NavigateDetail
	}) => {
		if (detail.reason === 'next') {
			if (!accountCreationFormStore.isFormValid) return
		}
		if ('' + detail.requestedStepIndex === '3') {
			if (!tagCreationFormStore.isFormValid) return
		}

		accountCreationFormStore.actions.setCurrentStep(detail.requestedStepIndex)
	}

	return (
		<Wizard
			i18nStrings={i18nStrings}
			steps={steps}
			activeStepIndex={accountCreationFormStore.currentStep}
			onNavigate={handleWizardNavigate}
			onSubmit={handleSubmit}
			isLoadingNextStep={isLoadingAccount && isLoadingTags}
			onCancel={handleCancel}
		/>
	)
}

export default AccountCreationForm
