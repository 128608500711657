import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import {
	Button,
	Container,
	Form,
	FormField,
	Header,
	Input,
	SpaceBetween
} from '@cloudscape-design/components'

import FormTokenGroup from '../../../components/ControlledFormInputs/InputTokenGroup'
import LoadingSpinner from '../../../components/LoadingSpinner'

import useDeletePermissionsForUser from '../../../hooks/mutate/useDeletePermissionsForUser'
import useUpdatePermissionsForUser from '../../../hooks/mutate/useUpdatePermissionsForUser'
import useUserPermissions from '../../../hooks/query/useUserPermissions'
import { useUpdateAccessManagementStore } from '../../../stores/access-management/updateAccessManagementFormStore'
import type {
	DeleteAccessRequest,
	UpdateAccessRequest
} from '../../../types/access'

const AccessManagementDetails: FC = () => {
	const { userEmail } = useParams() as { userEmail: string }
	const navigate = useNavigate()

	const {
		formValues,
		actions: { setFormValues, setFormValidity }
	} = useUpdateAccessManagementStore()

	const {
		control,
		trigger,
		formState: { isValid }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange'
	})

	useEffect(() => {
		setFormValidity(isValid)
	}, [isValid, setFormValidity])

	const { data: access, isLoading } = useUserPermissions(userEmail)

	const { mutate: updatePermissionsForUser, isLoading: updateLoading } =
		useUpdatePermissionsForUser(userEmail)

	const { mutate: deletePermissionsForUser, isLoading: deleteLoading } =
		useDeletePermissionsForUser(userEmail)

	const handleAccessChange = () => {
		trigger()
		if (!isValid) return

		const updateAccess: UpdateAccessRequest = {
			userEmail: userEmail,
			permissions: formValues.permissions.map(
				(permission: { label: string }) => permission.label
			),
			action: 'UPDATE'
		}

		updatePermissionsForUser(updateAccess, {
			onSuccess: () => navigate('/access-management')
		})
	}

	const handleAccessDelete = () => {
		const deleteAccessRequestPayload: DeleteAccessRequest = {
			userEmail,
			action: 'DELETE'
		}

		deletePermissionsForUser(deleteAccessRequestPayload, {
			onSuccess: () => navigate('/access-management')
		})
	}

	if (!access || isLoading) return <LoadingSpinner />

	const existingPermissions = access.permissions.map((permission: string) => ({
		label: permission,
		dismissLabel: `Remove item ${permission}`,
		disabled: permission && permission.indexOf('@') > 0
	}))

	return (
		<Form
			actions={
				<SpaceBetween direction="horizontal" size="xs">
					<Button variant="link" onClick={() => navigate(-1)}>
						Cancel
					</Button>
					<Button
						variant="link"
						loading={deleteLoading}
						onClick={handleAccessDelete}
					>
						Delete
					</Button>
					<Button
						variant="primary"
						loading={updateLoading}
						onClick={handleAccessChange}
					>
						Save
					</Button>
				</SpaceBetween>
			}
			header={
				<Header variant="h1" description="">
					Update/Edit Access
				</Header>
			}
		>
			<Container>
				<SpaceBetween direction="vertical" size="l">
					<FormField label="Email">
						<Input disabled value={userEmail} />
					</FormField>
					<FormTokenGroup
						label="Permissions"
						name="permissions"
						control={control}
						items={existingPermissions}
						placeholder="Type permission and press 'Enter'"
						rules={
							{
								// TODO: validate that there is at least one token
							}
						}
						setFormValues={setFormValues}
					/>
				</SpaceBetween>
			</Container>
		</Form>
	)
}

export default AccessManagementDetails
