import { FC, ReactNode, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";
import { useNewRhocpTenantFormStore } from "../../../stores/rhocpTenants/newRhocpTenantFormStore";
import useCreateNewTags from "../../../hooks/mutate/useCreateNewTags";
import { Form, SpaceBetween, Box, Button, Header, Container, ColumnLayout, TextContent } from "@cloudscape-design/components";
import FormInput from "../../../components/ControlledFormInputs/Input";
import { useForm } from "react-hook-form";
import FormMultiselect from "../../../components/ControlledFormInputs/Multiselect";
import FormCheckBox from "../../../components/ControlledFormInputs/CheckBox";
import FormSelect from "../../../components/ControlledFormInputs/Select";

import type { RhocpTenantRequest } from "../../../types/rhocpTenant";

import PORTFOLIO_LIST from "../../../portfolio";
import useCreateNewRhocpTenant from "../../../hooks/mutate/useCreateNewRhocpTenant";
import RhocpTenantTagsNew from "./RhocpTenantNewTags";
import { useGenericTagsManagementFormStore } from "../../../stores/tags/genericTagsManagementFormStore";
import { getTagsApprovalObject, TagsRequest } from "../../../types/tags";
import {v4 as uuidv4} from 'uuid'

type FormInputContainerProps = {
  children: ReactNode
}

function FormInputContainer(props: FormInputContainerProps) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <div style={{ width: '650px' }}>
        {props.children}
      </div>
    </div>
  )
}

const ENVIRONMENT_LIST = [
  { label: 'Non-prod', value: 'nprd' },
  { label: 'Prod', value: 'prd' }
]

const RhocpTenantCreationForm: FC = () => {
  const { authState } = useOktaAuth()
  const thisUserEmail = authState?.idToken?.claims.email as string

  const navigate = useNavigate()

  const { formValues, actions: { setFormValues, clearFormValues } } = useNewRhocpTenantFormStore()

  const { mutate: createNewRhocpTenant, isLoading } = useCreateNewRhocpTenant()

  const { formValues: tagFormValues,
    actions: { setFormValues: setTagFormValues, clearFormValues: clearTagFormValues } } = useGenericTagsManagementFormStore()
  
  const { mutate: createTags, isLoading: isCreateTagsLoading } = useCreateNewTags()

  const { control, setValue, trigger, formState: { isValid } } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: formValues
  })

  const [showEnvAdGroupNames, setShowEnvAdGroupNames] = useState(false)

  // Set AD Group Names to be used in provisoning access to the RHOCP Console & corresponding cluster
  useEffect(() => {
    const baseAdGroupName = 'gtcrhocp'
    const platformAliasValue = formValues.platformAlias
    const envValues = formValues.environments

    if (platformAliasValue !== "") {
      const adGroupNames = envValues.map((env: { label: string, value: string }) => {
        return `${baseAdGroupName}-${env.value}-${platformAliasValue}`
      })
      
      setValue('envAdGroups', adGroupNames)
      setFormValues('envAdGroups', adGroupNames)
      setShowEnvAdGroupNames(true)
    }
    else {
      setShowEnvAdGroupNames(false)
    }
  }, [formValues.platformAlias, formValues.environments, setValue, setFormValues])

  useEffect(() => {
    if (tagFormValues !== undefined) {
      setFormValues('costCenter', tagFormValues.costCenter)
      setValue('costCenter', tagFormValues.costCenter)
      setFormValues('WBSCode', tagFormValues.WBSCode)
      setValue('WBSCode', tagFormValues.WBSCode)

      if (tagFormValues.group !== undefined) {
        setFormValues('domain', tagFormValues.group)
        setValue('domain', tagFormValues.group)
      }
    }

    if (formValues !== undefined) {
      setTagFormValues('platformName', formValues.platformName)
      setTagFormValues('systemAlias', formValues.platformAlias)
      setTagFormValues('platformOwner', formValues.platformOwner)
    }
  },[tagFormValues, setFormValues, setValue])

  const handleSubmit = async () => {
    await trigger()
    if (isValid) {
      let tagsId = uuidv4()
      const newTag: TagsRequest = {
        tagsID: tagsId,
        requestorID: thisUserEmail,
        details: tagFormValues,
        action: 'CREATE',
        status: 'PENDING_APPROVAL',
        approvalStatus: getTagsApprovalObject()
      }
      const tenant: RhocpTenantRequest = {
        requestorID: thisUserEmail,
        details: formValues,
        action: 'CREATE',
        status: 'PENDING_APPROVAL',
        creationTime: new Date().toISOString(),
        tagsID: tagsId
      }

      console.log(newTag)
      console.log(tenant)

      createTags(newTag, {
        onSuccess: () => {
          createNewRhocpTenant(tenant, {
            onSuccess: () => {
              navigate('/rhocpTenants')
              clearFormValues()
            },
            onError: (e) => {
              console.log(e)
            }
          })
          clearTagFormValues()
        },
        onError: (e) => {
          console.log(e)
        }
      })

      // createNewRhocpTenant(tenant, {
      //   onSuccess: () => {
      //     navigate('/rhocpTenants')
      //     clearFormValues()
      //   },
      //   onError: (e) => {
      //     console.log(e)
      //   }
      // })
    }
  }

  return (
    <Form
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Box>
            <Button variant="primary" onClick={handleSubmit} loading={isLoading && isCreateTagsLoading}>Submit</Button>
            <Button variant="link" onClick={() => navigate('/rhocpTenants')}>Cancel</Button>
          </Box>
        </SpaceBetween>
      }
      header={
        <Header variant="h1" description="">
          Request a new Tenant
        </Header>
      }>
        <SpaceBetween direction="vertical" size="s">
          {/* <Container>
            <TextContent>
              <h3>Welcome to the RHOCP Account Creation Request Form</h3>
              <p>
                This form facilitates the setup of your Project/Demand's RHOCP Account. Before proceeding, ensure the following prerequisites are met:             
              </p>
              <p>
                <h4>Business Requirements:</h4>
                <ul>
                  <li>Ensure your project has an approved Business Case and MTS (Migration Technical Strategy).</li>
                  <li> Coordinate with your Service Owner to confirm the MTS's approval status.</li>
                </ul>
              </p>
              <p>
                <h4>Tags in the Cloud AppSheet:</h4>
                <ul>
                  <li>
                  Complete the <a href="https://www.appsheet.com/start/d8e8e394-b9b5-4469-9535-ff080e72b176#control=Cloud%20Tags%20Library&page=table" target="_blank">Tags in the Cloud AppSheet</a>. These tags will be used to associate with your resources.
                  </li>
                </ul>
              </p>
              <p>
                <h4>AD Group and Member Request:</h4>
                <ul>
                  <li>An AD group and its members have been created for your authentication and authorization.</li>
                </ul>
              </p>
              
              <p>
                <small>
                  <strong>Note:</strong> Accurate and valid information provided in this form is crucial for our resource provisioning process.
                </small>
              </p>
            </TextContent>
          </Container> */}
          <Container>
            <SpaceBetween direction="vertical" size="l">
              <FormInputContainer>
                <FormInput
                  name="platformName"
                  label="System Name"
                  type="text"
                  control={control}
                  setFormValues={setFormValues}
                  description="Maximum 20 characters"
                  rules={{
                    required: 'Please enter system name',
                    maxLength: {
                      value: 20,
                      message: 'Maximum length is 20 characters'
                    },
                    pattern: {
                      value: /^[a-z0-9-]+$/,
                      message:
                        'Platform name should be lowercase only and whitespaces are not allowed.'
                    }
                  }}/>
              </FormInputContainer>
              <FormInputContainer>
                <FormInput
                  name="platformOwner"
                  label="Platform Owner"
                  type="email"
                  control={control}
                  placeholder="platform.owner@globe.com.ph"
                  setFormValues={setFormValues}
                  rules={{
                    required: 'Please enter platform owner',
                    pattern: {
                      value: /^[a-z.0-9-]+@globe.com.ph$/,
                      message:
                        'Platform Owner should be a Globe email address'
                    }
                  }}
                />
              </FormInputContainer>
              <FormInputContainer>
                <FormInput
                  name="platformAlias"
                  label="System Alias"
                  type="text"
                  control={control}
                  setFormValues={setFormValues}
                  description="Maximum 5 characters"
                  rules={{
                    required: 'Please enter system alias',
                    maxLength: {
                      value: 5,
                      message: 'Maximum length is 5 characters'
                    },
                    pattern: {
                      value: /^[a-z0-9-]+$/,
                      message:
                        'Platform name should be lowercase only and whitespaces are not allowed.'
                    }
                  }} />
              </FormInputContainer>
              <FormInputContainer>
                <FormMultiselect
                  label="Environments"
                  name="environments"
                  control={control}
                  setFormValues={setFormValues}
                  options={ENVIRONMENT_LIST}
                  placeholder="Select Environment(s)"
                  description="RHOCP Tenant Environment(s) to be provisioned"
                  rules={{required: 'Please select atleast 1 environment'}}/>
              </FormInputContainer>
              <FormInputContainer>
                {(showEnvAdGroupNames) ? 
                  <FormInput
                    name="envAdGroups"
                    label="AD Group Name"
                    control={control}
                    setFormValues={setFormValues}
                    rules={{
                      required: 'Please enter AD Security Group Name',
                      pattern: {
                        value: /^[a-z0-9-]+$/,
                        message: 'AD Security group name should be lowercase only and whitespaces are not-allowed'
                      }
                    }}
                    disabled /> : null
                }
              </FormInputContainer>
              <FormInputContainer>
                <FormInput
                  name="services"
                  label="Services"
                  description="Comma-separated list of namespaces needed by tenants to be provisioned"
                  control={control}
                  setFormValues={setFormValues}/>
              </FormInputContainer>
              <FormInputContainer>
                <FormCheckBox
                  name="sandbox"
                  label="Sandbox Flag"
                  description="Option to create sandbox environment"
                  control={control}
                  setFormValues={setFormValues}
                  check_name="Create Sandbox environment"/>
              </FormInputContainer>
              <FormInputContainer>
                <FormCheckBox
                  name="scc"
                  label="Security Context Contraints Flag"
                  description="Pods are using Restricted SCC (Security Context Contraints), thus you cannot use images that are only run as root or a specific user. Do you want to be able to run images as Root or a specific user?"
                  control={control}
                  setFormValues={setFormValues}
                  check_name="Run as root inside pods"/>
              </FormInputContainer>
            </SpaceBetween>
          </Container>
          <Container>
            <Header variant="h3">Tag Details</Header>
            <RhocpTenantTagsNew/>
          </Container>
        </SpaceBetween>
      </Form>
  )
}

export default RhocpTenantCreationForm