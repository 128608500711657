import { FC } from 'react'
import KeyValuePairs from '@aws-northstar/ui/components/KeyValuePairs'
import Container from '@cloudscape-design/components/container'
import Header from '@cloudscape-design/components/header'
import data, { sumByItem } from '../../../../types'

const totalOrder = data.length
const mostSoldItem = sumByItem[sumByItem.length - 1].item
const totalAmount = sumByItem.reduce((sum: number, item: any) => {
	sum += item.amount
	return sum
}, 0)

const totalDiscount = sumByItem.reduce((sum: number, item: any) => {
	sum += item.discount
	return sum
}, 0)

const transactionsWithDiscount = data.reduce((count, order) => {
	if (order.discounted) {
		count++
	}
	return count
}, 0)

const pendingOrder = data.reduce((count, order) => {
	if (order.status === 'Processing') {
		count++
	}
	return count
}, 0)

const Overview: FC = () => {
	return (
		<Container header={<Header>Hello World</Header>}>
			<KeyValuePairs
				items={[
					[
						{
							label: 'Total Order',
							value: totalOrder
						},
						{
							label: 'Number of transactions with discount',
							value: transactionsWithDiscount
						}
					],
					[
						{
							label: 'Total revenue',
							value: `$${totalAmount}`
						},
						{
							label: 'Total discount',
							value: `$${totalDiscount}`
						}
					],
					[
						{
							label: 'Most sold item',
							value: mostSoldItem
						},
						{
							label: 'Number of pending orders',
							value: `$${pendingOrder}`
						}
					]
				]}
			/>
		</Container>
	)
}

export default Overview
