import { useMutation } from '@tanstack/react-query'

import api from '../../api'

const useTagsByAccountId = () => {
	return useMutation({
		mutationFn: (accountRequestID: string) => {
			return api.accounts.getSingleAccount(accountRequestID)
		},

		onSuccess: (data) => {
			const response = data && data.body[0]
			return response
		},
		onError: (error) => {
			return error
		}
	})
}

export default useTagsByAccountId
