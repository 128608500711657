import { create } from 'zustand'
import { produce } from 'immer'
import { mountStoreDevtool } from 'simple-zustand-devtools'

type gcpProjectCreationFormState = {
	currentStep: number
	isFormValid: boolean
	formValues: Record<string, any>
}

type gcpProjectCreationFormActions = {
	actions: {
		setFormValues: (fieldName: string, value: any) => void
		setCurrentStep: (step: number) => void
		setFormValidity: (isValid: boolean) => void
		clearFormValues: () => void
	}
}

const initialFormValues = {
	//projectName: 'demo-project-name',
	// gcpProjectTypes: [
	// 	{
	// 		label: 'Sandbox',
	// 		value: 'Sandbox'
	// 	}
	// ],
	gcpProjectType: '',
	sandboxDistributionList: '',
	sandboxMonthlyBudgetCap: 1,
	//sandboxRepositoryStructure: 'monoRepository',
	sandboxSSOUserNames: ''
}

const gcpProjectCreationFromStore = create<
	gcpProjectCreationFormState & gcpProjectCreationFormActions
>((set, _get) => ({
	currentStep: 0,
	isFormValid: false,
	formValues: initialFormValues,
	actions: {
		clearFormValues: () => set(() => ({ formValues: initialFormValues })),

		setFormValidity: (isValid) => set(() => ({ isFormValid: isValid })),

		setFormValues: (fieldName, value) =>
			set((state) =>
				produce(state, (draft) => {
					draft.formValues[fieldName] = value
				})
			),

		setCurrentStep: (step) => {
			set(() => ({
				currentStep: step
			}))
		}
	}
}))

export const usegcpProjectCreationFormStore = () =>
	gcpProjectCreationFromStore((state) => state)

if (process.env.NODE_ENV !== 'production') {
	mountStoreDevtool('gcpProjectCreationFormStore', gcpProjectCreationFromStore)
}
