import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { PlatformEditRequest } from '../../types/platforms'
import api from '../../api'

const useEditPlatform = (platformID: string) => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (platformEdit: PlatformEditRequest) => {
			return api.platforms.editPlatform(platformEdit)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['platforms'] })
			queryClient.invalidateQueries({
				queryKey: ['platforms', platformID]
			})
		},
		onError: (error) => {
			console.log(error)
		}
	})
}

export default useEditPlatform
