import {
	Box,
	Container,
	Header,
	ColumnLayout
} from '@cloudscape-design/components'
import { FC } from 'react'
import TextContent from '@cloudscape-design/components/text-content'

interface ValueWithLabelProps {
	label: string
	value: string
	version: string[]
}

interface ApplicationProps {
	Application: Record<any, any>
}

const ValueWithLabel: FC<ValueWithLabelProps> = ({ label, value, version }) => {
	return (
		<div>
			<Box variant="awsui-key-label">{label}</Box>
			<TextContent>
				<p>{typeof value == 'object' ? value['value'] : value}</p>
			</TextContent>
		</div>
	)
}

const RenderApplicationFields: FC<ApplicationProps> = ({ Application }) => {
	return (
		<>
			<Container>
				<Header variant="h3">Request Details</Header>
				<ColumnLayout columns={3} variant="text-grid">
					{Application.map((element: ValueWithLabelProps, index: number) => {
						return <ValueWithLabel {...element} key={element.label + index} />
					})}
				</ColumnLayout>
			</Container>
		</>
	)
}

export default RenderApplicationFields
