import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { PlatformApprovalRequest } from '../../types/platforms'
import api from '../../api'

const useAcceptPlatform = (platformID: string) => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (platformApproval: PlatformApprovalRequest) => {
			return api.platforms.acceptPlatform(platformApproval)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['platforms'] })
			queryClient.invalidateQueries({ queryKey: ['platforms', platformID] })
		}
	})
}

export default useAcceptPlatform
