import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
import type { ApplicationApprovalRequest } from '../../types/applications'

const { APPLICATIONS_API_URL } = CONSTANT

const acceptApplication = async (
	applicationApproval: ApplicationApprovalRequest
): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(
		`${APPLICATIONS_API_URL}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				action: 'APPROVE'
			},
			body: JSON.stringify(applicationApproval)
		}
	)

	return response.json()
}

export default acceptApplication
