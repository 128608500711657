import {FC, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {
    Alert,
    Box,
    ButtonDropdown,
    Container,
    ContentLayout,
    Grid,
    Header,
    SpaceBetween
} from '@cloudscape-design/components'

import HeaderWithStatusBadges from '../../../components/Common/HeaderWithStatusBadges'
import LoadingSpinner from '../../../components/LoadingSpinner'
import ApprovalForm from '../../../components/Account/ApprovalForm'

import useSingleAccount from '../../../hooks/query/useSingleAccount'
import {KeyValuePairs} from '@aws-northstar/ui'

import AccountApprovalDetails from '../../../components/Account/ApprovalDetails'
import RejectionForm from '../../../components/Account/RejectionForm'

import EditGenericTags from '../../../components/ControlledFormInputs/GenericTagsEdit'
import ReadGenericTags from '../../../components/ControlledFormInputs/GenericTagsRead'

import RequiredPermissions from '../../../helper/RequiredPermissions'
import CONSTANTS from '../../../constants'

import useUserPermissions from '../../../hooks/query/useUserPermissions'

const extractUserNamesFromObject = (
    SSOUserNames: Array<{
        displayName: string
        userName: string
    }>
): Array<string> => {
    return SSOUserNames.map((user) => user.userName)
}

const AccountDetails: FC = () => {
    const CURRENT_USER_ID = JSON.parse(localStorage["okta-token-storage"])["idToken"]["claims"]["email"]
    const {
        data: userPermissions,
        isLoading: isLoadingPermissions,
        error: permissionsError
    } = useUserPermissions(CURRENT_USER_ID);
    const hasSpecificPermissions = (permissions: string[], requiredPermissions: string[]): boolean => {
        return requiredPermissions.some(requiredPerm => permissions.includes(requiredPerm));
    };
    const specificPermissions = ["accounts:*:*", "accounts:*:approve", "*:*:*"];
    const hasRequiredPermissions = userPermissions && hasSpecificPermissions(userPermissions.permissions, specificPermissions);
    const {id: accountRequestID} = useParams() as { id: string }
    const {data: thisAccount, isLoading} = useSingleAccount(accountRequestID, true)

    const [showApprovalForm, setShowApprovalForm] = useState(false)
    const [showRejectionForm, setShowRejectionForm] = useState(false)


    if (!thisAccount || isLoading) return <LoadingSpinner/>

    if (thisAccount.status === "ERROR") {
        return (
            <Alert
                statusIconAriaLabel="Error"
                type="error"
                header="An error occurred">
                {thisAccount.details.message}
            </Alert>
        );
    }

    const keyValuePairsItems = [
        [
            {
                label: 'Requestor ID',
                value: thisAccount?.requestorID
            },
            {
                label: 'Account Type',
                value: thisAccount?.type
            },
            {
                label: 'SSO User Names',
                value: extractUserNamesFromObject(thisAccount?.details.SSOUserNames)
            }
        ],
        [
            {
                label: 'Repository Structure',
                value: thisAccount?.details.repositoryStructure
            },
            {
                label: 'Distribution List',
                value: thisAccount?.details.distributionList
            },
            {
                label: 'Budget Threshold Alert',
                value: thisAccount?.details.monthlyBudgetCap
            }
        ]
    ]

    console.log(thisAccount)
    let isAllTagsApproved = false
    if (thisAccount?.tags !== undefined) {
        isAllTagsApproved = true
        let accountTags = thisAccount?.tags
        let tagsApprovalStatuses = accountTags.approvalStatus
        for (const tagStatus of tagsApprovalStatuses) {
            if (tagStatus.status !== 1) {
                isAllTagsApproved = false
            }
        }
    }

    return (
        <ContentLayout
            header={
                <HeaderWithStatusBadges
                    headerName={thisAccount?.details.projectName}
                    status={thisAccount?.status}
                />
            }
        >
            <Grid
                gridDefinition={[
                    {colspan: {default: 3, xxs: 9}},
                    {colspan: {default: 9, xxs: 3}}
                ]}
            >
                <SpaceBetween direction="vertical" size="l">
                    <Container header={<Header variant="h3">Request Details</Header>}>
                        <KeyValuePairs items={keyValuePairsItems}/>
                    </Container>
                    {!hasRequiredPermissions && (thisAccount.status === 'PENDING_APPROVAL') && (
                        <RequiredPermissions
                            requiredPermissions={CONSTANTS.ACCOUNT_REQUESTOR}
                        >
                            <Container header={<Header variant="h3">Tags Details</Header>}>
                                {thisAccount.tagsID && (
                                    <ReadGenericTags tagsId={thisAccount.tagsID}/>
                                )}
                            </Container>
                        </RequiredPermissions>
                    )}
                    {(
                        thisAccount.status === 'APPROVED' ||
                        thisAccount.status === 'REJECTED') && (
                        <RequiredPermissions
                            requiredPermissions={CONSTANTS.ACCOUNT_REQUESTOR}
                        >
                            <Container header={<Header variant="h3">Tags Details</Header>}>
                                {thisAccount.tagsID && (
                                    <ReadGenericTags tagsId={thisAccount.tagsID}/>
                                )}
                            </Container>
                        </RequiredPermissions>
                    )}
                    {/* {(thisAccount.status === 'APPROVED' ||
                        thisAccount.status === 'REJECTED') && (
                        <RequiredPermissions
                            requiredPermissions={CONSTANTS.ACCOUNT_APPROVER}
                        >
                            <Container header={<Header variant="h3">Tags Details</Header>}>
                                {thisAccount.tagsID && (
                                    <ReadGenericTags tagsId={thisAccount.tagsID}/>
                                )}
                            </Container>
                        </RequiredPermissions>
                    )} */}
                    {thisAccount.status === 'PENDING_APPROVAL' && hasRequiredPermissions && (
                        <RequiredPermissions
                            requiredPermissions={CONSTANTS.ACCOUNT_APPROVER}
                        >
                            <Container header={<Header variant="h3">Tags Details</Header>}>
                                {thisAccount.tagsID && (
                                    <EditGenericTags tagsId={thisAccount.tagsID}/>
                                )}
                            </Container>
                        </RequiredPermissions>
                    )}
                    {thisAccount.status === 'PENDING_APPROVAL' &&
                        CURRENT_USER_ID !== thisAccount.requestorID && (
                            <RequiredPermissions
                                requiredPermissions={CONSTANTS.ACCOUNT_APPROVER}
                            >
                                <Box float="right">
                                    <ButtonDropdown
                                        items={isAllTagsApproved ? [
                                            {text: 'To Approve...', id: 'to-approve'},
                                            {text: 'To Reject...', id: 'to-reject'}
                                        ] : [
                                            {text: 'To Reject...', id: 'to-reject'}
                                        ]}
                                        onItemClick={({detail}) => {
                                            if (detail.id === 'to-approve') {
                                                setShowApprovalForm(true)
                                                setShowRejectionForm(false)
                                            }

                                            if (detail.id === 'to-reject') {
                                                setShowApprovalForm(false)
                                                setShowRejectionForm(true)
                                            }
                                        }}
                                        variant="primary"
                                    >
                                        Action
                                    </ButtonDropdown>
                                </Box>
                            </RequiredPermissions>
                        )}

                    {showApprovalForm && (
                        <ApprovalForm
                            thisAccountRequestID={thisAccount.accountRequestID}
                            shouldShowApprovalForm={setShowApprovalForm}
                        />
                    )}

                    {showRejectionForm && (
                        <RejectionForm
                            thisAccountRequestID={thisAccount.accountRequestID}
                            shouldShowRejectionForm={setShowRejectionForm}
                        />
                    )}

                    {thisAccount.status === 'APPROVED' && (
                        <AccountApprovalDetails
                            accountApprovalDetails={thisAccount.approval!}
                        />
                    )}
                </SpaceBetween>
            </Grid>
        </ContentLayout>
    )
}

export default AccountDetails
