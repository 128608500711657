import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

import {SpaceBetween, Button, ContentLayout, Grid, Box, Container, Header, ButtonDropdown} from '@cloudscape-design/components'
import HeaderWithStatusBadges from "../../../components/Common/HeaderWithStatusBadges";
import RenderTenantFields from "./RenderTenantFields";
import { RhocpTenant, RhocpTenantRejection } from "../../../types/rhocpTenant";
import RequiredPermissions from "../../../helper/RequiredPermissions";
import CONSTANTS from "../../../constants";
import { RejectionForm } from "./RejectionForm";
import { ApprovalForm } from "./ApprovalForm";
import useSingleRhocpTenant from "../../../hooks/query/useSingleRhocpTenant";
import LoadingSpinner from "../../../components/LoadingSpinner";
import CommentsOverview from "./CommentsOverview";
import ReadGenericTags from "../../../components/ControlledFormInputs/GenericTagsRead";
import EditGenericTags from "../../../components/ControlledFormInputs/GenericTagsEditGCP";
import useUserPermissions from "../../../hooks/query/useUserPermissions";

const RhocpTenantDetails: FC = () => {
  const { authState } = useOktaAuth()
  const CURRENT_USER_ID = authState?.idToken?.claims.email as string
  
  const { id } = useParams() as { id: string }

  const { data: thisTenant, isLoading } = useSingleRhocpTenant(id)

  const [showApprovalForm, setshowApprovalForm] = useState(false)
  const [showRejectionForm, setshowRejectionForm] = useState(false)

  const { data: userPermissions, isLoading: isLoadingPermissions } = useUserPermissions(CURRENT_USER_ID)

  const hasSpecificPermissions = (
    permissions: string[],
    requiredPermissions: string[]
  ): boolean => {
    return requiredPermissions.some((requiredPerm) =>
      permissions.includes(requiredPerm)
    )
  }

  const specificPermissions = [
    'rhocpTenants:*:*',
    'rhocpTenants:*:approve',
    '*:*:*'
  ]

  const hasRequiredPermissions = userPermissions && hasSpecificPermissions(userPermissions.permissions, specificPermissions)

  if (!thisTenant || isLoading) {
    return <LoadingSpinner/>
  }

  const tenantVersionedObject = getKeyValueRhocpTenant(thisTenant)

  const handleApproveClick = () => {
    setshowApprovalForm(true)
    setshowRejectionForm(false)
  }

  const handleRejectionClick = () => {
    setshowRejectionForm(true)
    setshowApprovalForm(false)
  }

  // to account for differences in schema from backend vs types defined in frontend
  if (thisTenant.status === 'REJECTED' && thisTenant.comments === undefined) {
    const rejectionMessage: RhocpTenantRejection = {
      commenterID: thisTenant.commenterID as string,
      comment: thisTenant.comment as string,
      timestamp: thisTenant.timestamp
    }
    thisTenant.comments = [rejectionMessage]
  }

  let isAllTagsApproved = false

  if (thisTenant.tags !== undefined) {
    isAllTagsApproved = true
    let tenanttags = thisTenant.tags
    let approvalStatuses = tenanttags.approvalStatus
    for (const tagStatus of approvalStatuses) {
      if (tagStatus.status !== 1) {
        isAllTagsApproved = false
      }
    }
  }
  
  return (
    <ContentLayout
      header={
        <HeaderWithStatusBadges
          headerName={thisTenant?.details.platformName} status={thisTenant.status}/>
      }>
        <Grid
          gridDefinition={[
            {colspan: {default: 3, xxs: 9}},
            {colspan: {default: 9, xxs: 3}}
          ]}>
            <SpaceBetween direction="vertical" size="l">
              <RenderTenantFields tenant={tenantVersionedObject}/>

              {!hasRequiredPermissions && thisTenant.status === 'PENDING_APPROVAL' && (
                <RequiredPermissions requiredPermissions={CONSTANTS.RHOCPTENANT_REQUESTOR}>
                  <Container
                    header={<Header variant="h3">Tag Details</Header>}>
                      {thisTenant.tagsID && (
                        <ReadGenericTags tagsId={thisTenant.tagsID}/>
                      )}
                    </Container>
                </RequiredPermissions>
              )}

              {(thisTenant.status === 'APPROVED' || thisTenant.status === 'REJECTED') && (
                <RequiredPermissions requiredPermissions={CONSTANTS.RHOCPTENANT_REQUESTOR}>
                  <Container
                    header={<Header variant="h3">Tag Details</Header>}>
                      {thisTenant.tagsID && (
                        <ReadGenericTags tagsId={thisTenant.tagsID}/>
                      )}
                    </Container>
                </RequiredPermissions>
              )}

              {(thisTenant.status === 'PENDING_APPROVAL') && hasRequiredPermissions && (
                <RequiredPermissions requiredPermissions={CONSTANTS.RHOCPTENANT_APPROVER}>
                  <Container 
                    header={<Header variant="h3">Tag Details</Header>}>
                      {thisTenant.tagsID && (
                        <EditGenericTags tagsId={thisTenant.tagsID} isPvm={false}/>
                      )}
                    </Container>
                </RequiredPermissions>
              )}

              {thisTenant.status === 'PENDING_APPROVAL' 
                && CURRENT_USER_ID !== thisTenant.requestorID && (
                  <RequiredPermissions requiredPermissions={CONSTANTS.RHOCPTENANT_APPROVER}>
                    <Box float="left">
                      <ButtonDropdown
                        items={isAllTagsApproved ? [
                          { text: 'Approve', id: 'approve' },
                          { text: 'Reject', id: 'reject' }
                        ] : [
                          {text: 'Reject', id: 'reject'}
                        ]}
                        onItemClick={({detail}) => {
                          if (detail.id === 'approve') {
                            setshowApprovalForm(true)
                            setshowRejectionForm(false)
                          }

                          if (detail.id === 'reject') {
                            setshowApprovalForm(false)
                            setshowRejectionForm(true)
                          }
                        }}>
                        Action
                      </ButtonDropdown>
                    </Box>
                  </RequiredPermissions>
                )
              }
              {showApprovalForm && (
                <ApprovalForm 
                  tenantID={thisTenant.rhocpTenantID}
                  showForm={setshowApprovalForm}/>
              )}
              {showRejectionForm && (
                <RejectionForm 
                  tenantID={thisTenant.rhocpTenantID} 
                  showForm={setshowRejectionForm}/>
              )}

            </SpaceBetween>
            <SpaceBetween direction="vertical" size="l">
              <Container header={<Header variant="h3">Comment History</Header>}>
                <CommentsOverview comments={thisTenant.comments}/>
              </Container>
            </SpaceBetween>

        </Grid>
    </ContentLayout>
  )
}

const getKeyValueRhocpTenant = (tenant: RhocpTenant) => {
  return [
    {
      label: 'Requester',
      value: tenant?.requestorID,
      version: getVersionStr(tenant?.version, 'requestorID')
    },
    {
      label: 'System Name',
      value: tenant.details?.platformName,
      version: getVersionStr(tenant?.version, 'systemName')
    },
    {
      label: 'System Alias',
      value: tenant.details?.platformAlias,
      version: getVersionStr(tenant?.version, 'systemAlias')
    },
    {
      label: 'System Owner',
      value: tenant.details?.platformOwner,
      version: getVersionStr(tenant?.version, 'systemOwner')
    },
    {
      label: 'WBS Code',
      value: tenant.details?.WBSCode || 'N/A',
      version: getVersionStr(tenant?.version, 'WBSCode')
    },
    {
      label: 'Cost Center',
      value: tenant?.details?.costCenter || 'N/A',
      version: getVersionStr(tenant?.version, 'costCenter')
    },
    {
      label: 'Services',
      value: tenant?.details?.services.toString(),
      version: getVersionStr(tenant?.version, 'services')
    },
    {
      label: 'Environments',
      value: tenant?.details?.environments.map(env => (env.label)).join(','),
      version: getVersionStr(tenant?.version, 'environments')
    },
    {
      label: 'Group',
      value: tenant?.details.domain,
      version: getVersionStr(tenant?.version, 'group')
    }
  ]
}

const getVersionStr = (version: Array<any> | undefined, key: string) => {
  let versionItems: string[] = []
  version?.forEach((element) => {
    if (!element[key]) return
    if (key === 'region' && element.region) {
      versionItems.unshift(`v${element.versionID}: ${element.region[0].label}`)
    } else if (key === 'maintenanceWindowDay' && element.maintenanceWindowDay) {
      versionItems.unshift(
        `v${element.versionID}: ${element.maintenanceWindowDay.label}`
      )
    }
    else {
      versionItems.unshift(`v${element.versionID}: ${element[key]}`)
    }
  })
  return versionItems
}

export default RhocpTenantDetails