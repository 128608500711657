import { useQuery } from "@tanstack/react-query"

import type { RhocpTenant } from "../../types/rhocpTenant"
import api from "../../api"

const useRhocpTenants = () => {
  return useQuery({
    queryKey: ['rhocpTenants'],
    queryFn: () => api.rhocpTenants.getRhocpTenants(),
    select(data) {
      return data.body as Array<RhocpTenant>
    }
  })
}

export default useRhocpTenants