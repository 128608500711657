import { useQuery } from '@tanstack/react-query'

import type { Project } from '../../types/projects'
import api from '../../api'

const useProjects = () => {
	return useQuery({
		queryKey: ['projects'],
		queryFn: () => api.projects.getProjects(),
		select(data) {
			return data.body as Array<Project>
		}
	})
}

export default useProjects
