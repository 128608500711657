import { FC } from 'react'
import {
	Badge,
	Box,
	Button,
	ColumnLayout,
	Container,
	Header,
	SpaceBetween
} from '@cloudscape-design/components'
import { useAccountCreationFormStore } from '../../../../stores/accounts/newAccountCreationFormStore'

import { useGenericTagsManagementFormStore } from '../../../../stores/tags/genericTagsManagementFormStore'
import { keysToLabel } from '../../../../helper/toCamelCase'

const Review: FC = () => {
	const {
		formValues,
		actions: { setCurrentStep, setFormValues }
	} = useAccountCreationFormStore()

	const { formValues: tagformValues } = useGenericTagsManagementFormStore()

	const tagKeys = Object.keys(tagformValues)

	return (
		<SpaceBetween direction="vertical" size="l">
			<Header
				variant="h3"
				actions={<Button onClick={() => setCurrentStep(0)}>Edit</Button>}
			>
				Step 1: Project Details
			</Header>
			<Container>
				<ColumnLayout columns={2} variant="text-grid">
					<div>
						<Box variant="awsui-key-label">Account Types</Box>
						<SpaceBetween size="xxs" direction="horizontal">
							{formValues.accountTypes?.map(
								(type: { value: string }, i: number) => {
									return (
										<Badge color="blue" key={i}>
											{type.value}
										</Badge>
									)
								}
							)}
						</SpaceBetween>
					</div>
				</ColumnLayout>
			</Container>
			<Header
				variant="h3"
				actions={<Button onClick={() => setCurrentStep(1)}>Edit</Button>}
			>
				Step 2: Account Details
			</Header>
			{formValues.accountTypes?.map(
				(accountType: { label: string; value: string }, i: number) => {
					return (
						<Container
							header={<Header variant="h3">{accountType.label}</Header>}
							key={i}
						>
							<ColumnLayout columns={2} variant="text-grid">
								<div>
									<Box variant="awsui-key-label">Distribution List</Box>
									{formValues[`${accountType.value}DistributionList`]}
								</div>

								<div>
									<Box variant="awsui-key-label">
										Account Monthly Budget Cap
									</Box>
									{formValues[`${accountType.value}MonthlyBudgetCap`]}
								</div>

								<div>
									<Box variant="awsui-key-label">
										Platform Infrastructure Repository Structure
									</Box>
									{formValues[`${accountType.value}RepositoryStructure`] ||
										setFormValues(
											`${accountType.value}RepositoryStructure`,
											'monoRepository'
										)}
								</div>
							</ColumnLayout>
						</Container>
					)
				}
			)}
			<Header
				variant="h3"
				actions={<Button onClick={() => setCurrentStep(2)}>Edit</Button>}
			>
				Step 3: Tag Details
			</Header>
			<Container>
				<ColumnLayout columns={2} variant="text-grid">
					{tagKeys?.map((tagkey) => (
						<div key={tagkey}>
							<Box variant="awsui-key-label">
								{keysToLabel(tagkey) == 'W B S Code'
									? 'WBS Code'
									: keysToLabel(tagkey)}
							</Box>
							{typeof tagformValues[tagkey] == 'object'
								? tagformValues[tagkey]['value']
								: tagformValues[tagkey] == ''
								? 'NA'
								: tagformValues[tagkey]}
						</div>
					))}
				</ColumnLayout>
			</Container>
		</SpaceBetween>
	)
}

export default Review
