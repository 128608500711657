import { FC, useEffect, useState } from 'react'
import { Control, Controller, UseControllerProps } from 'react-hook-form'

import {
	FormField,
	Input,
	InputProps,
	TokenGroup
} from '@cloudscape-design/components'

export interface FormTokenGroupProps {
	label: string
	name: string
	control: Control
	items: Array<{ label: string; dismissLabel: string }>
	placeholder: string
	defaultValue?: Array<{ label: string; dismissLabel: string }>
	description?: string
	info?: string
	rules?: UseControllerProps['rules']
	type?: InputProps.Type
	setFormValues: (name: string, value: any) => void
}

const FormTokenGroup: FC<FormTokenGroupProps> = ({
	label,
	name,
	control,
	description,
	defaultValue,
	info,
	rules,
	items,
	placeholder,
	type,
	setFormValues
}) => {
	const [text, setText] = useState('')
	const [tokenItems, setTokenItems] = useState(items)

	useEffect(() => {
		setFormValues(name, tokenItems)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tokenItems])

	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			defaultValue={defaultValue}
			render={({ field: { name, onChange, value }, fieldState: { error } }) => {
				return (
					<FormField
						label={label}
						description={description}
						info={info}
						errorText={error?.message}
					>
						<Input
							name={name}
							value={text}
							type={type}
							placeholder={placeholder}
							onChange={({ detail }) => {
								setText(detail.value)
							}}
							onKeyDown={({ detail }) => {
								if (detail.key !== 'Enter') return
								if (text === '') return

								setTokenItems((prevItems) => [
									...prevItems,
									{ label: text, dismissLabel: `Remove item ${text}` }
								])
								onChange([
									...tokenItems,
									{ label: text, dismissLabel: `Remove item ${text}` }
								])
								setText('')
							}}
						/>
						<TokenGroup
							onDismiss={({ detail: { itemIndex } }) => {
								setTokenItems([
									...tokenItems.slice(0, itemIndex),
									...tokenItems.slice(itemIndex + 1)
								])
							}}
							items={tokenItems}
						/>
					</FormField>
				)
			}}
		/>
	)
}

export default FormTokenGroup
