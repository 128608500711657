import { useMutation, useQueryClient } from "@tanstack/react-query"
import { RhocpTenantApprovalRequest } from "../../types/rhocpTenant"

import api from "../../api"

const useAcceptRhocpTenant = (rhocpTenantID: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (rhocpTenantApproval: RhocpTenantApprovalRequest) => {
      return api.rhocpTenants.acceptRhocpTenant(rhocpTenantApproval)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['rhocpTenants'] })
      queryClient.invalidateQueries({ queryKey: ['rhocpTenant', rhocpTenantID] })
    },
    onError: (e) => {
      console.log(e)
    }
  })
}

export default useAcceptRhocpTenant