import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { AccountRejectionRequest } from '../../types/account'
import api from '../../api'

const useRejectAccount = (accountRequestID: string) => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (accountRejection: AccountRejectionRequest) => {
			return api.accounts.rejectAccount(accountRejection)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['accounts'] })
			queryClient.invalidateQueries({
				queryKey: ['accounts', accountRequestID]
			})
		}
	})
}

export default useRejectAccount
