import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { ProjectRejectionRequest } from '../../types/projects'
import api from '../../api'

const useRejectProject = (projectID: string) => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (projectRejection: ProjectRejectionRequest) => {
			return api.projects.rejectProject(projectRejection)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['projects'] })
			queryClient.invalidateQueries({ queryKey: ['projects', projectID] })
		}
	})
}

export default useRejectProject
