import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { CreateAccessRequest } from '../../types/access'
import api from '../../api'

const useCreatePermissionsForUser = () => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (createAccessRequest: CreateAccessRequest) => {
			return api.accessManagement.createPermissionsForUser(createAccessRequest)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['allUserPermissions']
			})
		}
	})
}

export default useCreatePermissionsForUser
