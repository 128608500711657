import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { AccountRequest } from '../../types/account'
import api from '../../api'

const useCreateNewAccount = () => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (newAccount: AccountRequest) => {
			return api.accounts.createNewAccount(newAccount)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['projects'] })
		}
	})
}

export default useCreateNewAccount
