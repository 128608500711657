import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
import type { ProjectApprovalRequest } from '../../types/projects'

const { PROJECTS_API_URL } = CONSTANT

const acceptProject = async (
	projectApproval: ProjectApprovalRequest
): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(`${PROJECTS_API_URL}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			action: 'APPROVE'
		},
		body: JSON.stringify(projectApproval)
	})

	return response.json()
}

export default acceptProject
