import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { ProjectEditRequest } from '../../types/projects'
import api from '../../api'

const useEditProject = (projectID: string) => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (projectEdit: ProjectEditRequest) => {
			return api.projects.editProject(projectEdit)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['projects'] })
			queryClient.invalidateQueries({
				queryKey: ['projects', projectID]
			})
		},
		onError: (error) => {
			console.log(error)
		}
	})
}

export default useEditProject
