import { FC } from 'react'
import { LoginCallback, useOktaAuth } from '@okta/okta-react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { Button, SpaceBetween } from '@cloudscape-design/components'
import LoadingSpinner from '../../components/LoadingSpinner'

const RootRedirect: FC = () => {
	const { oktaAuth, authState } = useOktaAuth()
	const [searchParams] = useSearchParams()

	// Loading component while Okta authentication is in progress
	// searchParams.get('code') is used to check if the user is being redirected back from Okta
	// e.g: http://localhost:3000/index.html?code=xxxxxxxxxxxxxxxxxxxx&state=xxxxxxxxxxxxxxxxxxxx
	if (!authState || searchParams.get('code')) {
		return (
			<>
				<LoginCallback />
				<LoadingSpinner />
			</>
		)
	}

	// Redirect to dashboard if user is authenticated
	if (authState.isAuthenticated) {
		// Redirect to the path specified in the URL query string
		if (searchParams.get('path')) {
			return <Navigate to={`/${searchParams.get('path')}`} />
		}

		return <Navigate to="/dashboard" />
	}

	// Displays login button if user is not authenticated
	return (
		<SpaceBetween direction="vertical" size="l">
			<h1>Welcome to Globe SSP</h1>
			<Button onClick={() => oktaAuth.signInWithRedirect()}> Login </Button>
		</SpaceBetween>
	)
}

export default RootRedirect
