import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'

import { Access } from '../../types/access'
const { ACCESS_MANAGEMENT_API_URL } = CONSTANT

const getAllUserPermissions = async (): Promise<{
	status: number
	body: Array<Access>
}> => {
	const response = await fetchWithAuthorizationHeader(
		`${ACCESS_MANAGEMENT_API_URL}`
	)
	return response.json()
}

export default getAllUserPermissions
