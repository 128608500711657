import {useQuery} from '@tanstack/react-query'

import type {Project} from '../../types/projects'
import api from '../../api'

const useSingleProject = (projectID: string, getTags: boolean = false) => {
    return useQuery({
        queryKey: ['projects', projectID],
        queryFn: () => api.projects.getSingleProject(projectID, getTags),
        select(data) {
            if (data.body !== undefined) {
                const response = sortComments(data.body[0])
                return response as Project
            } else {
                let response = {} as Project
                response["status"] = "ERROR"
                response["details"] = data
                return response
            }

        }
    })
}
const sortComments = (response: Record<any, any>) => {
    let sortedCommentResponse = JSON.parse(JSON.stringify(response))
    let comments = (response && response.comments) || []
    sortedCommentResponse['comments'] = comments.sort(
        (a: any, b: any) =>
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    )
    return sortedCommentResponse
}

export default useSingleProject
