import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
    Alert,
    Box,
    ButtonDropdown,
    Container,
    ContentLayout,
    Grid,
    Header,
    SpaceBetween
} from '@cloudscape-design/components'

import HeaderWithStatusBadges from '../../../components/Common/HeaderWithStatusBadges'
import LoadingSpinner from '../../../components/LoadingSpinner'
import ApprovalForm from '../../../components/gcpProject/ApprovalForm'

import useSingleGcpProject from '../../../hooks/query/useSingleGcpProject'
import { KeyValuePairs } from '@aws-northstar/ui'

import GcpProjectApprovalDetails from '../../../components/gcpProject/ApprovalDetails'
import RejectionForm from '../../../components/gcpProject/RejectionForm'

import EditGenericTags from '../../../components/ControlledFormInputs/GenericTagsEditGCP'
import ReadGenericTagsGCP from '../../../components/ControlledFormInputs/GenericTagsReadGCP'

import RequiredPermissions from '../../../helper/RequiredPermissions'
import CONSTANTS from '../../../constants'

import useUserPermissions from '../../../hooks/query/useUserPermissions'

const extractUserNamesFromObject = (
    SSOUserNames: Array<{
        displayName: string
        userName: string
    }>
): Array<string> => {
    return SSOUserNames.map((user) => user.userName)
}

const GcpProjectDetails: FC = () => {
    const CURRENT_USER_ID = JSON.parse(localStorage["okta-token-storage"])["idToken"]["claims"]["email"]
    const {
        data: userPermissions,
        isLoading: isLoadingPermissions,
        error: permissionsError
    } = useUserPermissions(CURRENT_USER_ID);
    const hasSpecificPermissions = (permissions: string[], requiredPermissions: string[]): boolean => {
        return requiredPermissions.some(requiredPerm => permissions.includes(requiredPerm));
    };
    const specificPermissions = ["gcpProjects:*:*", "gcpProjects:*:approve", "*:*:*"];
    const hasRequiredPermissions = userPermissions && hasSpecificPermissions(userPermissions.permissions, specificPermissions);
    const { id: gcpProjectID } = useParams() as { id: string }
    const { data: thisgcpProject, isLoading } = useSingleGcpProject(gcpProjectID, true)

    const [showApprovalForm, setShowApprovalForm] = useState(false)
    const [showRejectionForm, setShowRejectionForm] = useState(false)


    if (!thisgcpProject || isLoading) return <LoadingSpinner />

    if (thisgcpProject.status === "ERROR") {
        return (
            <Alert
                statusIconAriaLabel="Error"
                type="error"
                header="An error occurred">
                {thisgcpProject.details.message}
            </Alert>
        );
    }

    const keyValuePairsItems = [
        [
            {
                label: 'Requestor ID',
                value: thisgcpProject?.requestorID
            },
            {
                label: 'Project Type',
                value: thisgcpProject?.type
            },
            {
                label: 'Budget Threshold Alert',
                value: thisgcpProject?.details.monthlyBudgetCap
            }
        ],
        [
            {
                label: 'Project Viewer Distribution List',
                value: thisgcpProject?.details.projectViewerDistributionList
            },
            {
                label: 'Budget Alert Distribution List',
                value: thisgcpProject?.details.budgetAlertsDistributionList
            }
        ]
    ]

    console.log(thisgcpProject)
    let isAllTagsApproved = false
    if (thisgcpProject?.tags !== undefined) {
        isAllTagsApproved = true
        let gcpProjectTags = thisgcpProject?.tags
        let tagsApprovalStatuses = gcpProjectTags.approvalStatus
        for (const tagStatus of tagsApprovalStatuses) {
            if (tagStatus.status !== 1) {
                isAllTagsApproved = false
            }
        }
    }

    return (
        <ContentLayout
            header={
                <HeaderWithStatusBadges
                    headerName={thisgcpProject?.details.projectName}
                    status={thisgcpProject?.status}
                />
            }
        >
            <Grid
                gridDefinition={[
                    { colspan: { default: 3, xxs: 9 } },
                    { colspan: { default: 9, xxs: 3 } }
                ]}
            >
                <SpaceBetween direction="vertical" size="l">
                    <Container header={<Header variant="h3">Request Details</Header>}>
                        <KeyValuePairs items={keyValuePairsItems} />
                    </Container>
                    {!hasRequiredPermissions && (thisgcpProject.status === 'PENDING_APPROVAL') && (
                        <RequiredPermissions
                            requiredPermissions={CONSTANTS.GCPPROJECT_REQUESTOR}
                        >
                            <Container header={<Header variant="h3">Tags Details</Header>}>
                                {thisgcpProject.tagsID && (
                                    <ReadGenericTagsGCP tagsId={thisgcpProject.tagsID} />
                                )}
                            </Container>
                        </RequiredPermissions>
                    )}
                    {(
                        thisgcpProject.status === 'APPROVED' ||
                        thisgcpProject.status === 'REJECTED') && (
                        <RequiredPermissions
                            requiredPermissions={CONSTANTS.GCPPROJECT_REQUESTOR}
                        >
                            <Container header={<Header variant="h3">Tags Details</Header>}>
                                {thisgcpProject.tagsID && (
                                    <ReadGenericTagsGCP tagsId={thisgcpProject.tagsID} />
                                )}
                            </Container>
                        </RequiredPermissions>
                    )}
                    {thisgcpProject.status === 'PENDING_APPROVAL' && hasRequiredPermissions && (
                        <RequiredPermissions
                            requiredPermissions={CONSTANTS.GCPPROJECT_APPROVER}
                        >
                            <Container header={<Header variant="h3">Tags Details</Header>}>
                                {thisgcpProject.tagsID && (
                                    <EditGenericTags tagsId={thisgcpProject.tagsID} />
                                )}
                            </Container>
                        </RequiredPermissions>
                    )}
                    {thisgcpProject.status === 'PENDING_APPROVAL' &&
                        CURRENT_USER_ID !== thisgcpProject.requestorID && (
                            <RequiredPermissions
                                requiredPermissions={CONSTANTS.GCPPROJECT_APPROVER}
                            >
                                <Box float="right">
                                    <ButtonDropdown
                                        items={isAllTagsApproved ? [
                                            { text: 'To Approve...', id: 'to-approve' },
                                            { text: 'To Reject...', id: 'to-reject' }
                                        ] : [
                                            { text: 'To Reject...', id: 'to-reject' }
                                        ]}
                                        onItemClick={({ detail }) => {
                                            if (detail.id === 'to-approve') {
                                                setShowApprovalForm(true)
                                                setShowRejectionForm(false)
                                            }

                                            if (detail.id === 'to-reject') {
                                                setShowApprovalForm(false)
                                                setShowRejectionForm(true)
                                            }
                                        }}
                                        variant="primary"
                                    >
                                        Action
                                    </ButtonDropdown>
                                </Box>
                            </RequiredPermissions>
                        )}

                    {showApprovalForm && (
                        <ApprovalForm
                            thisgcpProjectID={thisgcpProject.gcpProjectID}
                            shouldShowApprovalForm={setShowApprovalForm}
                        />
                    )}

                    {showRejectionForm && (
                        <RejectionForm
                            thisgcpProjectID={thisgcpProject.gcpProjectID}
                            shouldShowRejectionForm={setShowRejectionForm}
                        />
                    )}

                    {thisgcpProject.status === 'APPROVED' && (
                        <GcpProjectApprovalDetails
                            gcpProjectApprovalDetails={thisgcpProject.approval!}
                        />
                    )}
                </SpaceBetween>
            </Grid>
        </ContentLayout>
    )
}

export default GcpProjectDetails