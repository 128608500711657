import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
import type { PlatformApprovalRequest } from '../../types/platforms'

const { PLATFORMS_API_URL } = CONSTANT

const acceptPlatform = async (
	platformApproval: PlatformApprovalRequest
): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(`${PLATFORMS_API_URL}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			action: 'APPROVE'
		},
		body: JSON.stringify(platformApproval)
	})
	return response.json()
}

export default acceptPlatform
