import { ReactNode } from 'react'
import { Route } from 'react-router-dom'

import RequiredAuth from './SecureRoute'

const withAuthRequired = (
	path: string,
	component: ReactNode,
	permissionsRequired: Array<string> = [] // by default, no permissions are required
) => {
	return (
		<Route
			path={path}
			element={<RequiredAuth permissionsRequired={permissionsRequired} />}
		>
			<Route path="" element={component} />
		</Route>
	)
}
export default withAuthRequired
