import { produce } from 'immer'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'

type EphemeralFormState = {
	isFormValid: boolean
	formValues: Record<string, any>
}

type EphemeralFormActions = {
	actions: {
		clearFormValues: () => void
		setFormValues: (fieldName: string, value: any) => void
		setFormValidity: (isValid: boolean) => void
	}
}

const initialFormValues = {
	project: '',
    workload: '',
    environment: '',
    networkID: '',
    awsAccountID: '',
    teardown_after_days: '',
    projectID: ''
}

const ephemeralFromStore = create<EphemeralFormState & EphemeralFormActions>(
	(set, _get) => ({
		isFormValid: false,
		formValues: initialFormValues,
		actions: {
			clearFormValues: () => set(() => ({ formValues: initialFormValues })),

			setFormValidity: (isValid) => set(() => ({ isFormValid: isValid })),

			setFormValues: (fieldName, value) =>
				set((state) =>
					produce(state, (draft) => {
						draft.formValues[fieldName] = value
					})
				)
		}
	})
)

export const useEphemeralFormStore = () =>
	ephemeralFromStore((state) => state)

if (process.env.NODE_ENV !== 'production') {
	mountStoreDevtool('EphemeralFormStore', ephemeralFromStore)
}
