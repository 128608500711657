import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { PlatformRequest } from '../../types/platforms'
import api from '../../api'

const useCreateNewPlatform = () => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (platform: PlatformRequest) => {
			return api.platforms.createNewPlatform(platform)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['platforms'] })
		}
	})
}

export default useCreateNewPlatform
