import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
import type { PlatformRejectionRequest } from '../../types/platforms'

const { PLATFORMS_API_URL } = CONSTANT

const rejectPlatform = async (
	platformRejection: PlatformRejectionRequest
): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(`${PLATFORMS_API_URL}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			action: 'REJECT'
		},
		body: JSON.stringify(platformRejection)
	})

	return response.json()
}

export default rejectPlatform
