import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
import type { gcpProjectRejectionRequest } from '../../types/gcpProject'

const { GCPPROJECTS_API_URL } = CONSTANT

const rejectgcpProject = async (
	gcpProjectRejection: gcpProjectRejectionRequest
): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(`${GCPPROJECTS_API_URL}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			action: 'REJECT'
		},
		body: JSON.stringify(gcpProjectRejection)
	})

	return response.json()
}

export default rejectgcpProject
