import CONSTANT from '../../constants'
import type { PlatformEditRequest } from '../../types/platforms'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
const { PLATFORMS_API_URL } = CONSTANT

const editPlatform = async (
	editPlatform: PlatformEditRequest
): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(`${PLATFORMS_API_URL}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			action: 'UPDATE'
		},
		body: JSON.stringify(editPlatform)
	})

	return response.json()
}

export default editPlatform
