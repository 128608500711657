import { produce } from 'immer'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'
 
type ApprovalFormState = {
    isFormValid: boolean
    formValues: Record<string, any>
}
 
type ApprovalFormActions = {
    actions: {
        clearFormValues: () => void
        setFormValues: (fieldName: string, value: any) => void
        setFormValidity: (isValid: boolean) => void
    }
}
 
const initialFormValues = {
    // platformName: 'the',
    // project: 'quick',
    // tfProviderEnvironment: 'brown',
    // tfProviderSecurity: 'fox',
    // tfProviderDisasterRecovery: 'jumped',
    // securityAliases: 'sa:over',
    // kmsAccountID: '123456789012',
    // dnsZoneApex: 'the',
    // keyPairName: 'lazy',
    // regionFactsImageAliases: [
    //  {
    //      key: 'key1',
    //      value: 'val1',
    //      existing: false
    //  },
    //  {
    //      key: 'key2',
    //      value: 'val2',
    //      existing: false
    //  }
    // ],
    // regionFactsSecurityAliases: [
    //  {
    //      key: 'key3',
    //      value: 'val3',
    //      existing: false
    //  },
    //  {
    //      key: 'key4',
    //      value: 'val4',
    //      existing: false
    //  }
    // ]
}
 
const _initialFormValues = {
    subnetAliases: [
        {
            alias: '',
            count: '',
            identifier: '',
            value: ''
        }
   
    ],
    platformName: '',
    // kms: [
    //     {
    //         delegateAWSAccountIDs: [
               
    //         ],
    //         awsAccountId: '',
    //         roleName: ''
    //     }
    // ],
     delegateAWSAccountIDs: [
               
            ],
     awsAccountId: '',
    roleName: '',
    tfProviders: [
        {
            alias: '',
            region: '',
            arn: '',
            externalId: ''
        }
        // {
        //     alias: 'environment',
        //     region: 'ap-southeast-1',
        //     arn: 'arn:aws:iam::095033845377:role/AC2-IRS-DO-GLD-BL-095033845377-STG',
        //     externalId: 'GitlabRunner-426956141294-095033845377'
        // },
        // {
        //     alias: 'security',
        //     region: 'ap-southeast-1',
        //     arn: 'arn:aws:iam::085094121557:role/AC2-IRS-DO-GLD-SECURITY-STG',
        //     externalId: 'atlas'
        // },
        // {
        //     alias: 'route53',
        //     region: 'ap-southeast-1',
        //     arn: 'arn:aws:iam::095033845377:role/AC2-IRS-DO-GLD-BL-095033845377-STG',
        //     externalId: 'GitlabRunner-426956141294-095033845377'
        // }
    ],
    vpcIdentifier: ' ',
    vpcValue: ' ',
    dnsZoneApex: ' ',
    commit:'',
    securityAliases: [
        // {
        //     key: 'app',
        //     value: '172.32.0.0/16',
        //     existing: false
        // },
        // {
        //     key: 'bastion',
        //     value: '172.32.0.0/24',
        //     existing: false
        // }
    ],
    tags: [
        // {
        //  key: 'Portfolio',
        //  value: 'mickey',
        //  existing: false
        // },
        // {
        //  key: 'Application',
        //  value: 'mouse',
        //  existing: false
        // }
    ]
 
}
 
const ApprovalFromStore = create<ApprovalFormState & ApprovalFormActions>(
    (set, _get) => ({
        isFormValid: false,
        formValues: _initialFormValues,
        actions: {
            clearFormValues: () => set(() => ({ formValues: _initialFormValues })),
 
            setFormValidity: (isValid) => set(() => ({ isFormValid: isValid })),
 
            setFormValues: (fieldName, value) =>
                set((state) =>
                    produce(state, (draft) => {
                        draft.formValues[fieldName] = value
                    })
                )
        }
    })
)
 
export const useApprovalFormStore = () => ApprovalFromStore((state) => state)
 
if (process.env.NODE_ENV !== 'production') {
    mountStoreDevtool('ApprovalFormStore', ApprovalFromStore)
}