import PLATFORM_LIST from './platform'

const API_URL = process.env.REACT_APP_API_URL
const LOCAL_API_URL = 'http://127.0.0.1:8000'

const CONSTANTS = {
	COMPASS_BASEURL:'https://compass.globe.com.ph/latest',

	ACCESS_MANAGEMENT_API_URL: API_URL + '/userAccess',
	PROJECTS_API_URL: API_URL + '/projects',
	ACCOUNTS_API_URL: API_URL + '/accounts',
	TAGS_API_URL: API_URL + '/tags',
	PLATFORMS_API_URL: API_URL + '/platforms',
	APPLICATIONS_API_URL: API_URL + '/applications',
	GCPPROJECTS_API_URL: API_URL + '/gcpProjects',
	RHOCPTENANTS_API_URL: API_URL + '/rhocpTenants',

	PROJECT_APPROVER: ['projects:*:approve', 'projects:*:read'],
	PROJECT_READER: ['projects:*:read'],
	PROJECT_REQUESTOR: ['projects:*:create', 'projects:*:read'],

	PLATFORM_APPROVER: ['platforms:*:approve', 'platforms:*:read'],
	PLATFORM_READER: ['platforms:*:read'],
	PLATFORM_REQUESTOR: ['platforms:*:create', 'platforms:*:read'],

	APPLICATION_APPROVER: ['applications:*:approve', 'applications:*:read'],
	APPLICATION_READER: ['applications:*:read'],
	APPLICATION_REQUESTOR: ['applications:*:create', 'applications:*:read'],

	ACCOUNT_APPROVER: ['accounts:*:approve', 'accounts:*:read'],
	ACCOUNT_READER: ['accounts:*:read'],
	ACCOUNT_REQUESTOR: ['accounts:*:create', 'accounts:*:read'],

	GCPPROJECT_APPROVER: ['gcpProjects:*:approve', 'gcpProjects:*:read'],
	GCPPROJECT_READER: ['gcpProjects:*:read'],
	GCPPROJECT_REQUESTOR: ['gcpProjects:*:create', 'gcpProjects:*:read'],

	RHOCPTENANT_APPROVER: ['rhocpTenants:*:approve', 'rhocpTenants:*:read'],
	RHOCPTENANT_READER: ['rhocpTenants:*:read'],
	RHOCPTENANT_REQUESTOR: ['rhocpTenants:*:create', 'rhocpTenants:*:read'],

	ACCESS_MANAGER: [
		'useraccess:*:update',
		'useraccess:*:read',
		'useraccess:*:create'
	],
	COST_TYPE_LIST: [
		{ label: 'CapEx', value: 'capex' },
		{ label: 'OpEx', value: 'opex' }
	],

	PLATFORM_REPO_LIST: [
		{ label: 'Mono Repo', value: 'monorepo' },
		{ label: 'Multi Repo', value: 'multirepo' }
	],

	DATA_CLASSIFICATION_LIST: [
		{ label: 'CONFIDENTIAL-PERSONAL', value: 'CONFIDENTIAL-PERSONAL' },
		{ label: 'CONFIDENTIAL-PROPRIETARY', value: 'CONFIDENTIAL-PROPRIETARY' },
		{ label: 'PRIVATE', value: 'PRIVATE' },
		{ label: 'PUBLIC', value: 'PUBLIC' }
	],
	APPROVER_TEAMS: [
		'PlatformOwner',
		'FinanceTeam',
		'CloudEconomicsTeam'
	],
	PLATFORM_LIST: PLATFORM_LIST
}

export default CONSTANTS
