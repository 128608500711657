import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { ApplicationApprovalRequest } from '../../types/applications'
import api from '../../api'

const useAcceptApplication = (applicationID: string) => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (applicationApproval: ApplicationApprovalRequest) => {
			return api.applications.acceptApplication(applicationApproval)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['applications'] })
			queryClient.invalidateQueries({
				queryKey: ['applications', applicationID]
			})
		}
	})
}

export default useAcceptApplication
