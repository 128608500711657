import { FC } from 'react'
import { Control } from 'react-hook-form'
import { FormField, ColumnLayout } from '@cloudscape-design/components'
import FormInput from '../Input'
import toCamelCase from '../../../helper/toCamelCase'

interface FormRequiredTagsProps {
	label: string
	name: string
	control: Control
	tagKeys: Array<string>
	description?: string
	setFormValues: (name: string, value: any) => void
	rules?: {
		required: string
		pattern?: {
			value: RegExp
			message: string
		}
		maxLength?: {
			value: number
			message: string
		}
	}
}

const FormRequiredTags: FC<FormRequiredTagsProps> = ({
	label,
	name,
	control,
	description,
	tagKeys,
	setFormValues,
	rules
}) => {
	return (
		<FormField label={label} description={description}>
			<ColumnLayout columns={3} variant="default">
				{tagKeys.map((tagKey, i) => (
					<FormInput
						name={toCamelCase(tagKey)}
						label={tagKey}
						placeholder={`Enter ${tagKey}`}
						control={control}
						rules={rules}
						setFormValues={setFormValues}
						key={i}
					/>
				))}
			</ColumnLayout>
		</FormField>
	)
}

export default FormRequiredTags
