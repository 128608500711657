const updateOptionsWithAuthorizationHeader = (options: RequestInit) => {
	const oktaTokenStorage = localStorage.getItem('okta-token-storage')
	const idToken =
		oktaTokenStorage && JSON.parse(oktaTokenStorage).idToken.idToken

	if (idToken) {
		options.headers = {
			...options.headers,
			Authorization: `Bearer ${idToken}`
		}
	}
	return options
}

const fetchWithAuthorizationHeader = async (
	url: string,
	options: RequestInit = {}
): Promise<Response> => {
	return fetch(url, updateOptionsWithAuthorizationHeader(options))
}

export default fetchWithAuthorizationHeader
