import { useQuery } from '@tanstack/react-query'

import type { Application } from '../../types/applications'
import api from '../../api'

const useApplications = () => {
	return useQuery({
		queryKey: ['applications'],
		queryFn: () => api.applications.getApplications(),
		select(data) {
			return data.body as Array<Application>
		}
	})
}

export default useApplications
