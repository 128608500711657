import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
import type { gcpProjectRequest } from '../../types/gcpProject'

const { GCPPROJECTS_API_URL } = CONSTANT

const createNewgcpProject = async (newgcpProject: gcpProjectRequest): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(`${GCPPROJECTS_API_URL}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			action: 'CREATE'
		},
		body: JSON.stringify(newgcpProject)
	})

	return response.json()
}

export default createNewgcpProject
