import React, { FC, useEffect, useState } from 'react'
import { Control, useForm } from 'react-hook-form'
import { useOktaAuth } from '@okta/okta-react'
import { useParams } from 'react-router-dom'
import {
    Button,
    Container,
    Form,
    SpaceBetween,
    FormField,
    Input,
    Checkbox,
    TokenGroup
} from '@cloudscape-design/components'
import FormSelect from '../../ControlledFormInputs/Select'
import FormInput from '../../ControlledFormInputs/Input'
import FormTagEditor from '../../ControlledFormInputs/TagEditor'
import FormRequiredTags from '../../ControlledFormInputs/RequiredTags'
import FormDynamicFields from '../../ControlledFormInputs/DynamicFields'

import useAcceptProject from '../../../hooks/mutate/useAcceptProject'
import useSingleProject from '../../../hooks/query/useSingleProject'
import useSingleAccount from '../../../hooks/query/useSingleAccount'
import { useApprovalFormStore } from '../../../stores/projects/approvalFormStore'
import type { ProjectApprovalRequest } from '../../../types/projects'

interface FormTokenGroupProps {
    label: string
    description?: string
    onDismiss: (itemIndex: number) => void
}

interface ApprovalFormProps {
    thisProjectID: string
    shouldShowApprovalForm: (shouldShow: boolean) => void
}

function toKebabCase(str: string): string {
    return str.toLowerCase().replace(/\s+/g, '-')
}

const TFProvider: FC<{ alias: string; control: Control; setFormValues: any }> = ({
    alias,
    control,
    setFormValues
}) => (
    <>
        <FormSelect
            label="Alias"
            name={`alias_${alias}`}
            control={control}
            options={[
                { label: 'environment', value: 'environment' },
                { label: 'route53', value: 'route53' },
                { label: 'dr', value: 'dr' },
                { label: 'security', value: 'security' },
                { label: 'security_dr', value: 'security_dr' },
                { label: 'workload', value: 'workload' }
            ]}
            placeholder="Select an option"
            setFormValues={setFormValues}
        />
        <FormInput
            label="Region"
            name={`region_${alias}`}
            control={control}
            setFormValues={setFormValues}
        />
        <FormInput
            label="ARN"
            name={`arn_${alias}`}
            control={control}
            setFormValues={setFormValues}
        />
        <FormInput
            label="ExternalID"
            name={`externalID_${alias}`}
            control={control}
            setFormValues={setFormValues}
        />
    </>
)

const ApprovalForm: FC<ApprovalFormProps> = ({
    thisProjectID,
    shouldShowApprovalForm
}) => {
    const { authState } = useOktaAuth()
    const thisUserEmail = authState?.idToken?.claims.email as string
    const { id } = useParams() as { id: string }
    const { data: thisProject } = useSingleProject(id, true)

    const {
        formValues,
        actions: { setFormValues, clearFormValues }
    } = useApprovalFormStore()
    const { data: thisAccount } = useSingleAccount(
        thisProject?.details?.distributionList,
        true
    )

    const {
        control,
        trigger,
        setValue,
        formState: { isValid }
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: formValues
    })

    const { mutate: acceptProject, isLoading } = useAcceptProject(thisProjectID)

	useEffect(() => {
        const platformSubgroupName = thisAccount?.approval?.platformSubgroupName || '';
        const kebabCaseName = toKebabCase(platformSubgroupName);

        setValue('platformName', kebabCaseName);
		setFormValues('platformName', kebabCaseName);
    }, [setValue, setFormValues, thisAccount?.approval?.platformSubgroupName]);

	useEffect(() => {
		const selectedValue = formValues.awsAccountId?.value;
	
		const roleName = selectedValue === '085094121557' 
			? 'arn:aws:iam::085094121557:role/AC2-IRS-DO-GLD-SECURITY-STG' 
			: selectedValue === '713010294205' 
			? 'arn:aws:iam::713010294205:role/AC2-IRS-DO-GLD-SECURITY-PRD' 
			: '';
	
		setValue('roleName', roleName);
		setFormValues('roleName', roleName);
	}, [setValue, setFormValues, formValues, formValues.awsAccountId?.value])

	useEffect(() => {
        const selectedValue = formValues.awsAccountId?.value
        if (selectedValue) {
            setTokenItems((prevItems) => [
                ...prevItems,
                { label: selectedValue, dismissLabel: `Remove item ${selectedValue}` }
            ])
        }
    }, [formValues.awsAccountId?.value])

    const [providerCount, setProviderCount] = useState(1);

    const addProvider = () => {
		setProviderCount(providerCount + 1);
	
		const newProviderIndex = providerCount + 1;
		setValue(`alias_provider${newProviderIndex}`, null);
		setValue(`region_provider${newProviderIndex}`, null);
		setValue(`arn_provider${newProviderIndex}`, null);
		setValue(`externalID_provider${newProviderIndex}`, null);
	}

    useEffect(() => {
        const selectedValue = formValues[`alias_provider${providerCount}`]?.value;
        const awsAccountID = thisProject?.details?.awsAccountID
		const KMSawsAccountID = formValues.awsAccountId?.value || ''
		let value
		if (thisProject?.details?.environment?.value === 'pd') {
			value = 'PRD';
		} else {
			value = 'STG';
		}
        
        const regions = [
            { alias: 'environment', region: 'ap-southeast-1', arn: `arn:aws:iam::${awsAccountID}:role/AC2-IRS-DO-GLI-BL-${awsAccountID}-${value}`, externalID: `GitlabRunner-426956141294-${awsAccountID}` },
            { alias: 'route53', region: 'ap-southeast-1', arn: `arn:aws:iam::${awsAccountID}:role/AC2-IRS-DO-GLI-BL-${awsAccountID}-${value}`, externalID: `GitlabRunner-426956141294-${awsAccountID}` },
            { alias: 'dr', region: 'ap-southeast-2', arn: `arn:aws:iam::${awsAccountID}:role/AC2-IRS-DO-GLI-BL-${awsAccountID}-${value}`, externalID: `GitlabRunner-426956141294-${awsAccountID}` },
            { alias: 'security', region: 'ap-southeast-1', arn: `arn:aws:iam::${KMSawsAccountID}:role/AC2-IRS-DO-GLD-SECURITY-${value}`, externalID: 'atlas' },
            { alias: 'security_dr', region: 'ap-southeast-2', arn: `arn:aws:iam::${KMSawsAccountID}:role/AC2-IRS-DO-GLD-SECURITY-${value}`, externalID: 'atlas' },
            { alias: 'workload', region: 'ap-southeast-1', arn: 'arn:aws:secretsmanager:ap-southeast-1:713010294205:secret:/atlas/gitlab/projects/cluster_manifests_workload_token', externalID: '' }
        ];
    
        const selectedRegion = regions.find(region => region.alias === selectedValue);
        if (selectedRegion) {
            setValue(`region_provider${providerCount}`, selectedRegion.region);
            setValue(`arn_provider${providerCount}`, selectedRegion.arn);
            setValue(`externalID_provider${providerCount}`, selectedRegion.externalID);
            setFormValues(`region_provider${providerCount}`, selectedRegion.region);
            setFormValues(`arn_provider${providerCount}`, selectedRegion.arn);
            setFormValues(`externalID_provider${providerCount}`, selectedRegion.externalID);
        }
    }, [setValue, setFormValues, formValues, providerCount])

    const _handleApprove = () => {
		trigger()
	
		if (!isValid) return
	
		const transformedProviders = Array.from({ length: providerCount }, (_, index) => {
			const alias = formValues[`alias_provider${index + 1}`]?.value || '';
			const region = formValues[`region_provider${index + 1}`] || '';
			const arn = formValues[`arn_provider${index + 1}`] || '';
			const externalId = formValues[`externalID_provider${index + 1}`] || '';
	
			return { alias, region, arn, externalId }
		});
	
		const approvalRequest: ProjectApprovalRequest = {
			action: 'APPROVE',
			projectID: thisProjectID,
			approverID: thisUserEmail,
			details: {
				...formValues,
				tfProviders: transformedProviders,
				commit: formValues.commit
			}
		}
	
		acceptProject(approvalRequest, {
			onSuccess: () => {
				shouldShowApprovalForm(false)
				clearFormValues()
			}
		})
	}
	
    const [text, setText] = useState('')
    const [tokenItems, setTokenItems] = useState<
        Array<{ label: string; dismissLabel: string }>
    >([])
 
    useEffect(() => {
        setFormValues(
            'delegateAWSAccountIDs',
            tokenItems.map((item) => item.label)
        )
    }, [tokenItems, setFormValues])

    return (
        <Form
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={() => shouldShowApprovalForm(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        loading={isLoading}
                        onClick={_handleApprove}
                    >
                        Approve
                    </Button>
                </SpaceBetween>
            }
        >
            <Container>
                <SpaceBetween direction="vertical" size="l">
					<FormRequiredTags
						name="vpcAliases"
						label="VPC Aliases:"
						control={control}
						tagKeys={['VPC Identifier', 'VPC Value']}
						setFormValues={setFormValues}
					/>

					<FormInput
						name="dnsZoneApex"
						label="DNS Zone Apex"
						control={control}
						setFormValues={setFormValues}
						rules={{ required: 'Required' }}
					/>
					<FormInput
						name="platformName"
						label="Platform Name"
						control={control}
						setFormValues={setFormValues}
						rules={{
							required: 'Required',
							pattern: {
								value: /^[a-z0-9-]+$/,
								message:
									'Only small alphabetics, numerics, and hyphens are allowed with no spaces.'
							}
						}}
					/>
					<p style={{ fontWeight: 'bold', fontSize: '1.1em', marginBottom: '-10px'  }}>KMS:</p>
					<FormSelect
                        label="Aws Account Id"
                        name="awsAccountId"
                        control={control}
                        options={[
                            { label: '085094121557', value: '085094121557' },
                            { label: '713010294205', value: '713010294205' }
                        ]}
                        placeholder="Select an option"
                        setFormValues={setFormValues}
                    />
                    <FormInput
                        label="Role Name"
                        name="roleName"
                        control={control}
                        setFormValues={setFormValues}
                    />
                <div>
					<FormField
						label="Delegate AWSAccount IDs"
						description=""
					>
						<Input
							value={text}
							onChange={({ detail }) => setText(detail.value)}
							onKeyDown={({ detail }) => {
								if (detail.key !== 'Enter') return
								if (text.trim() === '') return
								setTokenItems((prevItems) => [
									...prevItems,
									{
										label: text.trim(),
										dismissLabel: `Remove item ${text.trim()}`
									}
								])
								setText('')
							}}
							placeholder="Type here and press Enter"
						/>
						{tokenItems.length > 0 && (
							<TokenGroup
								items={tokenItems}
								onDismiss={({ detail: { itemIndex } }) => {
									setTokenItems((prevItems) => [
										...prevItems.slice(0, itemIndex),
										...prevItems.slice(itemIndex + 1)
									])
								}}
							/>
						)}
					</FormField>
				</div>

					<FormDynamicFields
						name="subnetAliases"
						label="Subnet Aliases:"
						matcher={/^subnetAliases\.(\d+)\.(.+)$/}
						inputComponents={[
							<FormInput
								label="Subnet Alias"
								name="alias"
								control={control}
								setFormValues={() => null}
							/>,
							<FormInput
								label="Count"
								name="count"
								control={control}
								setFormValues={() => null}
							/>,
							<FormInput
								label="Identifier"
								name="identifier"
								control={control}
								setFormValues={() => null}
							/>,
							<FormInput
								label="Value"
								name="value"
								control={control}
								setFormValues={() => null}
							/>
						]}
						setFormValues={setFormValues}
					/>

                    <p style={{ fontWeight: 'bold', fontSize: '1.1em', marginBottom: '-10px' }}>TFProviders:</p>
                    {[...Array(providerCount)].map((_, index) => (
                        <TFProvider key={index} alias={`provider${index + 1}`} control={control} setFormValues={setFormValues} />
                    ))}
					<Button onClick={addProvider}>Add TFProvider</Button>
					<FormTagEditor
						label="Security Aliases"
						name="securityAliases"
						control={control}
						setFormValues={setFormValues}
						tags={[]}
					/>

					<FormTagEditor
						label="Tags"
						name="tags"
						control={control}
						setFormValues={setFormValues}
						tags={[]}
					/>

                    <FormField label="Disable Commit">
                        <Checkbox
                            name="commit"
                            checked={formValues.commit || false}
                            onChange={({ detail }) => {
                                setFormValues('commit', detail.checked)
                            }}
                        >
                        </Checkbox>
                    </FormField>
                </SpaceBetween>
            </Container>
        </Form>
    )
}

export default ApprovalForm