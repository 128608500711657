import { useQuery } from '@tanstack/react-query'

import type { gcpProject } from '../../types/gcpProject'
import api from '../../api'

const useSingleGcpProject = (gcpProjectRequestID: string, getTags: boolean = false) => {
	return useQuery({
		queryKey: ['gcpProjects', gcpProjectRequestID],
		queryFn: () => api.gcpProjects.getSinglegcpProject(gcpProjectRequestID, getTags),
		select(data) {
			if(data.body !== undefined) {
				const response = data.body[0]
				return response as gcpProject
			} else {
				let response = {} as gcpProject
				response["status"] = "ERROR"
				response["details"] = data
				return response
			}
		}
	})
}

export default useSingleGcpProject