import { useQuery } from '@tanstack/react-query'

import type { Account } from '../../types/account'
import api from '../../api'

const useSingleAccount = (accountRequestID: string, getTags: boolean = false) => {
	return useQuery({
		queryKey: ['accounts', accountRequestID],
		queryFn: () => api.accounts.getSingleAccount(accountRequestID, getTags),
		select(data) {
			if(data.body !== undefined) {
				const response = data.body[0]
				return response as Account
			} else {
				let response = {} as Account
				response["status"] = "ERROR"
				response["details"] = data
				return response
			}
		}
	})
}

export default useSingleAccount
