import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Table } from '@aws-northstar/ui'
import { Badge, Button, SpaceBetween } from '@cloudscape-design/components'

import LoadingSpinner from '../../components/LoadingSpinner'

import useAllUserPermissions from '../../hooks/query/useAllUserPermissions'
import type { Access } from '../../types/access'

const AccessManagement: FC = () => {
	const navigate = useNavigate()

	const { data: allUserPermissions, isLoading } = useAllUserPermissions()

	if (!allUserPermissions || isLoading) return <LoadingSpinner />

	const tableColumnDefinitions = [
		{
			id: 'email',
			width: 130,
			header: 'User Email',
			cell: (data: Access) => (
				<Button
					variant="link"
					onClick={() => navigate(`/access-management/${data.userEmail}`)}
				>
					{data.userEmail}
				</Button>
			)
		},
		{
			id: 'permissions',
			width: 200,
			header: 'Permissions',
			cell: (data: Access) => (
				<SpaceBetween direction="vertical" size="xxxs">
					{data.permissions.map((permission: string, i: number) => {
						return (
							<Badge color="blue" key={i}>
								<code>{permission}</code>
							</Badge>
						)
					})}
				</SpaceBetween>
			)
		}
	]

	const tableActions = (
		<Button
			variant="primary"
			onClick={() => navigate('/access-management/new')}
		>
			New Access
		</Button>
	)

	return (
		<Table
			header="Access Management"
			columnDefinitions={tableColumnDefinitions}
			items={allUserPermissions || []}
			actions={tableActions}
			disableRowSelect
			sortingDisabled
			loading={isLoading}
		/>
	)
}

export default AccessManagement
