import { useOktaAuth } from '@okta/okta-react'
import { FC, ReactNode } from 'react'

import useUserPermissions from '../../hooks/query/useUserPermissions'
import hasRequiredPermissions from '../hasRequiredPermissions'

interface RequiredPermissionsProps {
	children?: ReactNode
	requiredPermissions: Array<string>
}

const RequiredPermissions: FC<RequiredPermissionsProps> = ({
	children,
	requiredPermissions
}) => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const { data: access, isError } = useUserPermissions(thisUserEmail)

	if (!access || isError) {
		return <div>Something went wrong...</div>
	}

	if (hasRequiredPermissions(requiredPermissions, access.permissions)) {
		return <>{children}</>
	}

	return null
}

export default RequiredPermissions
