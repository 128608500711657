import { produce } from 'immer'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'

type EditProjectFormState = {
	isFormValid: boolean
	formValues: Record<string, any>
}

type EditProjectFormActions = {
	actions: {
		clearFormValues: () => void
		setFormValues: (fieldName: string, value: any) => void
		setFormValidity: (isValid: boolean) => void
		setOriginalRequestFormValues: (
			originalProjectRequestValues: EditProjectFormState
		) => void
	}
}

const initialFormValues = {
	projectID: '0fab9663-cb4d-408a-8060-b1c323ee3518',
	creationTime: '2023-06-28T09:37:54.867631',
	requestorID: 'awstribe@gmail.com',
	status: 'REJECTED',
	projectName: 'quick',
	comments: [
		{
			commenterID: 'awstribe@gmail.com',
			comment: 'test',
			timestamp: '2023-06-28T09:38:35.944395'
		}
	],
	commentText: '',
	lastUpdatedTime: '2023-06-28T09:38:35.944406',
	details: {
		awsAccountID: '123456789012',
		prnWhitelist: [],
		maintenanceWindowDay: {
			value: 'Friday',
			label: 'Friday'
		},
		dataClassification: 'over',
		maintenanceWindowTime: '01:00',
		region: [
			{
				value: 'singapore',
				description: 'ap-southeast-1',
				label: 'Singapore'
			}
		],
		platformName: 'the',
		projectName: 'quick',
		vpcAlias: 'the',
		subnetAlias: 'lazy',
		deployment: 'jumped',
		tags: [],
		workloadAMINames: [],
		projectUsers: [],
		securityAliasesWhitelist: [],
		budget: '',
		crfsDate: '',
		customProjectID: ''
	},
	version: [
		{
			versionId: 1,
			awsAccountID: '123456789012',
			delegateAWSAccountID: '098765432122',
			maintenanceWindowDay: {
				value: 'tuesday',
				label: 'Tuesday'
			},
			region: [
				{
					value: 'sydney',
					description: 'ap-southeast-2',
					label: 'Sydney'
				}
			],
			lastUpdatedTime: '2023-06-28T09:38:35.944406'
		},
		{
			versionId: 2,
			comments: [
				{
					commenterID: 'awstribe@gmail.com',
					comment: 'test',
					timestamp: '2023-06-28T09:38:35.944395'
				}
			],
			awsAccountID: '123456789012',
			delegateAWSAccountID: '098765432122',
			lastUpdatedTime: '2023-06-28T11:38:35.944406'
		}
	]
}

const editProjectFromStore = create<
	EditProjectFormState & EditProjectFormActions
>((set, _get) => ({
	isFormValid: false,
	formValues: initialFormValues,
	actions: {
		clearFormValues: () => set(() => ({ formValues: initialFormValues })),

		setOriginalRequestFormValues: (originalProjectRequestValues) =>
			set(() => ({
				formValues: originalProjectRequestValues.formValues
			})),

		setFormValidity: (isValid) => set(() => ({ isFormValid: isValid })),

		setFormValues: (fieldName, value) =>
			set((state) =>
				produce(state, (draft) => {
					draft.formValues[fieldName] = value
				})
			)
	}
}))

export const useEditProjectFormStore = () =>
	editProjectFromStore((state) => state)

if (process.env.NODE_ENV !== 'production') {
	mountStoreDevtool('EditProjectFormStore', editProjectFromStore)
}
