import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
import type { PlatformRequest } from '../../types/platforms'

const { PLATFORMS_API_URL } = CONSTANT

const createNewPlatform = async (
	newPlatform: PlatformRequest
): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(`${PLATFORMS_API_URL}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			action: 'CREATE'
		},
		body: JSON.stringify(newPlatform)
	})

	return response.json()
}

export default createNewPlatform
