import { FC } from 'react'
import { KeyValuePairs } from '@aws-northstar/ui'
import {
    Box,
    Container,
    Header,
    SpaceBetween
} from '@cloudscape-design/components'
 
import type { ProjectApproval } from '../../../types/projects'
 
interface ApprovalDetailsProps {
    approvalDetails: ProjectApproval
}
 
const EphemeralApprovalDetails: FC<ApprovalDetailsProps> = ({ approvalDetails }) => {
    const approvalDetailsKeyValuePairsItems = [
        [{ label: 'Approver', value: approvalDetails?.approverID }],
        [{ label: 'Approved Date', value: approvalDetails?.timestamp }]
    ]
 
    const platformNametags = [
        [{ label: 'Platform Name', value: approvalDetails?.details?.platformName }]
    ]
 
    const generalDetailsKeyValuePairsItems = [
        [{ label: 'DNS Zone Apex', value: approvalDetails?.details?.dnsZoneApex }],
        [{ label: 'VPC Value', value: approvalDetails?.details?.vpcValue }],
        [
            {
                label: 'VPC Identifier',
                value: approvalDetails?.details?.vpcIdentifier
            }
        ]
    ]
 
    return (
        <Container header={<Header variant="h3">Approval Details</Header>}>
            <SpaceBetween direction="vertical" size="l">
                <KeyValuePairs
                    key={'approvalDetails'}
                    items={approvalDetailsKeyValuePairsItems}
                />
                {/* <Box variant="h5">
                    <KeyValuePairs key={'Platform Name'} items={platformNametags} />
                </Box> */}

            </SpaceBetween>
        </Container>
    )
}
 
export default EphemeralApprovalDetails