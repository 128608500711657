import { FC, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { toRelativeUrl } from '@okta/okta-auth-js'
import { useOktaAuth } from '@okta/okta-react'

import useUserPermissions from '../../hooks/query/useUserPermissions'
import LoadingSpinner from '../../components/LoadingSpinner'
import hasRequiredPermissions from '../hasRequiredPermissions'

interface RequiredAuthProps {
	permissionsRequired: Array<string>
}

const RequiredAuth: FC<RequiredAuthProps> = ({ permissionsRequired }) => {
	const { oktaAuth, authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const { data: access, isLoading, isError } = useUserPermissions(thisUserEmail)

	useEffect(() => {
		if (!authState) {
			return
		}

		// Redirect to the sign-in page if the user is not authenticated
		if (!authState?.isAuthenticated) {
			const originalUri = toRelativeUrl(
				window.location.href,
				window.location.origin
			)
			oktaAuth.setOriginalUri(originalUri)
			oktaAuth.signInWithRedirect()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [oktaAuth, !!authState, authState?.isAuthenticated])

	// If there's a delay in communication with Okta's auth server or
	// if the user's authentication state hasn't been determined yet,
	// returns Loading...
	if (!authState || !authState?.isAuthenticated) {
		return <h2>Loading...</h2>
	}

	if (isLoading) return <LoadingSpinner />

	if (!access || isError) {
		return <h2>Unauthorized</h2>
	}

	if (hasRequiredPermissions(permissionsRequired, access.permissions)) {
		// If the user is authenticated and has necessary permissions,
		// render the component
		return <Outlet />
	}

	// If the user is authenticated, but doesn't have the required role,
	// returns Unauthorized
	return <h2>Unauthorized</h2>
}

export default RequiredAuth
