import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'

const { PROJECTS_API_URL } = CONSTANT

const getSingleProject = async (
	projectID: string,
	getTags: boolean = false,
): Promise<{
	status: number
	body: Array<object>
}> => {
	const response = await fetchWithAuthorizationHeader(
		`${PROJECTS_API_URL}?projectID=${projectID}&getTags=${getTags.toString()}`
	)
	return response.json()
}

export default getSingleProject
