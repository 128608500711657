import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'

import type { DeleteAccessRequest } from '../../types/access'
const { ACCESS_MANAGEMENT_API_URL } = CONSTANT

const deletePermissionsForUser = async (
	access: DeleteAccessRequest
): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(
		`${ACCESS_MANAGEMENT_API_URL}`,
		{
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				action: 'DELETE'
			},
			body: JSON.stringify(access)
		}
	)

	return response.json()
}

export default deletePermissionsForUser
