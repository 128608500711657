import { produce } from 'immer'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'

type ApprovalFormState = {
	isFormValid: boolean
	formValues: Record<string, any>
}

type ApprovalFormActions = {
	actions: {
		clearFormValues: () => void
		setFormValues: (fieldName: string, value: any) => void
		setFormValidity: (isValid: boolean) => void
	}
}

const _initialFormValues = {
	approverID: '',
	projectId: '',
	wkldClusterName: '',
	region: 'asia-southeast1',
	hostname: '',
	externalIP: '',
	action: 'APPROVE'
}

const ApprovalFromStore = create<ApprovalFormState & ApprovalFormActions>(
	(set, _get) => ({
		isFormValid: false,
		formValues: _initialFormValues,
		actions: {
			clearFormValues: () => set(() => ({ formValues: _initialFormValues })),

			setFormValidity: (isValid) => set(() => ({ isFormValid: isValid })),

			setFormValues: (fieldName, value) =>
				set((state) =>
					produce(state, (draft) => {
						draft.formValues[fieldName] = value
					})
				)
		}
	})
)

export const useApprovalFormStore = () => ApprovalFromStore((state) => state)

if (process.env.NODE_ENV !== 'production') {
	mountStoreDevtool('ApprovalFormStore', ApprovalFromStore)
}
