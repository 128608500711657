import CONSTANTS from '../constants'
export type TagsDetails = {
	details: Record<any, any>
	status: 'PENDING_APPROVAL' | 'APPROVED' | 'REJECTED'
}
export type TagsApproval = {
	name: string
	status: number | null
	approverID: string
	lastUpdated: string
}
export type TagsRequest = TagsDetails & {
	action: 'CREATE'
	requestorID: string
	approvalStatus: Array<TagsApproval>
	tagsID: string
}
export type UpdateTags = TagsDetails & {
	tagsID: string
	requestorID: string
	action: 'UPDATE_TAGS'
}

export type Tags = TagsDetails & {
	tagsID: string
	requestorID: string
	creationTime: string
	lastUpdatedTime: string
	accountId?: string
	approvalStatus: Array<TagsApproval>
}
export type ApproveTags = {
	action: 'UPDATE_APPROVE_STATUS'
	status: 'APPROVED'
	tagsID: string
}
export type UpdateApprovalTags = {
	action: 'UPDATE_APPROVER_STATUS'
	tagsID: string
	approvalStatus?: TagsApproval
}

export const getTagsApprovalObject = () => {
	const approvalStatus = CONSTANTS.APPROVER_TEAMS.map((item) => {
		return {
			name: item,
			status: null,
			approverID: '',
			lastUpdated: ''
		}
	})
	return approvalStatus
}
