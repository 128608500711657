import {
	ColumnLayout,
	FormField,
	Select,
	TimeInput
} from '@cloudscape-design/components'
import { FC, ReactNode } from 'react'
import { Control, Controller, UseControllerProps } from 'react-hook-form'

interface FormDayTimeProps {
	label: string
	name: string
	control: Control
	options: Array<{ label: string; value: string; description?: string }>
	placeholder: string
	description?: string
	info?: ReactNode
	rules?: {
		day: UseControllerProps['rules']
		time: UseControllerProps['rules']
	}
	setFormValues: (name: string, value: any) => void
}

const FormDayTime: FC<FormDayTimeProps> = ({
	label,
	name,
	control,
	description,
	info,
	rules,
	options,
	placeholder,
	setFormValues
}) => {
	return (
		<FormField label={label} description={description} info={info}>
			<ColumnLayout columns={2} variant="default">
				<Controller
					name={`${name}Day`}
					control={control}
					rules={rules?.day}
					render={({
						field: { name, onChange, value },
						fieldState: { error }
					}) => {
						return (
							<FormField errorText={error?.message}>
								<Select
									selectedOption={value}
									options={options}
									placeholder={placeholder}
									onChange={({ detail }) => {
										onChange(detail.selectedOption)
										setFormValues(name, detail.selectedOption)
									}}
								/>
							</FormField>
						)
					}}
				/>

				<Controller
					name={`${name}Time`}
					control={control}
					rules={rules?.time}
					render={({
						field: { name, onChange, value },
						fieldState: { error }
					}) => {
						return (
							<FormField errorText={error?.message}>
								<TimeInput
									value={value}
									format="hh:mm"
									placeholder="hh:mm"
									onChange={({ detail }) => {
										onChange(detail.value)
										setFormValues(name, detail.value)
									}}
								/>
							</FormField>
						)
					}}
				/>
			</ColumnLayout>
		</FormField>
	)
}

export default FormDayTime
