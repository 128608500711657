import { produce } from 'immer'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'

type ApprovalFormState = {
	isFormValid: boolean
	formValues: Record<string, any>
}

type ApprovalFormActions = {
	actions: {
		clearFormValues: () => void
		setFormValues: (fieldName: string, value: any) => void
		setFormValidity: (isValid: boolean) => void
	}
}

const initialFormValues = {
	ssoDisplayNames: 'Apple, Banana',
	platformSubgroupName: 'Example Dashboard',
	vpcName: 'isg-bidas-dv-aws-vpc-1',
	vpcCidrBlock: '10.220.130.0/24',
	vpcAvailabilityZones: 'ap-southeast-1a, ap-southeast-1b',
	privateSubnetsCidr:
		'10.220.130.0/27, 10.220.130.32/27, 10.220.130.64/27, 10.220.130.96/27, 10.220.130.160/28, 10.220.130.176/28, 10.220.130.192/28, 10.220.130.208/28, 10.220.130.224/28, 10.220.130.240/28',
	tgwSubnetsCidr: '10.220.130.0/27',
	requestTgwAttachment: 'false',
	isCoreVpc: 'false',
	createPrivateHostedZone: 'true',
	associateDnsResolverRules: 'true',
	useCentralizedVpcInterfaceEndpoints: 'true',
	privateHostedZoneName: 'example-dv.globetel.cloud',
}

const ApprovalFromStore = create<ApprovalFormState & ApprovalFormActions>(
	(set, _get) => ({
		isFormValid: false,
		formValues: initialFormValues,
		actions: {
			clearFormValues: () => set(() => ({ formValues: initialFormValues })),

			setFormValidity: (isValid) => set(() => ({ isFormValid: isValid })),

			setFormValues: (fieldName, value) =>
				set((state) =>
					produce(state, (draft) => {
						draft.formValues[fieldName] = value
					})
				)
		}
	})
)

export const useApprovalFormStore = () => ApprovalFromStore((state) => state)

if (process.env.NODE_ENV !== 'production') {
	mountStoreDevtool('AccountsApprovalFormStore', ApprovalFromStore)
}
