import { FC, ReactNode, KeyboardEvent } from 'react'
import { Control, Controller, UseControllerProps } from 'react-hook-form'
 
import { FormField, Input, InputProps } from '@cloudscape-design/components'
 
interface FormInputProps {
    label: string
    name: string
    control: Control
    placeholder?: string
    description?: string
    info?: ReactNode
    constraintText?: string
    rules?: UseControllerProps['rules']
    type?: InputProps.Type
    disabled?: boolean
    setFormValues: (name: string, value: any) => void
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void // Add onKeyDown prop
}
 
const FormInput: FC<FormInputProps> = ({
    label,
    name,
    control,
    placeholder,
    description,
    info,
    constraintText,
    rules,
    type,
    disabled = false,
    setFormValues,
    onKeyDown // Destructure onKeyDown
}) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { name, onChange, value }, fieldState: { error } }) => {
                return (
                    <FormField
                        label={label}
                        description={description}
                        info={info}
                        constraintText={constraintText}
                        errorText={error?.message}
                    >
                        <Input
                            name={name}
                            value={value}
                            type={type}
                            placeholder={placeholder}
                            onChange={({ detail }) => {
                                onChange(detail.value)
                                setFormValues(name, detail.value)
                            }}
                            disabled={disabled}
                        />
                    </FormField>
                )
            }}
        />
    )
}
 
export default FormInput