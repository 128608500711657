import { create } from 'zustand'
import { z } from 'zod'

const formFields = z.object({
	firstField: z.string(),
	secondField: z.boolean()
})

type FormFieldsType = z.infer<typeof formFields>

interface IDevOkkarFormState {
	formFields: FormFieldsType
}

interface IDevOkkarFormStateActions {
	actions: {
		updateField: (fieldName: string, value: string | boolean) => void
		getField: (fieldName: keyof FormFieldsType) => string | boolean
	}
}

const useDevOkkarFormStore = create<
	IDevOkkarFormState & IDevOkkarFormStateActions
>((set, get) => ({
	formFields: {
		firstField: '',
		secondField: false
	},
	actions: {
		updateField: (fieldName, value) =>
			set((state) => ({
				formFields: {
					...state.formFields,
					[fieldName]: value
				}
			})),
		getField: (fieldName) => {
			const formFields = get().formFields
			return formFields[fieldName] // return the value of the field
		}
	}
}))

const useDevOkkarForm = () => useDevOkkarFormStore((state) => state)

export default useDevOkkarForm
