import { useQuery } from '@tanstack/react-query'

import api from '../../api'
import { Tags } from '../../types/tags'

const useSingleTags = (tagsID: string) => {
	return useQuery({
		queryKey: ['tags', tagsID],
		queryFn: () => api.tags.getTagsById(tagsID),
		select(data) {
			const response = data.body[0]
			return response as Tags
		}
	})
}

export default useSingleTags
