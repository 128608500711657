import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { TagsRequest } from '../../types/tags'
import api from '../../api'

const useCreateNewTags = () => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (newTags: TagsRequest) => {
			return api.tags.createTags(newTags)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['tags'] })
		}
	})
}

export default useCreateNewTags
