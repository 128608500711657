import {
	Box,
	Button,
	ColumnLayout,
	Container,
	ExpandableSection,
	Form,
	FormField,
	Header,
	Input,
	SpaceBetween
} from '@cloudscape-design/components'
import { useOktaAuth } from '@okta/okta-react'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import FormDayTime from '../../../components/ControlledFormInputs/DayTime'
import FormInput from '../../../components/ControlledFormInputs/Input'
import FormMultiselect from '../../../components/ControlledFormInputs/Multiselect'
import FormRequiredTags from '../../../components/ControlledFormInputs/RequiredTags'

import type { ProjectRequest } from '../../../types/projects'
import { useNewProjectFormStore } from '../../../stores/projects/newProjectFormStore'
import useCreateNewProject from '../../../hooks/mutate/useCreateNewProject'
import FormTokenGroup from '../../../components/ControlledFormInputs/InputTokenGroup'
import FormTagEditor from '../../../components/ControlledFormInputs/TagEditor'
import useTagsByAccountId from '../../../hooks/query/useTagsByAccountId'
import ReadGenericTags from '../../../components/ControlledFormInputs/GenericTagsRead'
import GenericTagsNew from '../../../components/ControlledFormInputs/GenericTagsNew'
import { useGenericTagsManagementFormStore } from '../../../stores/tags/genericTagsManagementFormStore'
import { TagsRequest, getTagsApprovalObject } from '../../../types/tags'
import useCreateNewTags from '../../../hooks/mutate/useCreateNewTags'
import { v4 as uuidv4 } from 'uuid'
import FormDatePicker from '../../../components/ControlledFormInputs/FormDatePicker'
import FormSelect from '../../../components/ControlledFormInputs/Select'
import PORTFOLIO_LIST from '../../../portfolio'

const weekDaySelectOptions = [
	{ label: 'Monday', value: 'monday' },
	{ label: 'Tuesday', value: 'tuesday' },
	{ label: 'Wednesday', value: 'wednesday' },
	{ label: 'Thursday', value: 'thursday' },
	{ label: 'Friday', value: 'friday' },
	{ label: 'Saturday', value: 'saturday' },
	{ label: 'Sunday', value: 'sunday' }
]

const projectKeys = [
	//'Application',
	// 'Project Name',
	//'Platform Name',
	// 'Environment',
	'Deployment',
	'Data Classification',
	'Tier Level'
]

const ENVIRONMENT_LIST = [
	{ label: 'dv', value: 'dv' },
	{ label: 'st', value: 'st' },
	{ label: 'pd', value: 'pd' },
]
const NewProjectForm: FC = () => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const navigate = useNavigate()

	const {
		formValues,
		actions: { setFormValues, setFormValidity }
	} = useNewProjectFormStore()

	const { mutate: createNewProject, isLoading } = useCreateNewProject()
	const { mutate: getTagsByAccountId, isLoading: isLoadingTags } =
		useTagsByAccountId()
	const [getTagsId, setTagsId] = useState('')

	const {
		control,
		setValue,
		trigger,
		formState: { isValid }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: formValues
	})
	const tagCreationFormStore = useGenericTagsManagementFormStore()
	const { mutate: createTags } = useCreateNewTags()

   
   useEffect(() => {
	const portfolioValue = formValues.portfolio?.value ?? 'demo';
    const projectNameValue = portfolioValue + "-" + formValues.application;
    setValue('projectName', projectNameValue);
	setFormValues('projectName', projectNameValue)
}, [formValues.portfolio?.value, formValues.application, setValue]);



	useEffect(() => {
		setFormValidity(isValid)
	}, [isValid, setFormValidity])

	const [inputDLValue, setInputDLValue] = useState('')
	const [isDLValid, setIsDLValid] = useState(false)

	useEffect(() => {
		if (formValues.distributionList) {
			getTagsByDLId()
		}
		// eslint-disable-next-line
	}, [formValues.distributionList])

	// const validateDL = (param: any) => {
	// 	const pattern = /^aws-glo02-[a-zA-Z]+-[a-zA-Z0-9]+-[a-zA-Z]+@globe.com.ph$/
	// 	setInputDLValue(param.detail.value)
	// 	setIsDLValid(pattern.test(param.detail.value))
	// }
	const [tagsViewToggler, setTagsViewToggler] = useState(true)
	const handleSubmit = async () => {
		await trigger()
		if (!isValid) return

		const tagsID = uuidv4()
		if (tagsViewToggler) {
			const project: ProjectRequest = {
				requestorID: thisUserEmail,
				action: 'CREATE',
				details: formValues,
				status: 'PENDING_APPROVAL',
				tagsID: getTagsId
			}
			createNewProject(project, {
				onSuccess: () => navigate('/projects')
			})
		} else {
			const project: ProjectRequest = {
				requestorID: thisUserEmail,
				action: 'CREATE',
				details: formValues,
				status: 'PENDING_APPROVAL',
				tagsID: tagsID
			}
			const tagRequest: TagsRequest = {
				requestorID: thisUserEmail,
				action: 'CREATE',
				status: 'PENDING_APPROVAL',
				details: tagCreationFormStore.formValues,
				tagsID: tagsID,
				approvalStatus: getTagsApprovalObject()
			}
			createTags(tagRequest, {
				onSuccess: () => {
					createNewProject(project, {
						onSuccess: () => navigate('/projects')
					})
				}
			})
		}
	}
	const getTagsByDLId = () => {
		console.log('getTagsByDLId')
		getTagsByAccountId(formValues.distributionList, {
			onSuccess: (data: Record<any, any>) => {
				console.log('Success', data)
				const response = data.body && data.body[0]
				setTagsId(response.tagsID)
			},
			onError: () => {
				console.log('Error Fetching Tags ID')
			}
		})
	}
	return (
		<Form
			actions={
				<SpaceBetween direction="horizontal" size="xs">
					<Box>
						<Button
							variant="primary"
							onClick={handleSubmit}
							loading={isLoading && isLoadingTags}
						>
							Submit
						</Button>
						<Button variant="link" onClick={() => navigate(-1)}>
							Cancel
						</Button>
					</Box>
				</SpaceBetween>
			}
			header={
				<Header variant="h1" description="">
					Request a New Project
				</Header>
			}
		>
			<SpaceBetween direction="vertical" size="s">
				<Container>
					<SpaceBetween direction="vertical" size="l">
						<FormInput
							name="awsAccountID"
							label="AWS Account ID"
							placeholder='Enter AWS Account ID'
							type="number"
							control={control}
							rules={{
								required: 'Please enter your AWS Account ID',
								minLength: {
									value: 12,
									message: 'Minimum length should be 12'
								},
								maxLength: {
									value: 12,
									message: 'Maximum length should be 12'
								}
							}}
							setFormValues={setFormValues}
						/>

						<FormInput
							name="distributionList"
							label="Distribution List"
							type="text"
							control={control}
							rules={{
								required: 'Please enter valid DL',
								pattern: {
									value: /^aws-glo02-[a-zA-Z]+-[a-zA-Z0-9]+-[a-zA-Z]+@globe.com.ph$/g,
									message: 'Not Valid DL'
								},
							}}
							placeholder="aws-glo02-<group_name>-<platform_name>-<environment>@globe.com.ph"
							setFormValues={setFormValues}
						/>

						{/* <FormField
							description=""
							label="Distribution List"
							errorText={
								isDLValid || inputDLValue === '' ? '' : 'Please enter valid DL'
							}
						>
							<Input
								name="distributionList"
								value={inputDLValue}
								onChange={validateDL}
								placeholder="aws-glo02-abc-def-np@globe.com.ph"
							/>
						</FormField> */}


						<FormMultiselect
							name="region"
							label="Region"
							control={control}
							placeholder="Select a region"
							options={[
								{
									label: 'Singapore',
									value: 'singapore',
									description: 'ap-southeast-1'
								}
							]}
							rules={{
								required: 'Please select at least one region',
								validate: (value) =>
									value.length > 0 || 'Select at least one region'
							}}
							setFormValues={setFormValues}
						/>

						<FormRequiredTags
							name="projectDetails"
							label="Project Details"
							control={control}
							tagKeys={projectKeys}
							setFormValues={setFormValues}
						/>
						<FormField label="" description="">
							<ColumnLayout columns={3} variant="default">
								<FormInput
								    name="application"
                                    label="Application"
                                    type="text"
                                    placeholder="Enter Application"
                                    control={control}
                                    rules={{
                                        required: 'Application should be 5 letters',
                                        pattern: {
                                            value: /^[A-Za-z][A-Za-z0-9-]{4,}$/g,
                                            message: 'Application should be 5 letters'
                                        },
                                        minLength: {
                                            value: 5,
                                            message: 'Application should be 5 letters'
                                        },
                                        maxLength: {
                                            value: 5,
                                            message: 'System alias should be 5 letters, Example: namax'
                                        }
                                    }}
                                    setFormValues={setFormValues}
                            />
								<FormSelect
									label="Portfolio"
									name="portfolio"
									control={control}
									rules={{
										required: 'Please Select Option'
									}}
									setFormValues={setFormValues}
									options={PORTFOLIO_LIST}
									placeholder="Select Portfolio"	
								/>
								<FormSelect
									label="Environment"
									name="environment"
									control={control}
									rules={{
										required: 'Please Select Option'
									}}
									setFormValues={setFormValues}
									options={ENVIRONMENT_LIST}
									placeholder="Select Environment"
								/>
								<FormInput
									name="budget"
									label="Project Budget Allocated"
									description="In US Dollar (USD) currency"
									type="text"
									rules={{
										required: 'Please enter Project Budget allocated',
										pattern: {
											value: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
											message:
												'Please enter valid Project Budget allocated'
										},
									}}
									
									control={control}
									setFormValues={setFormValues}
									placeholder="0"
								/>
								<FormDatePicker
									name="crfsDate"
									label="CRFS Date"
									description="Customer Ready for Service Date"
									rules={{
										required: 'Please Enter CRFS Date'

									}}
									control={control}
									setFormValues={setFormValues}
								/>
								<FormInput
									name="customProjectID"
									label="Project ID"
									description="12 alphanumeric characters only"
									type="text"
									rules={{
										required: 'Please enter valid project ID',
										pattern: {
											value: /^[a-zA-Z0-9_]*$/,
											message: 'Please enter valid project ID'
										},
										minLength: {
                                            value: 12,
                                            message: 'ProjectID should be 12 letters'
                                        },
                                        maxLength: {
                                            value: 12,
                                            message: 'ProjectID should be 12 letters'
                                        }
									}}
									control={control}
									setFormValues={setFormValues}
									placeholder=""
								/>
								<FormInput
									name="projectName"
									label="Project Name"
									type="text"
									control={control}
									setFormValues={setFormValues}
									placeholder=""
									disabled={true}
								/>
								
							</ColumnLayout>
						</FormField>

						<FormTagEditor
							label="Additional Tags"
							name="tags"
							control={control}
							setFormValues={setFormValues}
							tags={[]}
						/>

						<ExpandableSection headerText="Optional Input(s)">
							<SpaceBetween direction="vertical" size="l">
								<FormTokenGroup
									label="Workload AMI Names"
									name="workloadAMINames"
									control={control}
									items={[]}
									placeholder="Type WorkloadAMINames and press 'Enter'"
									setFormValues={setFormValues}
								/>

								<FormTokenGroup
									label="Project Users"
									name="projectUsers"
									control={control}
									items={[]}
									placeholder="Type Users and press 'Enter'"
									setFormValues={setFormValues}
								/>

								<FormTokenGroup
									label="SecurityAliases Whitelist"
									name="securityAliasesWhitelist"
									control={control}
									items={[]}
									placeholder="Type SecurityAlias and press 'Enter'"
									setFormValues={setFormValues}
								/>

								<FormTokenGroup
									label="PRN Whitelist"
									name="prnWhitelist"
									control={control}
									items={[]}
									placeholder="Type PRN and press 'Enter'"
									setFormValues={setFormValues}
								/>

								<FormDayTime
									name="maintenanceWindow"
									label="Maintenance Window"
									description="Optional | Defaults to Tuesday 1:00 AM GMT+8"
									control={control}
									options={weekDaySelectOptions}
									placeholder="Select a day and time"
									setFormValues={setFormValues}
								/>
							</SpaceBetween>
						</ExpandableSection>
					</SpaceBetween>
				</Container>
				{getTagsId && (
					<Container
						header={
							<Header
								variant="h2"
								description=""
								actions={
									<SpaceBetween direction="horizontal" size="xs">
										{tagsViewToggler ? (
											<Button
												variant="link"
												onClick={() => setTagsViewToggler(!tagsViewToggler)}
											>
												+ Create New Tags
											</Button>
										) : (
											<Button
												variant="link"
												onClick={() => setTagsViewToggler(!tagsViewToggler)}
											>
												- Switch to Original Tags
											</Button>
										)}
									</SpaceBetween>
								}
							>
								Tags Details
							</Header>
						}
					>
						<FormField label="">
							<ColumnLayout columns={1} variant="default">
								{tagsViewToggler ? (
									<ReadGenericTags tagsId={getTagsId} layout={3} />
								) : (
									<GenericTagsNew />
								)}
							</ColumnLayout>
						</FormField>
					</Container>
				)}
			</SpaceBetween>
		</Form>
	)
}

export default NewProjectForm