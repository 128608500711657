import {
	Alert,
	Box,
	Button,
	ColumnLayout,
	Container,
	ExpandableSection,
	Form,
	FormField,
	Header,
	SpaceBetween
} from '@cloudscape-design/components'
import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useOktaAuth } from '@okta/okta-react'

import FormDayTime from '../../../components/ControlledFormInputs/DayTime'
import FormInput from '../../../components/ControlledFormInputs/Input'
import FormMultiselect from '../../../components/ControlledFormInputs/Multiselect'
import FormRequiredTags from '../../../components/ControlledFormInputs/RequiredTags'
import LoadingSpinner from '../../../components/LoadingSpinner'
import CommentsOverview from '../../../components/Project/CommentsOverview'
import FormInputArea from '../../../components/ControlledFormInputs/TextArea'

import { useEditProjectFormStore } from '../../../stores/projects/editProjectFormStore'
import useSingleProject from '../../../hooks/query/useSingleProject'
import useEditProject from '../../../hooks/mutate/useEditProject'
import { ProjectEditRequest } from '../../../types/projects'
import FormSelect from '../../../components/ControlledFormInputs/Select'
import PORTFOLIO_LIST from '../../../portfolio'
import FormDatePicker from '../../../components/ControlledFormInputs/FormDatePicker'
import FormTokenGroup from '../../../components/ControlledFormInputs/InputTokenGroup'

const weekDaySelectOptions = [
	{ label: 'Monday', value: 'monday' },
	{ label: 'Tuesday', value: 'tuesday' },
	{ label: 'Wednesday', value: 'wednesday' },
	{ label: 'Thursday', value: 'thursday' },
	{ label: 'Friday', value: 'friday' },
	{ label: 'Saturday', value: 'saturday' },
	{ label: 'Sunday', value: 'sunday' }
]

const tagKeys = [
	'Application',
	'Project Name',
	'Platform Name',
	'Environment',
	'Deployment',
	'Data Classification',
	'Tier Level'
]

const EditApplicationForm: FC = () => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const { id } = useParams() as { id: string }

	const { data: originalProjectRequest, isLoading: isLoadingSingleProject } =
		useSingleProject(id)

	const navigate = useNavigate()

	useEffect(() => {
		if (
			typeof originalProjectRequest === 'object' &&
			originalProjectRequest !== null
		) {
			const prefetchedObject = {
				...originalProjectRequest.details,
				commentText: ''
			}
			setOriginalRequestFormValues({
				formValues: prefetchedObject,
				isFormValid: true
			})
			reset(prefetchedObject)
		}
	}, [originalProjectRequest])

	const {
		formValues,
		actions: { setFormValues, setFormValidity, setOriginalRequestFormValues }
	} = useEditProjectFormStore()

	const {
		mutate: editProject,
		isLoading: isLoadingEditProject,
		isError: isErrorInEditReqeust
	} = useEditProject(id)

	const {
		control,
		reset,
		trigger,
		formState: { isValid, dirtyFields }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: { ...formValues.details, commentText: '' }
	})

	useEffect(() => {
		setFormValidity(isValid)
	}, [isValid, setFormValidity])

	const getOriginalObjVersion = () => {
		let deltaObject: any = {}
		const updatedFields: string[] = Object.keys(dirtyFields)
		for (let fieldName of updatedFields) {
			if (fieldName !== 'commentText')
				deltaObject[fieldName] =
					originalProjectRequest?.details[fieldName as keyof object]
		}
		if (Object.keys(deltaObject).length !== 0) {
			deltaObject['lastUpdatedTime'] = originalProjectRequest?.lastUpdatedTime
		}
		return deltaObject
	}

	const handleSubmit = async () => {
		await trigger()
		if (!isValid) return

		let formFieldValues = JSON.parse(JSON.stringify(formValues))
		let projectEditRequest: ProjectEditRequest = {
			details: formFieldValues,
			projectID: originalProjectRequest?.projectID || '',
			requestorID: originalProjectRequest?.requestorID || '',
			creationTime: originalProjectRequest?.creationTime || '',
			action: 'UPDATE',
			version: getOriginalObjVersion(),
			commenterID: thisUserEmail,
			comment: formValues.commentText,
			status: 'PENDING_APPROVAL'
		}
		delete projectEditRequest.details.commentText

		editProject(projectEditRequest, {
			onSuccess: () => navigate('/projects'),
			onError: (e) => {}
		})
	}

	if (!originalProjectRequest || isLoadingSingleProject)
		return <LoadingSpinner />

	return (
		<>
			<Form
				actions={
					<SpaceBetween direction="horizontal" size="l">
						<Button
							variant="primary"
							onClick={handleSubmit}
							loading={isLoadingEditProject}
						>
							Submit
						</Button>
						<Button variant="link" onClick={() => navigate(-1)}>
							Cancel
						</Button>
					</SpaceBetween>
				}
				header={
					<Header variant="h1" description="">
						<SpaceBetween direction="horizontal" size="l">
							<Button
								variant="icon"
								iconName="arrow-left"
								onClick={() => navigate(-1)}
								key="icon"
							/>
							<div key="text">
								{originalProjectRequest?.details.projectName}
							</div>
						</SpaceBetween>
					</Header>
				}
			>
				<SpaceBetween direction="vertical" size="l">
					<Container>
						<SpaceBetween direction="vertical" size="l">
							<FormInput
								name="awsAccountID"
								label="AWS Account ID"
								type="number"
								control={control}
								rules={{
									required: 'Please enter your AWS Account ID',
									minLength: {
										value: 12,
										message: 'Minimum length should be 12'
									},
									maxLength: {
										value: 12,
										message: 'Maximum length should be 12'
									}
								}}
								setFormValues={setFormValues}
							/>

							<FormMultiselect
								name="region"
								label="Region"
								control={control}
								placeholder="Select a region"
								options={[
									{
										label: 'Singapore',
										value: 'singapore',
										description: 'ap-southeast-1'
									}
								]}
								rules={{
									required: 'Please select at least one region',
									validate: (value) =>
										value.length > 0 || 'Select at least one region'
								}}
								setFormValues={setFormValues}
							/>

							<FormRequiredTags
								name="projectDetails"
								label="Project Details"
								control={control}
								tagKeys={tagKeys}
								setFormValues={setFormValues}
							/>
							<FormField label="" description="">
								<ColumnLayout columns={3} variant="default">
									<FormSelect
										label="Portfolio"
										name="portfolio"
										control={control}
										rules={{
											required: 'Please Select Option'
										}}
										setFormValues={setFormValues}
										options={PORTFOLIO_LIST}
										placeholder="Select Portfolio"
									/>
									<FormInput
										name="budget"
										label="Project Budget Allocated"
										description="In US Dollar (USD) currency"
										type="text"
										rules={{
											required: 'Please enter Project Budget allocated',
											pattern: {
												value:
													/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
												message: 'Please enter valid Project Budget allocated'
											}
										}}
										control={control}
										setFormValues={setFormValues}
										placeholder="0"
									/>
									<FormDatePicker
										name="crfsDate"
										label="CRFS Date"
										description="Customer Ready for Service Date"
										rules={{
											required: 'Please Enter CRFS Date'
										}}
										control={control}
										setFormValues={setFormValues}
									/>
									<FormInput
										name="customProjectID"
										label="Project ID"
										description="12 alphanumeric characters only"
										type="text"
										rules={{
											required: 'Please enter valid project ID',
											pattern: {
												value: /^[a-zA-Z0-9_]*$/,
												message: 'Please enter valid project ID'
											}
										}}
										control={control}
										setFormValues={setFormValues}
										placeholder=""
									/>
								</ColumnLayout>
							</FormField>

							<ExpandableSection headerText="Optional Input(s)">
								<SpaceBetween direction="vertical" size="l">
									<FormTokenGroup
										label="Workload AMI Names"
										name="workloadAMINames"
										control={control}
										items={[]}
										placeholder="Type WorkloadAMINames and press 'Enter'"
										setFormValues={setFormValues}
									/>

									<FormTokenGroup
										label="SecurityAliases Whitelist"
										name="securityAliasesWhitelist"
										control={control}
										items={[]}
										placeholder="Type SecurityAlias and press 'Enter'"
										setFormValues={setFormValues}
									/>

									<FormTokenGroup
										label="PRN Whitelist"
										name="prnWhitelist"
										control={control}
										items={[]}
										placeholder="Type PRN and press 'Enter'"
										setFormValues={setFormValues}
									/>

									<FormDayTime
										name="maintenanceWindow"
										label="Maintenance Window"
										description="Optional | Defaults to Tuesday 1:00 AM GMT+8"
										control={control}
										options={weekDaySelectOptions}
										placeholder="Select a day and time"
										setFormValues={setFormValues}
									/>
								</SpaceBetween>
							</ExpandableSection>
						</SpaceBetween>
					</Container>
					<Container
						header={<Header variant="h3">Comment History</Header>}
						footer={
							<SpaceBetween direction="vertical" size="l">
								<FormInputArea
									name="commentText"
									label=""
									description="Add comments here"
									control={control}
									rules={{
										required: 'Please enter your comments here',
										minLength: {
											value: 2,
											message: 'Minimum comment length should be 2'
										},
										maxLength: {
											value: 50,
											message: 'Maximum comment length should be 50'
										}
									}}
									setFormValues={setFormValues}
								/>
							</SpaceBetween>
						}
					>
						<SpaceBetween direction="vertical" size="l">
							<CommentsOverview comments={originalProjectRequest?.comments} />
						</SpaceBetween>
					</Container>
					{isErrorInEditReqeust && (
						<Container>
							<Box variant="p">
								<Alert
									statusIconAriaLabel="Error"
									type="error"
									header="Error Submitting Request, Please try again."
								/>
							</Box>
						</Container>
					)}
				</SpaceBetween>
			</Form>
		</>
	)
}

export default EditApplicationForm
