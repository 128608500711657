import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'

const { TAGS_API_URL } = CONSTANT

const getTagsById = async (
	tagsId: string
): Promise<{
	status: number
	body: Array<object>
}> => {
	const response = await fetchWithAuthorizationHeader(
		`${TAGS_API_URL}?tagsID=${tagsId}`
	)
	return response.json()
}

export default getTagsById
