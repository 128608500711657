import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { ApproveTags } from '../../types/tags'
import api from '../../api'

const useApproveTags = (tagsID: string) => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (updateTags: ApproveTags) => {
			return api.tags.approveTagsById(updateTags)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['tags'] })
			queryClient.invalidateQueries({
				queryKey: ['tags', tagsID]
			})
		},
		onError: (error) => {
			console.log(error)
		}
	})
}

export default useApproveTags
