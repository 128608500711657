import CONSTANT from '../../constants'
import type { AccountEditRequest } from '../../types/account'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
const { ACCOUNTS_API_URL } = CONSTANT

const editAccount = async (editAccount: AccountEditRequest): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(`${ACCOUNTS_API_URL}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			action: 'UPDATE'
		},
		body: JSON.stringify(editAccount)
	})

	return response.json()
}

export default editAccount
