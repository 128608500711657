import CONSTANTS from "../../constants";
import fetchWithAuthorizationHeader from "../../helper/fetchWithAuthorizationHeader";

const { GCPPROJECTS_API_URL, TAGS_API_URL } = CONSTANTS

const getTagsByGCPProjectId = async (gcpProjectName: string): Promise<{
  status: number,
  body: Array<object>
}> => {
  const response = await fetchWithAuthorizationHeader(`${TAGS_API_URL}?gcpProjectId=${gcpProjectName}`)
  return response.json()
}

export default getTagsByGCPProjectId