import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { gcpProjectRejectionRequest } from '../../types/gcpProject'
import api from '../../api'

const useRejectGcpProject = (gcpProjectRequestID: string) => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (gcpProjectRejection: gcpProjectRejectionRequest) => {
			return api.gcpProjects.rejectgcpProject(gcpProjectRejection)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['gcpProjects'] })
			queryClient.invalidateQueries({
				queryKey: ['gcpProjects', gcpProjectRequestID]
			})
		}
	})
}

export default useRejectGcpProject