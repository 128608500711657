import { useQuery } from '@tanstack/react-query'

import api from '../../api'

const useAllUserPermissions = () => {
	return useQuery({
		queryKey: ['allUserPermissions'],
		queryFn: () => api.accessManagement.getAllUserPermissions(),
		select(data) {
			const response = data.body
			return response
		}
	})
}

export default useAllUserPermissions
