import { FC, useEffect } from 'react'
import {
	Container,
	FormField,
	Link,
	Multiselect,
	SpaceBetween
} from '@cloudscape-design/components'
import { useForm, Controller } from 'react-hook-form'
import { useAccountCreationFormStore } from '../../../../stores/accounts/newAccountCreationFormStore'

const accountTypeOptions = [
	{ label: 'Sandbox', value: 'Sandbox' },
	{ label: 'Non-Production', value: 'Non-Production' },
	{ label: 'Production', value: 'Production' }
]

const Step1: FC = () => {
	const {
		formValues,
		actions: { setFormValues, setFormValidity }
	} = useAccountCreationFormStore()

	const {
		control,
		formState: { isValid }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: formValues
	})

	useEffect(() => {
		setFormValidity(isValid)
	}, [isValid, setFormValidity])

	return (
		<Container>
			<SpaceBetween direction="vertical" size="l">
				{/* <Controller
					name="projectName"
					control={control}
					rules={{
						required: true,
						pattern: {
							value: /^[a-zA-Z0-9-]+$/i,
							message: 'Only alphanumeric characters and hyphens are allowed'
						}
					}}
					render={({
						field: { name, onChange, value },
						fieldState: { error }
					}) => {
						return (
							<FormField
								label="Project Name"
								description="Provide same project name that is indicated in the Tags in the Cloud AppSheet"
								info={
									<Link
										external
										href="https://www.appsheet.com/start/d8e8e394-b9b5-4469-9535-ff080e72b176#control=Cloud%20Tags%20Library&page=table"
									>
										Cloud Tags Library in AppSheet
									</Link>
								}
								errorText={error?.message}
							>
								<Input
									name={name}
									value={value}
									onChange={({ detail }) => {
										onChange(detail.value)
										setFormValues('projectName', detail.value)
									}}
								/>
							</FormField>
						)
					}}
				/> */}

				<Controller
					name="accountTypes"
					control={control}
					rules={{
						required: true,
						validate: (value) => {
							return value.length > 0 || 'Select atleast one account type'
						}
					}}
					render={({ field: { onChange, value }, fieldState: { error } }) => {
						return (
							<FormField
								label="Account Types"
								description="Production account will not be provisioned without a non-production account"
								info={
									<Link
										external
										href="https://globetelecom.atlassian.net/wiki/spaces/globeinfra/pages/4512187069/Account+Management"
									>
										Account Terms and Conditions
									</Link>
								}
								errorText={error?.message}
							>
								<Multiselect
									selectedOptions={value}
									options={accountTypeOptions}
									placeholder="Select Account Type"
									onChange={({ detail }) => {
										onChange(detail.selectedOptions)
										setFormValues('accountTypes', detail.selectedOptions)
									}}
								/>
							</FormField>
						)
					}}
				/>
			</SpaceBetween>
		</Container>
	)
}

export default Step1
