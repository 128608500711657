import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { ApplicationRequest } from '../../types/applications'
import api from '../../api'

const useCreateNewApplication = () => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (application: ApplicationRequest) => {
			return api.applications.createNewApplication(application)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['applications'] })
		}
	})
}

export default useCreateNewApplication
