const toCamelCase = (string: string) => {
	return string
		.split(' ')
		.map(function (word, index) {
			// If it is the first word make sure to lowercase all the chars.
			if (index === 0) {
				return word.toLowerCase()
			}
			// If it is not the first word only upper case the first char and lowercase the rest.
			return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
		})
		.join('')
}
export const keysToLabel = (string: string) => {
	return string
		.split('')
		.map((word, i) => {
			if (i === 0) {
				return word.toUpperCase()
			}
			return word.charCodeAt(0) < 91 && word.charCodeAt(0) > 64
				? ' ' + word
				: word
		})
		.join('')
}

export default toCamelCase
