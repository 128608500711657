import useDevOkkarForm from '../../stores/devOkkarFormStore'

const Review = () => {
	const formState = useDevOkkarForm()

	return (
		<>
			<p>Review</p>
			<p>First field: {formState.actions.getField('firstField')}</p>
			<p>
				Second field:{' '}
				{JSON.stringify(formState.actions.getField('secondField'))}
			</p>
		</>
	)
}

export default Review
