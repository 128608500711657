import CONSTANT from '../../constants'
import type { ApplicationEditRequest } from '../../types/applications'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
const { APPLICATIONS_API_URL } = CONSTANT

const editApplication = async (
	editApplication: ApplicationEditRequest
): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(
		`${APPLICATIONS_API_URL}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				action: 'UPDATE'
			},
			body: JSON.stringify(editApplication)
		}
	)

	return response.json()
}

export default editApplication
