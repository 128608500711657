import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
import type { ProjectRejectionRequest } from '../../types/projects'

const { PROJECTS_API_URL } = CONSTANT

const rejectProject = async (
	projectRejection: ProjectRejectionRequest
): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(`${PROJECTS_API_URL}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			action: 'REJECT'
		},
		body: JSON.stringify(projectRejection)
	})

	return response.json()
}

export default rejectProject
