import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
import type { gcpProjectApprovalRequest } from '../../types/gcpProject'

const { GCPPROJECTS_API_URL } = CONSTANT

const acceptgcpProject = async (
	gcpProjectApproval: gcpProjectApprovalRequest
): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(`${GCPPROJECTS_API_URL}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			action: 'APPROVE'
		},
		body: JSON.stringify(gcpProjectApproval)
	})

	return response.json()
}

export default acceptgcpProject
