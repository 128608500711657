import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'

const { ACCOUNTS_API_URL } = CONSTANT

const getTagsByAccountId = async (
	accountId: string
): Promise<{
	status: number
	body: Array<object>
}> => {
	const response = await fetchWithAuthorizationHeader(
		`${ACCOUNTS_API_URL}?accountRequestID=${accountId}`
	)
	return response.json()
}

export default getTagsByAccountId
