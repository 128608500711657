import { produce } from 'immer'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'

type NewProjectFormState = {
	isFormValid: boolean
	formValues: Record<string, any>
}

type NewProjectFormActions = {
	actions: {
		clearFormValues: () => void
		setFormValues: (fieldName: string, value: any) => void
		setFormValidity: (isValid: boolean) => void
	}
}

const initialFormValues = {
	maintenanceWindowDay: {
		label: 'Tuesday',
		value: 'tuesday'
	},
	maintenanceWindowTime: '01:00',
	awsAccountID: '',
	distributionList: '',
	region: [
		{
			label: 'Singapore',
			value: 'singapore',
			description: 'ap-southeast-1'
		}
	],
	prnWhitelist: [],
	portfolio: '',
	securityAliasesWhitelist: [],
	application: '',
	projectName: '',
	//platformName: '',
	environment: '',
	deployment: '',
	dataClassification: '',
	serviceTier: 'nonprod',
	tags: [],
	workloadAMINames: [],
	projectUsers: [],
	tierLevel: '',
	budget: '',
	crfsDate: '',
	customProjectID: ''
}
//const initialFormValues = {}

const newProjectFromStore = create<NewProjectFormState & NewProjectFormActions>(
	(set, _get) => ({
		isFormValid: false,
		formValues: initialFormValues,
		actions: {
			clearFormValues: () => set(() => ({ formValues: initialFormValues })),

			setFormValidity: (isValid) => set(() => ({ isFormValid: isValid })),

			setFormValues: (fieldName, value) =>
				set((state) =>
					produce(state, (draft) => {
						draft.formValues[fieldName] = value
					})
				)
		}
	})
)

export const useNewProjectFormStore = () =>
	newProjectFromStore((state) => state)

if (process.env.NODE_ENV !== 'production') {
	mountStoreDevtool('NewProjectFormStore', newProjectFromStore)
}
