import {FC, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {
    Alert,
    Box,
    Button,
    ButtonDropdown,
    Container,
    ContentLayout,
    Grid,
    Header,
    SpaceBetween
} from '@cloudscape-design/components'

import HeaderWithStatusBadges from '../../../components/Common/HeaderWithStatusBadges'
import ApprovalDetails from '../../../components/Project/ApprovalDetails'
import EphemeralApprovalDetails from '../../../components/Project/EphemeralApprovalDetails'
import ApprovalForm from '../../../components/Project/ApprovalForm'
import EphemeralApprovalForm from '../../../components/Project/EphemeralApprovalForm'
import RejectionForm from '../../../components/Project/RejectionForm'
import LoadingSpinner from '../../../components/LoadingSpinner'
import CONSTANTS from '../../../constants'
import useSingleProject from '../../../hooks/query/useSingleProject'
import RequiredPermissions from '../../../helper/RequiredPermissions'
import CommentsOverview from '../../../components/Project/CommentsOverview'
import RenderProjectFields from './RenderProjectFields'
import {Project} from '../../../types/projects'
import useUserPermissions from '../../../hooks/query/useUserPermissions'

import ReadGenericTags from '../../../components/ControlledFormInputs/GenericTagsRead'
import EditGenericTags from '../../../components/ControlledFormInputs/GenericTagsEdit'

const ProjectDetails: FC = () => {
    const CURRENT_USER_ID = JSON.parse(localStorage["okta-token-storage"])["idToken"]["claims"]["email"]
    const {id} = useParams() as { id: string }
    const navigate = useNavigate()
    const {data: thisProject, isLoading} = useSingleProject(id, true)

    const thisProjectID = thisProject?.projectID || ''
    const isEphemeral = thisProject?.isephemeral !== undefined ? thisProject.isephemeral : false

    const {
        data: userPermissions,
        isLoading: isLoadingPermissions,
        error: permissionsError
    } = useUserPermissions(CURRENT_USER_ID);
    const hasSpecificPermissions = (permissions: string[], requiredPermissions: string[]): boolean => {
        return requiredPermissions.some(requiredPerm => permissions.includes(requiredPerm));
    };
    const specificPermissions = ["projects:*:*", "projects:*:approve", "*:*:*"];
    const hasRequiredPermissions = userPermissions && hasSpecificPermissions(userPermissions.permissions, specificPermissions);
    const [showApprovalForm, setShowApprovalForm] = useState(false)
    const [showRejectionForm, setShowRejectionForm] = useState(false)

    console.log('isEphemeral:', isEphemeral)
    if (!thisProject || isLoading) return <LoadingSpinner/>
    console.log('Next Step')

    if (thisProject.status === "ERROR") {
        return (
            <Alert
                statusIconAriaLabel="Error"
                type="error"
                header="An error occurred">
                {thisProject.details.message}
            </Alert>
        );
    }

    console.log("Another Step")
    const projectVersionedObject = isEphemeral ? getKeyValueEphmeralProject(thisProject) : getKeyValueProject(thisProject);
    
    let isAllTagsApproved = false
    if (thisProject?.tags !== undefined) {
        isAllTagsApproved = true
        let accountTags = thisProject?.tags
        let tagsApprovalStatuses = accountTags.approvalStatus
        for (const tagStatus of tagsApprovalStatuses) {
            if (tagStatus.status !== 1) {
                isAllTagsApproved = false
            }
        }
    }
    
    return (
        <ContentLayout
            header={
                <HeaderWithStatusBadges
                    headerName={thisProject?.details.projectName}
                    status={thisProject?.status}
                />
            }
        >
        
            <Grid
                gridDefinition={[
                    {colspan: {default: 3, xxs: 9}},
                    {colspan: {default: 9, xxs: 3}}
                ]}
            >
                <SpaceBetween direction="vertical" size="l">
                    <RenderProjectFields project={projectVersionedObject}/>
                    {!hasRequiredPermissions && (
                        (thisProject.status === 'PENDING_APPROVAL') && (
                            <RequiredPermissions
                                requiredPermissions={CONSTANTS.PROJECT_REQUESTOR}
                            >
                                {!isEphemeral && (
                                    <Container header={<Header variant="h3">Tags Details</Header>}>
                                        {thisProject?.tagsID && (
                                            <ReadGenericTags tagsId={thisProject.tagsID} layout={3}/>
                                        )}
                                    </Container>
                                )}
                            </RequiredPermissions>
                        ))}

                    {(
                        (
                            thisProject.status === 'APPROVED' ||
                            thisProject.status === 'REJECTED') && (
                            <RequiredPermissions
                                requiredPermissions={CONSTANTS.PROJECT_REQUESTOR}
                            >
                                {!isEphemeral && (
                                    <Container header={<Header variant="h3">Tags Details </Header>}>
                                        {thisProject?.tagsID && (
                                            <ReadGenericTags tagsId={thisProject.tagsID} layout={3}/>
                                        )}
                                    </Container>
                                )}
                            </RequiredPermissions>
                        ))}
                    {/* {(thisProject.status === 'APPROVED' ||
						thisProject.status === 'REJECTED') && (
						<RequiredPermissions
							requiredPermissions={CONSTANTS.PROJECT_APPROVER}
						>
							<Container header={<Header variant="h3">Tags Details</Header>}>
								{thisProject?.tagsID && (
									<ReadGenericTags tagsId={thisProject.tagsID} layout={3} />
								)}
							</Container>
						</RequiredPermissions>
					)} */}
                    {thisProject.status === 'PENDING_APPROVAL' && hasRequiredPermissions && (
                        <RequiredPermissions
                            requiredPermissions={CONSTANTS.PROJECT_APPROVER}
                        >
                            {!isEphemeral && (
                                <Container header={<Header variant="h3">Tags Details</Header>}>
                                    {thisProject?.tagsID && (
                                        <EditGenericTags tagsId={thisProject.tagsID}/>
                                    )}
                                </Container>
                            )}
                        </RequiredPermissions>
                    )}
                    {thisProject.status === 'PENDING_APPROVAL' &&
                        CURRENT_USER_ID !== thisProject.requestorID && (
                            <RequiredPermissions
                                requiredPermissions={CONSTANTS.PROJECT_APPROVER}
                            >
                                <Box float="right">
                                    <ButtonDropdown
                                        items={isEphemeral || isAllTagsApproved ? [
                                            {text: 'To Approve...', id: 'to-approve'},
                                            {text: 'To Reject...', id: 'to-reject'}
                                        ] : [
                                            {text: 'To Reject...', id: 'to-reject'}
                                        ]}
                                        onItemClick={({detail}) => {
                                            if (detail.id === 'to-approve') {
                                                setShowApprovalForm(true)
                                                setShowRejectionForm(false)
                                            }

                                            if (detail.id === 'to-reject') {
                                                setShowApprovalForm(false)
                                                setShowRejectionForm(true)
                                            }
                                        }}
                                        variant="primary"
                                    >
                                        Action
                                    </ButtonDropdown>
                                </Box>
                            </RequiredPermissions>
                        )}

                    {showApprovalForm && (
                        isEphemeral ? (
                            <EphemeralApprovalForm
                                thisProjectID={thisProjectID}
                                shouldShowApprovalForm={setShowApprovalForm}
                            />
                        ) : (
                            <ApprovalForm
                                thisProjectID={thisProjectID}
                                shouldShowApprovalForm={setShowApprovalForm}
                            />
                        )
                    )}

                    {showRejectionForm && (
                        <RejectionForm
                            thisProjectID={thisProjectID}
                            shouldShowRejectionForm={setShowRejectionForm}
                        />
                    )}
                    {thisProject.status === 'APPROVED' && (
                        <RequiredPermissions
                            requiredPermissions={CONSTANTS.PROJECT_APPROVER}
                        >
                            {isEphemeral ? (
                                <EphemeralApprovalDetails approvalDetails={thisProject.approval!} />
                            ) : (
                                <ApprovalDetails approvalDetails={thisProject.approval!} />
                            )}
                        </RequiredPermissions>
                    )}
                    {thisProject.status === 'REJECTED' && (
                        <Box float="right">
                            <Button
                                variant="primary"
                                onClick={() => navigate(isEphemeral ? `/projects/ephemeral/edit/${id}` : `/projects/edit/${id}`)}
                            >
                                Re-Open Request
                            </Button>
                        </Box>
                    )}
                </SpaceBetween>

                <SpaceBetween direction="vertical" size="l">
                    <Container header={<Header variant="h3">Comment History</Header>}>
                        <CommentsOverview comments={thisProject.comments}/>
                    </Container>
                </SpaceBetween>
            </Grid>
        </ContentLayout>
    )
}

const getKeyValueProject = (project: Project) => {
    return [
        {
            label: 'Requester',
            value: project?.requestorID,
            version: getVersionStr(project?.version, 'requestorID')
        },
        {
            label: 'Project Name',
            value: project?.details.projectName,
            version: getVersionStr(project?.version, 'projectName')
        },
        // {
        //     label: 'Platform Name',
        //     value: project?.details.platformName,
        //     version: getVersionStr(project?.version, 'platformName')
        // },
        {
            label: 'AWS Account ID',
            value: project?.details.awsAccountID,
            version: getVersionStr(project?.version, 'awsAccountID')
        },
        {
            label: 'Distribution List',
            value: project?.details.distributionList,
            version: getVersionStr(project?.version, 'distributionList')
        },
        {
            label: 'Region',
            value: project?.details.region[0].label,
            version: getVersionStr(project?.version, 'region')
        },
        // {
        // 	label: 'PRN Whitelist',
        // 	value: project?.details.prnWhitelist,
        // 	version: getVersionStr(project?.version, 'prnWhitelist')
        // },
        // {
        // 	label: 'Delegate AWS Account ID',
        // 	value: project?.details.delegateAWSAccountID,
        // 	version: getVersionStr(project?.version, 'delegateAWSAccount')
        // },
        // {
        // 	label: 'Subnet Alias',
        // 	value: project?.details.subnetAlias,
        // 	version: getVersionStr(project?.version, 'subnetAlias')
        // },
        {
            label: 'Data Classification',
            value: project?.details.dataClassification,
            version: getVersionStr(project?.version, 'dataClassification')
        },
        // {
        // 	label: 'VPC Alias',
        // 	value: project?.details.vpcAlias,
        // 	version: getVersionStr(project?.version, 'vpcAlias')
        // },
        // {
        // 	label: 'WBS Code',
        // 	value: project?.details.wbsCode,
        // 	version: getVersionStr(project?.version, 'wbsCode')
        // },
        // {
        // 	label: 'Cost Center',
        // 	value: project?.details.costCenter,
        // 	version: getVersionStr(project?.version, 'costCenter')
        // },
        {
            label: 'Deployment',
            value: project?.details.deployment,
            version: getVersionStr(project?.version, 'deployment')
        },
        {
            label: 'Project Budget Allocated',
            value: project?.details.budget,
            version: getVersionStr(project?.version, 'budget')
        },
        {
            label: 'CRFS Date',
            value: project?.details.crfsDate,
            version: getVersionStr(project?.version, 'crfsDate')
        },
        {
            label: 'Maintenace Window Day (GMT +8)',
            value: project?.details.maintenanceWindowDay.label,
            version: getVersionStr(project?.version, 'maintenanceWindowDay')
        },
        {
            label: 'Maintenace Window Time (GMT +8)',
            value: project?.details.maintenanceWindowTime,
            version: getVersionStr(project?.version, 'maintenanceWindowTime')
        },
        {
            label: 'Environment',
            value: project?.details.environment.label,
            version: getVersionStr(project?.version, 'environment')
        }
    ]
}

const getKeyValueEphmeralProject = (project: Project) => {
    return [
        {
            label: 'Requester',
            value: project?.requestorID,
            version: getVersionStr(project?.version, 'requestorID')
        },
        {
            label: 'Project Name',
            value: project?.details.project,
            version: getVersionStr(project?.version, 'project')
        },
		{
            label: 'Workload',
            value: project?.details.workload,
            version: getVersionStr(project?.version, 'workload')
        },
        {
            label: 'AWS Account ID',
            value: project?.details.awsAccountID,
            version: getVersionStr(project?.version, 'awsAccountID')
        },
        {
            label: 'Environment',
            value: project?.details.environment.label,
            version: getVersionStr(project?.version, 'environment')
        },
		{
            label: 'Teardown After Days',
            value: project?.details.teardown_after_days,
            version: getVersionStr(project?.version, 'teardown_after_days')
        }
    ]
}

const getVersionStr = (version: Array<any> | undefined, key: string) => {
    let versionItems: string[] = []
    version?.forEach((element) => {
        if (!element[key]) return
        if (key === 'region' && element.region) {
            versionItems.unshift(`v${element.versionID}: ${element.region[0].label}`)
        } else if (key === 'maintenanceWindowDay' && element.maintenanceWindowDay) {
            versionItems.unshift(
                `v${element.versionID}: ${element.maintenanceWindowDay.label}`
            )
        } 
        else {
            versionItems.unshift(`v${element.versionID}: ${element[key]}`)
        }
    })
    return versionItems
}

export default ProjectDetails
