import { FC } from 'react'
import { Box, ColumnLayout, SpaceBetween } from '@cloudscape-design/components'
import useSingleTags from '../../../hooks/query/useSingleTags'
import LoadingSpinner from '../../LoadingSpinner'
import ApproverOverview from '../ApproverOverview'
import { keysToLabel } from '../../../helper/toCamelCase'

interface ReadGenericTagsProps {
	tagsId: string
	layout?: number
}

const ReadGenericTags: FC<ReadGenericTagsProps> = ({ tagsId, layout = 2 }) => {
	const { data: originalTags, isLoading: isLoadingTags } = useSingleTags(tagsId)

	if (!originalTags || isLoadingTags) return <LoadingSpinner />

	const tagKeys = Object.keys(originalTags['details']) || []
	const getLabel = (tagkey: string) => {
		switch (tagkey) {
			case 'tagProject':
				return 'Project'
			case 'WBSCode':
			case 'W B S Code':
				return 'WBS Code'
			default:
				return keysToLabel(tagkey)
		}
	}
	return (
		<>
			<SpaceBetween direction="vertical" size="s">
				<ColumnLayout columns={layout} variant="text-grid">
					{tagKeys?.map((tagkey) => (
						<div key={tagkey}>
							<Box variant="awsui-key-label">{getLabel(tagkey)}</Box>
							{typeof originalTags['details'][tagkey] == 'object'
								? originalTags['details'][tagkey]['value']
								: originalTags['details'][tagkey] == ''
								? 'NA'
								: originalTags['details'][tagkey]}
						</div>
					))}
				</ColumnLayout>
				<ApproverOverview
					approvalStatus={originalTags['approvalStatus']}
					isDisabled={true}
				/>
			</SpaceBetween>
		</>
	)
}

export default ReadGenericTags
