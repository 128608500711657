import CONSTANTS from "../../constants"
import fetchWithAuthorizationHeader from "../../helper/fetchWithAuthorizationHeader"
import type { RhocpTenantApprovalRequest, RhocpTenantApprovalResponse } from "../../types/rhocpTenant"

const { RHOCPTENANTS_API_URL } = CONSTANTS

const acceptRhocpTenant = async (
  rhocpTenantApproval: RhocpTenantApprovalRequest
): Promise<RhocpTenantApprovalResponse> => {
  const response = await fetchWithAuthorizationHeader(`${RHOCPTENANTS_API_URL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      action: 'APPROVE'
    },
    body: JSON.stringify(rhocpTenantApproval)
  })

  return response.json()
}

export default acceptRhocpTenant