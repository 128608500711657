import { DatePicker, FormField } from '@cloudscape-design/components'
import { FC, ReactNode } from 'react'
import { Control, Controller, UseControllerProps } from 'react-hook-form'

interface DatePickerProps {
	label: string
	name: string
	control: Control
	placeholder?: string
	description?: string
	info?: ReactNode
	constraintText?: string
	rules?: UseControllerProps['rules']
	setFormValues: (name: string, value: any) => void
}

const FormDatePicker: FC<DatePickerProps> = ({
	label,
	name,
	control,
	placeholder,
	description,
	info,
	constraintText,
	rules,

	setFormValues
}) => {
	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			render={({ field: { name, onChange, value }, fieldState: { error } }) => {
				return (
					<FormField
						label={label}
						description={description}
						info={info}
						constraintText={constraintText}
						errorText={error?.message}
					>
						<DatePicker
							onChange={({ detail }) => {
								onChange(detail.value)
								setFormValues(name, detail.value)
							}}
							value={value}
							placeholder="YYYY/MM/DD"
							startOfWeek={0}
						/>
					</FormField>
				)
			}}
		/>
	)
}

export default FormDatePicker
