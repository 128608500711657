import { useOktaAuth } from '@okta/okta-react'
import { FC } from 'react'

import {
	Container,
	Header,
	SpaceBetween,
	TextContent
} from '@cloudscape-design/components'

import RequiredPermissions from '../../helper/RequiredPermissions'

const ComponentRBAC: FC = () => {
	const { authState } = useOktaAuth()

	return (
		<SpaceBetween direction="vertical" size="l">
			<RequiredPermissions requiredPermissions={['projects:*:approve']}>
				<Container
					header={<Header variant="h3">ASP_APPROVER Level Component</Header>}
				>
					<TextContent>
						<p>
							You are able to see this becuase you are in 'ASP_APPROVER' Okta
							User Group
						</p>
						<p>
							You are in the following Okta User Groups:{' '}
							{JSON.stringify(authState?.idToken?.claims?.groups)}
						</p>
					</TextContent>
				</Container>
			</RequiredPermissions>

			<RequiredPermissions
				requiredPermissions={['projects:*:create', 'projects:*:read']}
			>
				<Container
					header={<Header variant="h3">ASP_USER Level Component</Header>}
				>
					<TextContent>
						<p>
							You are able to see this becuase you are in 'ASP_USER' Okta User
							Group
						</p>
						<p>
							You are in the following Okta User Groups:{' '}
							{JSON.stringify(authState?.idToken?.claims?.groups)}
						</p>
					</TextContent>
				</Container>
			</RequiredPermissions>
		</SpaceBetween>
	)
}

export default ComponentRBAC
