import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { gcpProjectApprovalRequest } from '../../types/gcpProject'
import api from '../../api'

const useAcceptGcpProject = (gcpProjectRequestID: string) => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (gcpProjectApproval: gcpProjectApprovalRequest) => {
			return api.gcpProjects.acceptgcpProject(gcpProjectApproval)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['gcpProjects'] })
			queryClient.invalidateQueries({
				queryKey: ['gcpProjects', gcpProjectRequestID]
			})
		}
	})
}

export default useAcceptGcpProject