import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { ProjectRequest } from '../../types/projects'
import api from '../../api'

const useCreateNewProject = () => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (project: ProjectRequest) => {
			return api.projects.createNewProject(project)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['projects'] })
		}
	})
}

export default useCreateNewProject
