import { useQuery } from '@tanstack/react-query'

import type { Platform } from '../../types/platforms'
import api from '../../api'

const usePlatforms = () => {
	return useQuery({
		queryKey: ['platforms'],
		queryFn: () => api.platforms.getPlatforms(),
		select(data) {
			return data.body as Array<Platform>
		}
	})
}

export default usePlatforms
