import { produce } from 'immer'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'

type ApprovalFormState = {
	isFormValid: boolean
	formValues: Record<string, any>
}

type ApprovalFormActions = {
	actions: {
		clearFormValues: () => void
		setFormValues: (fieldName: string, value: any) => void
		setFormValidity: (isValid: boolean) => void
	}
}

const _initialFormValues = {
	approverID: '',
	namePrefix: '',
	networkProjectID: '',
	network: '',
	subnetwork: '',
	ipRangePods: '',
	ipRangeServices: '',
	subnetworkRange: '',
	controlPlaneRange: '',
	ccmProjectId: 'isg-ceso-ccm-pd-ll90',
	ccmClusterName: 'glo-ccm-prd-gke-ase1-rin',
	argocdNamespace: '',
	region:'asia-southeast1',
	action: 'APPROVE',
	kVersion: '1.30.3-gke.1969002',
	releaseChannel: 'STABLE'
}

const ApprovalFromStore = create<ApprovalFormState & ApprovalFormActions>(
	(set, _get) => ({
		isFormValid: false,
		formValues: _initialFormValues,
		actions: {
			clearFormValues: () => set(() => ({ formValues: _initialFormValues })),

			setFormValidity: (isValid) => set(() => ({ isFormValid: isValid })),

			setFormValues: (fieldName, value) =>
				set((state) =>
					produce(state, (draft) => {
						draft.formValues[fieldName] = value
					})
				)
		}
	})
)

export const useApprovalFormStore = () => ApprovalFromStore((state) => state)

if (process.env.NODE_ENV !== 'production') {
	mountStoreDevtool('ApprovalFormStore', ApprovalFromStore)
}
