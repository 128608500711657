import { useMutation } from '@tanstack/react-query'

import type { UpdateApprovalTags } from '../../types/tags'
import api from '../../api'

const useMultiStepApprovalTags = (tagsID: string) => {
	return useMutation({
		mutationFn: (updateTags: UpdateApprovalTags) => {
			return api.tags.updateMultiStepTagsById(updateTags)
		},
		onSuccess: () => {},
		onError: (error) => {}
	})
}

export default useMultiStepApprovalTags
