import { produce } from 'immer'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'

export type GenericTagsManagementFormState = {
	isFormValid: boolean
	formValues: Record<string, any>
}

export type GenericTagsManagementFormActions = {
	actions: {
		clearFormValues: () => void
		setFormValues: (fieldName: string, value: any) => void
		setFormValidity: (isValid: boolean) => void
	}
}

const initialFormValues = {
	platformName: '',
	tagProject: '',
	//platformRepo: '',
	systemAlias: '',
	dataClassification: '',
	fundingSourceType: '',
	costCenter: '',
	WBSCode: ''
}

const genericTagsManagementFormStore = create<
	GenericTagsManagementFormState & GenericTagsManagementFormActions
>((set, _get) => ({
	isFormValid: false,
	formValues: initialFormValues,
	actions: {
		clearFormValues: () => set(() => ({ formValues: initialFormValues })),

		setFormValidity: (isValid) => set(() => ({ isFormValid: isValid })),

		setFormValues: (fieldName, value) =>
			set((state) =>
				produce(state, (draft) => {
					draft.formValues[fieldName] = value
				})
			)
	}
}))

export const useGenericTagsManagementFormStore = () =>
	genericTagsManagementFormStore((state) => state)

if (process.env.NODE_ENV !== 'production') {
	mountStoreDevtool(
		'GenericTagsManagementFormStore',
		genericTagsManagementFormStore
	)
}
