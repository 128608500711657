import CONSTANT from '../../constants'
import type { ProjectEditRequest } from '../../types/projects'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
const { PROJECTS_API_URL } = CONSTANT

const editProject = async (editProject: ProjectEditRequest): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(`${PROJECTS_API_URL}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			action: 'UPDATE'
		},
		body: JSON.stringify(editProject)
	})

	return response.json()
}

export default editProject
