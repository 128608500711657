import { FC, useEffect } from 'react';
import { Controller, useForm, RegisterOptions } from 'react-hook-form';

import {
	Container,
	FormField,
	Input,
	Link,
	SpaceBetween,
	Header
} from '@cloudscape-design/components';

import { usegcpProjectCreationFormStore } from '../../../../stores/gcpProjects/newgcpProjectCreationFormStore';
import { StepConfig } from '../steps_config';

const step2Fields: Array<StepConfig> = [
	{
		label: 'Project Viewer Distribution List',
		fieldName: 'ProjectViewerDistributionList',
		description:
			"The Project Viewer Distribution List will be used as the GCP Project email. Here's the standard format: gcp-glo02-<group_name>-<platform_name>-<environment>@globe.com.ph",
		info: (
			<Link
				external
				href="https://compass.globe.com.ph/latest/gcp/user-guide/asp/meet_criteria/"
			>
				Valid Distribution List Link
			</Link>
		),
		type: 'text',
		placeholder: 'gcp-glo02-<group_name>-<platform_name>-<environment>@globe.com.ph'
	},
	{
		label: 'Budget Alerts Distribution List',
		fieldName: 'BudgetAlertsDistributionList',
		description:
			"The Budget Alerts Distribution List will receive budget-related alerts. Here's the standard format: gcp-glo02-<group_name>-<platform_name>-budgetowners@globe.com.ph",
		type: 'text',
		placeholder: 'gcp-glo02-<group_name>-<platform_name>-budgetowners@globe.com.ph'
	},
	{
		label: 'Budget Threshold Alert',
		fieldName: 'MonthlyBudgetCap',
		description:
			'Kindly place the Initial Monthly Budget Cap that you would like each GCP Project to have as part of GCP Project Creation. The Service Owner can still update the Budget Cap as necessary via the pipeline after GCP project creation. Kindly ensure that the Budget Cap indicated here is aligned with the approved budget allocated for your particular Project/Workload.',
		type: 'number',
		constraintText: 'Value should be in USD. Example: 100 (means 100USD)'
	}
];

const getFieldTypeRules = (fieldType: string, fieldName: string): RegisterOptions => {
	switch (fieldType) {
		case 'text':
			if (fieldName === 'ProjectViewerDistributionList') {
				return {
					required: true,
					pattern: {
						value: /^gcp-glo02-[a-zA-Z]+-[a-zA-Z0-9]+-[a-zA-Z]+@globe\.com\.ph$/,
						message: 'Invalid Project Viewer Distribution List email address'
					}
				};
			} else if (fieldName === 'BudgetAlertsDistributionList') {
				return {
					required: true,
					pattern: {
						value: /^gcp-glo02-[a-zA-Z]+-[a-zA-Z0-9]+-budgetowners@globe\.com\.ph$/,
						message: 'Invalid Budget Alerts Distribution List email address'
					}
				};
			}
			return { required: true };
		case 'number':
			return {
				required: true,
				min: {
					value: 0,
					message: 'Value should be greater than 0'
				},
				max: {
					value: 100000,
					message: 'Value should be less than 100000'
				}
			};
		default:
			return { required: true };
	}
};

const Step2: FC = () => {
	const {
		formValues,
		actions: { setFormValues, setFormValidity }
	} = usegcpProjectCreationFormStore();

	const {
		control,
		formState: { isValid }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: formValues
	});

	useEffect(() => {
		setFormValidity(isValid);
	}, [isValid, setFormValidity]);

	return (
		<SpaceBetween direction="vertical" size="l">
			<Container>
				<Header variant="h3">{formValues.gcpProjectType.label}</Header>
				<SpaceBetween direction="vertical" size="l">
					{step2Fields.map((field: StepConfig, i) => {
						const gcpProjectTypeFieldName = `${formValues.gcpProjectType.value}${field.fieldName}`;

						return (
							<Controller
								name={gcpProjectTypeFieldName}
								control={control}
								key={i}
								rules={getFieldTypeRules(field.type, field.fieldName)}
								render={({
									field: { name, onChange, value },
									fieldState: { error }
								}) => (
									<FormField
										label={field.label}
										description={field.description}
										info={field.info}
										constraintText={field.constraintText}
										errorText={error?.message}
									>
										{field.type === 'text' && (
											<Input
												placeholder={field.placeholder}
												name={name}
												value={value}
												onChange={({ detail }) => {
													onChange(detail.value);
													setFormValues(gcpProjectTypeFieldName, detail.value);
												}}
											/>
										)}

										{field.type === 'number' && (
											<Input
												name={name}
												value={value}
												type="number"
												onChange={({ detail }) => {
													onChange(detail.value);
													setFormValues(gcpProjectTypeFieldName, +detail.value);
												}}
											/>
										)}
									</FormField>
								)}
							/>
						);
					})}
				</SpaceBetween>
			</Container>
		</SpaceBetween>
	);
};

export default Step2