import CONSTANTS from "../../constants";
import fetchWithAuthorizationHeader from "../../helper/fetchWithAuthorizationHeader";
import type { RhocpTenantRejectionReponse, RhocpTenantRejectionRequest } from "../../types/rhocpTenant";

const { RHOCPTENANTS_API_URL } = CONSTANTS

const rejectRhocpTenant = async (
  rhocpTenantRejection: RhocpTenantRejectionRequest
): Promise<RhocpTenantRejectionReponse> => {
  const response = await fetchWithAuthorizationHeader(`${RHOCPTENANTS_API_URL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      action: 'REJECT'
    },
    body: JSON.stringify(rhocpTenantRejection)
  })

  return response.json()
}

export default rejectRhocpTenant