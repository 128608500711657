import { useQuery } from '@tanstack/react-query'

import api from '../../api'

const useUserPermissions = (userEmail: string) => {
	return useQuery({
		queryKey: ['userPermissions', userEmail],
		queryFn: () => api.accessManagement.getUserPermissions(userEmail),
		select(data) {
			const response = data.body[0]
			return response
		}
	})
}

export default useUserPermissions
