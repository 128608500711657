import { useQuery } from '@tanstack/react-query'

import type { Account } from '../../types/account'
import api from '../../api'

const useAccounts = () => {
	return useQuery({
		queryKey: ['accounts'],
		queryFn: () => api.accounts.getAccounts(),
		select(data) {
			return data.body as Array<Account>
		}
	})
}

export default useAccounts
