import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    Alert,
    Button,
    ColumnLayout,
    Header
} from '@cloudscape-design/components'
import FormInput from '../Input'
import { useForm } from 'react-hook-form'
import FormSelect from '../Select'
import { useEditTagsManagementFormStore } from '../../../stores/tags/editTagsManagementFormStore'
import useSingleTags from '../../../hooks/query/useSingleTags'
import LoadingSpinner from '../../LoadingSpinner'
 
import CONSTANT from '../../../constants'
 
import { UpdateTags } from '../../../types/tags'
import useUpdateTags from '../../../hooks/mutate/useUpdateTags'
import ApproverOverview from '../ApproverOverview'
import { useOktaAuth } from '@okta/okta-react'
import PORTFOLIO_LIST from '../../../portfolio'
import useSingleGcpProject from '../../../hooks/query/useSingleGcpProject'
 
const { COST_TYPE_LIST, PLATFORM_LIST } = CONSTANT
 
interface EditGenericTagsProps {
    tagsId: string
    layout_col?: number
    page_context?: string
    isPvm?: boolean
}
 
const EditGenericTags: FC<EditGenericTagsProps> = ({
    tagsId,
    layout_col = 2,
    isPvm = true
}) => {
    const { authState } = useOktaAuth()
    const thisUserEmail = authState?.idToken?.claims.email as string
 
    const { data: originalTags, isLoading: isLoadingTags } = useSingleTags(tagsId)

    const { id: gcpProjectID } = useParams() as { id: string }
    const { data: thisgcpProject, isLoading } = useSingleGcpProject(gcpProjectID, true)
 
    const {
        formValues,
        actions: { setFormValues, setFormValidity, setOriginalRequestFormValues }
    } = useEditTagsManagementFormStore()
 
    const { mutate: updateTagsById, isLoading: isLoadingUpdateTags } =
        useUpdateTags(tagsId)
 
    const {
        control,
        reset,
        setValue,
        formState: { isValid, dirtyFields }
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: formValues.details,
        shouldUseNativeValidation: true
    })
 
    useEffect(() => {
        if (formValues?.fundingSourceType && formValues.fundingSourceType?.value === 'opex') {
            setValue('WBSCode', '')
            setFormValues('WBSCode', '')
        } else if (formValues?.fundingSourceType && formValues.fundingSourceType?.value === 'capex') {
            setValue('costCenter', '')
            setFormValues('costCenter', '')
        }
    }, [formValues.fundingSourceType, setValue, setFormValues])
 
    useEffect(() => {
        setFormValidity(isValid)
    }, [isValid, setFormValidity])

    useEffect(() => {
		const groupValue = formValues.group?.value ?? '';
		let projectType = '';
		if (thisgcpProject?.type === 'Develop') {
			projectType = 'dev';
		} else if (thisgcpProject?.type === 'Staging') {
			projectType = 'stg';
		} else if (thisgcpProject?.type === 'Production') {
			projectType = 'prod';
		} else if (thisgcpProject?.type === 'Sandbox') {
			projectType = 'sbx';
        }
		const projectNameValue = `glo-${groupValue}-${formValues.systemAlias}-project-${projectType}`;
		setValue('projectName', projectNameValue);
		setFormValues('projectName', projectNameValue);
	}, [formValues.group?.value, formValues.systemAlias, thisgcpProject?.type, setValue, setFormValues]);
 
    const [notification, setNotification] = useState<any>({
        type: '',
        statusIconAriaLabel: '',
        msg: ''
    })
    const [showTagsNotification, setShowTagsNotification] = useState(false)
 
    useEffect(() => {
        if (typeof originalTags === 'object' && originalTags !== null) {
            setOriginalRequestFormValues({
                formValues: originalTags.details,
                isFormValid: false
            })
            reset(originalTags.details)
        }
        // eslint-disable-next-line
    }, [originalTags])
 
    if (!originalTags || isLoadingTags) return <LoadingSpinner />
 
    const saveUpdatedTags = () => {
        console.log('save Updated Tags')
        const updateTagRequest: UpdateTags = {
            tagsID: tagsId,
            requestorID: thisUserEmail,
            action: 'UPDATE_TAGS',
            status: 'PENDING_APPROVAL',
            details: formValues
        }
        updateTagsById(updateTagRequest, {
            onSuccess: () => {
                setNotification({
                    type: 'success',
                    statusIconAriaLabel: 'Success',
                    msg: 'Tags Status Updated Successfully'
                })
                setShowTagsNotification(true)
                setTimeout(() => {
                    setShowTagsNotification(false)
                }, 3000)
            },
            onError: () => {
                setNotification({
                    type: 'error',
                    statusIconAriaLabel: 'Error',
                    msg: 'Error saving Tags, Please try again!'
                })
                setShowTagsNotification(true)
                setTimeout(() => {
                    setShowTagsNotification(false)
                }, 3000)
            }
        })
    }
 
    return (
        <>
            <ColumnLayout columns={layout_col} variant="default">
            {isPvm && (
                <FormSelect
                    name="platformName"
                    label="Platform Name"
                    control={control}
                    rules={{
                        required: 'Please Select Option'
                    }}
                    setFormValues={setFormValues}
                    options={PLATFORM_LIST}
                    placeholder="Select Platform "
                />
            )}
			<FormInput
				name="platformOwner"
				label="Platform Owner"
				type="text"
				placeholder="Enter Platform Owner"
				control={control}
				setFormValues={setFormValues}
			/>
			<FormInput
				name="systemAlias"
				label="System Alias"
				type="text"
				placeholder="Example: hipms, namax"
				control={control}
				rules={{
					required: 'System alias should be 5 letters, Example: hipms',
					pattern: {
						value: /^[A-Za-z][A-Za-z0-9-]{4,}$/g,
						message: 'System alias should be 5 letters, Example: hipms'
					},
					minLength: {
						value: 5,
						message: 'System alias should be 5 letters, Example: hipms'
					},
					maxLength: {
						value: 5,
						message: 'System alias should be 5 letters, Example: namax'
					}
				}}
				setFormValues={setFormValues}
			/>
			<FormSelect
				label="Group"
				name="group"
				control={control}
				rules={{
					required: 'Please Select Option'
				}}
				setFormValues={setFormValues}
				options={PORTFOLIO_LIST}
				placeholder="Select Group"
			/>
            {isPvm && (
                <>
                        <FormInput
                            name="projectName"
                            label="Project Name"
                            type="text"
                            control={control}
                            setFormValues={setFormValues}
                            placeholder=""
                            disabled={true}
                        />
                        <br />
                </>
            )}
			<FormSelect
				label="Funding Source"
				name="fundingSourceType"
				control={control}
				rules={{
					required: 'Please Select Option'
				}}
				setFormValues={setFormValues}
				options={COST_TYPE_LIST}
				placeholder="Select Funding Source Type"
			/>
                {isPvm && (<br />)}
			<FormInput
				name="WBSCode"
				label="WBS Code"
				type="text"
				control={control}
				rules={{
					pattern: {
						value: /^[A-Z]{2,5}-[A-Z]{1,15}-[0-9]{2}(?:-[A-Z0-9]{2,})?(?:-[A-Z0-9]{2,})?$/g, // /^[A-Z]{2,5}-[A-Z]{1,15}-[0-9]{2}$/g,
						message: 'Please Enter all in CAPS, Ex. OP20-G,ISG24-G,PSD-OMN-21'
					},
					minLength: {
						value: 3,
						message: 'Minimum length should be 3'
					}
				}}
				setFormValues={setFormValues}
				placeholder="Example: ISG-PROJECTA-23, ISG-PROJECTB-24, EDO-PROJECTC-24-SW-01"
				disabled={
					formValues?.fundingSourceType &&
					formValues.fundingSourceType.value === 'opex'
				}
			/>
			<FormInput
				name="costCenter"
				label="Cost Center"
				type="text"
				control={control}
				rules={{
					pattern: {
						value: /^[A-Z0-9]{1,7}-[A-Z0-9]{1,7}$/g,
						message: 'Please Enter all in CAPS, Ex. OP20-G,ISG24-G,PSD-OMN-21'
					},
					minLength: {
						value: 3,
						message: 'Minimum length should be 3'
					}
				}}
				setFormValues={setFormValues}
				placeholder="Example: ISG17-A, OP20-G, MP-CIV-20, HTT7-B,ISG01,PSD-TECCF/19"
				disabled={
					formValues?.fundingSourceType &&
					formValues.fundingSourceType.value === 'capex'
				}
			/>
            </ColumnLayout>
            <br />
            {Object.keys(dirtyFields).length > 0 && (
                <>
                    <Header
                        variant="h3"
                        description="Existing Tags has been updated, Would you like to save updated Tags"
                    ></Header>
                    <Button
                        variant="primary"
                        onClick={saveUpdatedTags}
                        loading={isLoadingUpdateTags}
                    >
                        Save Tags{' '}
                    </Button>
                    <br />
                    {showTagsNotification && (
                        <>
                            <br />
                            <Alert
                                statusIconAriaLabel={notification.statusIconAriaLabel}
                                type={notification.type}
                            >
                                {notification.msg}
                            </Alert>
                        </>
                    )}
                </>
            )}
 
            <br />
 
            <ApproverOverview
                approvalStatus={originalTags['approvalStatus']}
                tagsID={tagsId}
                description="Tick checkBox to Provide approval"
                //isDisabled={page_context == 'PROJECT' ? true : false}
            />
        </>
    )
}
 
export default EditGenericTags