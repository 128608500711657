/**
 `hasPermission` function:
 1. Take a single required permission and a list of the user's permissions as input.
 2. Split the required permission into three parts: resource, identifier, and action.
 3. Loop through each permission in the user's permissions list.
 a. For each user permission, split it into three parts: resource, identifier, and action.
 b. Compare the resource, identifier, and action of the user's permission with those of the required permission.
 c. If there is an exact match or if a part has a wildcard (`*`), which means "any", mark it as a match.
 d. If a match is found, exit the loop early and return `true` to indicate that the user has the required permission.
 4. If no match is found after looping through all user permissions, return `false`.

 `hasRequiredPermissions` function:
 1. Take a list of required permissions and a list of the user's permissions as input.
 2. Loop through each permission in the list of required permissions.
 a. For each required permission, call the `hasPermission` function with the current required permission and the user's permissions list.
 b. If `hasPermission` returns `false` (meaning the user does not have this particular permission), return `false` immediately to indicate that not all required permissions are satisfied.
 3. If the loop completes without returning `false`, it means the user has all the required permissions. In this case, return `true`.

 This combination of functions allows checking whether a user has all of the permissions required for a particular action or resource by breaking down the problem into smaller, more manageable pieces.
 */

/**
 * This function checks if a single required permission is satisfied by the user's permissions.
 *
 * @param requiredPermission - A string representing a single required permission.
 * @param userPermissions - An array of strings representing the user's permissions.
 *
 * @returns - A boolean indicating if the required permission is satisfied.
 */
const hasPermission = (requiredPermission: string, userPermissions: Array<string>): boolean => {
    // Split the required permission into its components [resource, identifier, action]
    const [requiredResource, requiredIdentifier, requiredAction] = requiredPermission.split(':');

    // Loop through each user permission
    for (const userPermission of userPermissions) {
        // Split the user permission into its components [resource, identifier, action]
        const [userResource, userIdentifier, userAction] = userPermission.split(':');

        // Check if the resource, identifier, and action match, taking into account wildcards
        if ((userResource === requiredResource || userResource === '*')
            && (userAction === requiredAction || userAction === '*')) {
            // If there is a match, return true
            return true;
        }
    }

    // If the function has not returned by this point, it means the required permission is not satisfied, so return false
    return false;
};

/**
 * This function checks if a user has all the required permissions.
 *
 * @param requiredPermissions - An array of strings representing the required permissions.
 * @param userPermissions - An array of strings representing the user's permissions.
 *
 * @returns - A boolean indicating if the user has all the required permissions.
 */
const hasRequiredPermissions = (requiredPermissions: Array<string>, userPermissions: Array<string>): boolean => {
    // Loop through each required permission
    for (const requiredPermission of requiredPermissions) {
        // If the required permission is not satisfied by any user permission, return false
        if (!hasPermission(requiredPermission, userPermissions)) {
            return false;
        }
    }

    // If the function has not returned by this point, it means all required permissions are satisfied, so return true
    return true;
};

/*
const userPermissions1: string[] = ["projects:*:create", "access:*:read"];
const userPermissions2: string[] = ["projects:123:update", "access:*:read", "projects:*:approve", "access:*:create"];
const userPermissions3: string[] = ["*:*:*"];
const userPermissions4: string[] = ["projects:*:delete", "access:*:read", "projects:*:approve", "access:*:create", "access:*:update"];
const userPermissions5: string[] = ["projects:*:create", "access:settings:create", "access:settings:update"];

// Example 1: User has general create permission for projects and read permission for access.
console.log(hasRequiredPermissions(["projects:*:create"], userPermissions1)); // Output: true
console.log(hasRequiredPermissions(["access:*:read"], userPermissions1)); // Output: true
console.log(hasRequiredPermissions(["projects:*:approve"], userPermissions1)); // Output: false

// Example 2: User has update permission for project 123, read and create permission for all access, and approve permission for all projects.
console.log(hasRequiredPermissions(["projects:123:update"], userPermissions2)); // Output: true
console.log(hasRequiredPermissions(["access:*:read"], userPermissions2)); // Output: true
console.log(hasRequiredPermissions(["projects:*:approve"], userPermissions2)); // Output: true
console.log(hasRequiredPermissions(["access:*:create"], userPermissions2)); // Output: true

// Example 3: User has wildcard permissions, which means access to everything.
console.log(hasRequiredPermissions(["projects:124:delete"], userPermissions3)); // Output: true
console.log(hasRequiredPermissions(["access:*:read"], userPermissions3)); // Output: true
console.log(hasRequiredPermissions(["projects:*:approve"], userPermissions3)); // Output: true

// Example 4: User has delete permissions for all projects, read, create, and update permissions for all access, and approve permissions for all projects.
console.log(hasRequiredPermissions(["projects:*:delete"], userPermissions4)); // Output: true
console.log(hasRequiredPermissions(["access:*:read"], userPermissions4)); // Output: true
console.log(hasRequiredPermissions(["projects:123:update"], userPermissions4)); // Output: false
console.log(hasRequiredPermissions(["projects:*:approve"], userPermissions4)); // Output: true
console.log(hasRequiredPermissions(["access:*:create"], userPermissions4)); // Output: true
console.log(hasRequiredPermissions(["access:*:update"], userPermissions4)); // Output: true

// Example 5: User has create permission for projects, create and update permission for access settings.
console.log(hasRequiredPermissions(["projects:*:create"], userPermissions5)); // Output: true
console.log(hasRequiredPermissions(["access:settings:create"], userPermissions5)); // Output: true
console.log(hasRequiredPermissions(["access:settings:update"], userPermissions5)); // Output: true
console.log(hasRequiredPermissions(["access:*:create"], userPermissions5)); // Output: false
*/

export default hasRequiredPermissions
