import {
	Alert,
	Box,
	Button,
	ColumnLayout,
	Container,
	Form,
	FormField,
	Header,
	SpaceBetween
} from '@cloudscape-design/components'
import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useOktaAuth } from '@okta/okta-react'

import FormInput from '../../../components/ControlledFormInputs/Input'
import FormMultiselect from '../../../components/ControlledFormInputs/Multiselect'
import FormRequiredTags from '../../../components/ControlledFormInputs/RequiredTags'
import LoadingSpinner from '../../../components/LoadingSpinner'
import FormInputArea from '../../../components/ControlledFormInputs/TextArea'

import { useEditPlatformFormStore } from '../../../stores/platforms/editPlatformFormStore'
import useSinglePlatform from '../../../hooks/query/useSinglePlatform'
import useEditPlatform from '../../../hooks/mutate/useEditPlatform'
import { PlatformEditRequest } from '../../../types/platforms'
import FormSelect from '../../../components/ControlledFormInputs/Select'
import FormDatePicker from '../../../components/ControlledFormInputs/FormDatePicker'

const ENVIRONMENT_LIST = [
	{ label: 'dv', value: 'dev' },
	{ label: 'st', value: 'staging' },
	{ label: 'pd', value: 'prod' }
]

const platformKeys = [
	'Platform Name',
	'Platform Alias',
	'Project Key',
	'Platform Owner',
	'WBS Code',
	'Cost Center',
	'System Type',
	'Department'
]
const EditPlatformForm: FC = () => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const { id } = useParams() as { id: string }

	const { data: originalPlatformRequest, isLoading: isLoadingSinglePlatform } =
		useSinglePlatform(id)

	const navigate = useNavigate()

	console.log('You are on the right page')
	useEffect(() => {
		if (
			typeof originalPlatformRequest === 'object' &&
			originalPlatformRequest !== null
		) {
			const prefetchedObject = {
				...originalPlatformRequest.details,
				commentText: ''
			}
			setOriginalRequestFormValues({
				formValues: prefetchedObject,
				isFormValid: true
			})
			reset(prefetchedObject)
		}
	}, [originalPlatformRequest])

	const {
		formValues,
		actions: { setFormValues, setFormValidity, setOriginalRequestFormValues }
	} = useEditPlatformFormStore()

	const {
		mutate: editPlatform,
		isLoading: isLoadingEditPlatform,
		isError: isErrorInEditReqeust
	} = useEditPlatform(id)

	const {
		control,
		reset,
		trigger,
		formState: { isValid, dirtyFields }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: { ...formValues.details, commentText: '' }
	})

	useEffect(() => {
		setFormValidity(isValid)
	}, [isValid, setFormValidity])

	const getOriginalObjVersion = () => {
		let deltaObject: any = {}
		const updatedFields: string[] = Object.keys(dirtyFields)
		for (let fieldName of updatedFields) {
			if (fieldName !== 'commentText')
				deltaObject[fieldName] =
					originalPlatformRequest?.details[fieldName as keyof object]
		}
		if (Object.keys(deltaObject).length !== 0) {
			deltaObject['lastUpdatedTime'] = originalPlatformRequest?.lastUpdatedTime
		}
		return deltaObject
	}

	const handleSubmit = async () => {
		await trigger()
		if (!isValid) return

		let formFieldValues = JSON.parse(JSON.stringify(formValues))
		let platformEditRequest: PlatformEditRequest = {
			details: formFieldValues,
			platformID: originalPlatformRequest?.platformID || '',
			requestorID: originalPlatformRequest?.requestorID || '',
			creationTime: originalPlatformRequest?.creationTime || '',
			action: 'UPDATE',
			version: getOriginalObjVersion(),
			commenterID: thisUserEmail,
			comment: formValues.commentText,
			status: 'PENDING_APPROVAL'
		}
		delete platformEditRequest.details.commentText

		editPlatform(platformEditRequest, {
			onSuccess: () => navigate('/platforms'),
			onError: (e) => {}
		})
	}

	if (!originalPlatformRequest || isLoadingSinglePlatform)
		return <LoadingSpinner />

	return (
		<>
			<Form
				actions={
					<SpaceBetween direction="horizontal" size="l">
						<Button
							variant="primary"
							onClick={handleSubmit}
							loading={isLoadingEditPlatform}
						>
							Submit
						</Button>
						<Button variant="link" onClick={() => navigate(-1)}>
							Cancel
						</Button>
					</SpaceBetween>
				}
				header={
					<Header variant="h1" description="">
						<SpaceBetween direction="horizontal" size="l">
							<Button
								variant="icon"
								iconName="arrow-left"
								onClick={() => navigate(-1)}
								key="icon"
							/>
							<div key="text">
								{originalPlatformRequest?.details.PlatformName}
							</div>
						</SpaceBetween>
					</Header>
				}
			>
				<SpaceBetween direction="vertical" size="l">
					<Container>
						<SpaceBetween direction="vertical" size="l">
							<FormSelect
								label="Environment"
								name="environment"
								control={control}
								rules={{
									required: 'Please Select Option'
								}}
								setFormValues={setFormValues}
								options={ENVIRONMENT_LIST}
								placeholder="Select Environment"
							/>
							<FormInput
								name="gcpProjectName"
								label="GCP Project Name"
								type="text"
								control={control}
								placeholder="Specify the desired name for the GCP project"
								setFormValues={setFormValues}
								rules={{
									required: 'Please enter GCP Project name',
									pattern: {
										value: /^(?!\s)(.*\S)?(?<!\s)$/,
										message: 'Leading and trailing whitespaces are not allowed.'
									}
								}}
							/>
							<FormInput
								name="projectEditor"
								label="Project Editor(s)"
								type="text"
								control={control}
								placeholder="Provide the email addresses of individuals who should
							have editor-level permissions for the project"
								setFormValues={setFormValues}
								rules={{
									required: 'Please enter email address',
									pattern: {
										value:
											/^(?:(?!\s)(.*\S)?(?<!\s)|[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+)$/,
										message:
											'Please enter a valid email address without leading or trailing whitespaces.'
									}
								}}
							/>
							<FormInput
								name="projectViewer"
								label="Project Viewer(s)"
								type="text"
								control={control}
								placeholder="Provide the email addresses of individuals who should
							have viewer-level permissions for the project"
								setFormValues={setFormValues}
								rules={{
									required: 'Please enter email address',
									pattern: {
										value:
											/^(?:(?!\s)(.*\S)?(?<!\s)|[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+)$/,
										message:
											'Please enter a valid email address without leading or trailing whitespaces.'
									}
								}}
							/>

							<FormMultiselect
								name="region"
								label="Region"
								control={control}
								placeholder="Select a region"
								options={[
									{
										label: 'Singapore',
										value: 'singapore',
										description: 'ap-southeast-1'
									}
								]}
								rules={{
									required: 'Please select at least one region',
									validate: (value) =>
										value.length > 0 || 'Select at least one region'
								}}
								setFormValues={setFormValues}
							/>

							<FormRequiredTags
								name="platformDetails"
								label="Platform Details"
								control={control}
								tagKeys={platformKeys}
								setFormValues={setFormValues}
								rules={{
									required: `Please enter the required value`,
									pattern: {
										value: /^(?!\s)(.*\S)?(?<!\s)$/,
										message: 'Leading and trailing whitespaces are not allowed.'
									}
								}}
							/>
							<FormField label="" description="">
								<ColumnLayout columns={3} variant="default">
									<FormInput
										name="budget"
										label="Platform Budget Allocated"
										description="In US Dollar (USD) currency"
										type="text"
										rules={{
											required: 'Please enter Platform Budget allocated',
											pattern: {
												value: /^\d+$/,
												message:
													'Please enter valid Platform Budget allocated without spaces'
											}
										}}
										control={control}
										setFormValues={setFormValues}
										placeholder="0"
									/>
									<FormDatePicker
										name="crfsDate"
										label="CRFS Date"
										description="Customer Ready for Service Date"
										rules={{
											required: 'Please Enter CRFS Date'
										}}
										control={control}
										setFormValues={setFormValues}
									/>
								</ColumnLayout>
							</FormField>
						</SpaceBetween>
					</Container>
					<Container
						header={<Header variant="h3">Comment History</Header>}
						footer={
							<SpaceBetween direction="vertical" size="l">
								<FormInputArea
									name="commentText"
									label=""
									description="Add comments here"
									control={control}
									rules={{
										required: 'Please enter your comments here',
										minLength: {
											value: 2,
											message: 'Minimum comment length should be 2'
										},
										maxLength: {
											value: 50,
											message: 'Maximum comment length should be 50'
										}
									}}
									setFormValues={setFormValues}
								/>
							</SpaceBetween>
						}
					></Container>
					{isErrorInEditReqeust && (
						<Container>
							<Box variant="p">
								<Alert
									statusIconAriaLabel="Error"
									type="error"
									header="Error Submitting Request, Please try again."
								/>
							</Box>
						</Container>
					)}
				</SpaceBetween>
			</Form>
		</>
	)
}

export default EditPlatformForm