import { FC } from 'react'
import { Container, Header, SpaceBetween } from '@cloudscape-design/components'

import GenericTagsNew from '../../../ControlledFormInputs/GenericTagsNew'

const Tags: FC = () => {
	return (
		<SpaceBetween direction="vertical" size="l">
			<Container header={<Header variant="h3"> Add Tags </Header>}>
				<GenericTagsNew />
			</Container>
		</SpaceBetween>
	)
}

export default Tags
