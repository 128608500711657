import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Table } from '@aws-northstar/ui/components'
import { Button, StatusIndicator } from '@cloudscape-design/components'

import LoadingSpinner from '../../components/LoadingSpinner'

import useProjects from '../../hooks/query/useProjects'
import type { Project } from '../../types/projects'

const Projects: FC = () => {
	const navigate = useNavigate()

	const { data: projects, isLoading } = useProjects()

	if (!projects || isLoading) return <LoadingSpinner />

	const columnDefinitions = [
		{
			id: 'projectID',
			width: 100,
			header: 'ID',
			cell: (data: any) => (
				<Button variant="link" onClick={() => navigate(data.projectID)}>
					{data.projectID}
				</Button>
			),
			sortingField: 'projectID'
		},
		{
			id: 'customProjectID',
			width: 100,
			header: 'Project ID',
			cell: (data: any) => (
				<Button variant="link" onClick={() => navigate(data.projectID)}>
					{data.details.customProjectID}
				</Button>
			),
			sortingField: 'customProjectID'
		},
		{
			id: 'projectName',
			width: 100,
			header: 'Project Name',
			cell: (data: Project) => {
				const projectName = data.details.projectName || data.details.workload || '';
				const projectNameStr = String(projectName);
				return projectNameStr.startsWith('atlas-') ? projectNameStr.replace('atlas-', '') : projectNameStr;
			}
			// sortingField: "projectName",
		},
		{
			id: 'requestor',
			width: 100,
			header: 'Requested by',
			cell: (data: Project) => data.requestorID || ''
			// sortingField: "requestor",
		},
		{
			id: 'time',
			width: 100,
			header: 'Created Time',
			//sortingField: 'time',
			cell: (data: Project) => {
				if (data.creationTime) {
					const date = new Date(data.creationTime)
					const year = date.getFullYear()
					const month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are 0-indexed
					const day = date.getDate().toString().padStart(2, '0')

					return `${day}-${month}-${year}`
				}
				return ''
			}
		},
		{
			id: 'status',
			width: 100,
			header: 'Status',
			cell: (data: Project) => {
				const status = data.status
				switch (status) {
					case 'PENDING_APPROVAL':
						return (
							<StatusIndicator type="in-progress" colorOverride="blue">
								Pending Approval
							</StatusIndicator>
						)
					case 'APPROVED':
						return <StatusIndicator type="success">Approved</StatusIndicator>
					case 'REJECTED':
						return <StatusIndicator type="error">Rejected</StatusIndicator>
					default:
						return null
				}
			}
		}
	]

	const tableActions = (
		<div style={{ display: 'flex', gap: '10px' }}>
			<Button variant="primary" onClick={() => navigate('/projects/new')}>
				New Project
			</Button>
			<Button variant="primary" onClick={() => navigate('/projects/ephemeral')}>
				New Ephemeral
			</Button>
		</div>
	)

	return (
		<Table
			header="Projects"
			columnDefinitions={columnDefinitions}
			items={projects}
			actions={tableActions}
			disableRowSelect
			sortingDisabled
			loading={isLoading}
		/>
	)
}

export default Projects
