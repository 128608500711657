import { FC } from 'react'
import { KeyValuePairs } from '@aws-northstar/ui'
import {
	Box,
	Container,
	Header,
	SpaceBetween
} from '@cloudscape-design/components'

import type { Application } from '../../../types/applications'

interface ApprovalDetailsProps {
	approvalDetails: Application
}

const ApprovalDetails: FC<ApprovalDetailsProps> = ({ approvalDetails }) => {
	const jsonDataString = JSON.stringify(approvalDetails)
	const jsonData = JSON.parse(jsonDataString)

	const generalDetailsKeyValuePairsItems = [
		[
			{
				label: 'Application ingress IP',
				value: jsonData.externalIP
			}
		],
		[
			{
				label: 'Application DNS',
				value: jsonData.hostname
			}
		],

		[
			{
				label: 'Application Namespace',
				value: jsonData.details.applicationName
			}
		],
		[
			{
				label: 'GCP Project ID',
				value: jsonData.projectId
			}
		]
	]

	return (
		<Container header={<Header variant="h3">Provisioning Details</Header>}>
			<SpaceBetween direction="vertical" size="l">
				<Box variant="h5">
					<KeyValuePairs
						key={'generalDetails'}
						items={generalDetailsKeyValuePairsItems}
					/>
				</Box>
			</SpaceBetween>
		</Container>
	)
}

export default ApprovalDetails
