import React, { FC, useState } from 'react'
import {
	Alert,
	ColumnLayout,
	Grid,
	Header,
	RadioGroup
} from '@cloudscape-design/components'
import { TagsApproval } from '../../../types/tags'
import useMultiStepApprovalTags from '../../../hooks/mutate/useMultiStepApprovalTags'
import { useOktaAuth } from '@okta/okta-react'
import useUserPermissions from '../../../hooks/query/useUserPermissions'
interface TagsApprovalProps {
	approvalStatus: Array<TagsApproval> | []
	isDisabled?: boolean
	tagsID?: string
	description?: string
}

const ApproverOverviewLocal: FC<TagsApprovalProps> = ({
	approvalStatus,
	isDisabled = false,
	tagsID = ''
}) => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const { mutate: updateMultiStepTagsById } = useMultiStepApprovalTags(tagsID)
	const { data: access } = useUserPermissions(thisUserEmail)

	const [notification, setNotification] = useState<any>({
		type: '',
		statusIconAriaLabel: '',
		msg: ''
	})
	const [showApprovalNotification, setShowApprovalNotification] =
		useState(false)

	const [approvalStatusLocal, setApprovalStatus] = useState(approvalStatus)

	const updateApprovalStatus = (state: any, tagkey: string) => {
		let updatedApprovalList: Array<TagsApproval> = []
		approvalStatusLocal.forEach((item) => {
			if (item.name === tagkey) {
				updatedApprovalList.push({
					...item,
					status:
						state.value === 'default' ? null : state.value === 'true' ? 1 : 0,
					approverID: thisUserEmail,
					lastUpdated: Date.now() + ''
				})
			} else {
				updatedApprovalList.push(item)
			}
		})

		setApprovalStatus(updatedApprovalList)

		updateMultiStepTagsById(
			{
				tagsID: tagsID,
				approvalStatus: updatedApprovalList.find(
					(item) => item.name === tagkey
				),
				action: 'UPDATE_APPROVER_STATUS'
			},
			{
				onSuccess: () => {
					console.log('success')
					setNotification({
						type: 'success',
						statusIconAriaLabel: 'Success',
						msg: 'Tags Approval Status Updated!'
					})
					setShowApprovalNotification(true)
					setTimeout(() => {
						setShowApprovalNotification(false)
					}, 3000)
				},
				onError: (e) => {
					setNotification({
						type: 'error',
						statusIconAriaLabel: 'Error',
						msg: 'Error saving Tags, Please try again!'
					})
					setShowApprovalNotification(true)
					setTimeout(() => {
						setShowApprovalNotification(false)
					}, 3000)
				}
			}
		)
	}
	return (
		<>
			<Header variant="h3" description="">
				Approval Status
			</Header>
			<ColumnLayout columns={2} variant="text-grid">
				{approvalStatusLocal &&
					approvalStatusLocal.map((approverItem) => {
						return (
							<Grid
								disableGutters
								gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
								key={approverItem.name}
							>
								<div color="text-body-secondary" key={approverItem.name}>
									{approverItem.name}
								</div>

								<RadioGroup
									onChange={({ detail }) => {
										updateApprovalStatus(detail, approverItem.name)
									}}
									value={
										approverItem.status === null
											? 'default'
											: Boolean(approverItem.status) === true
											? 'true'
											: 'false'
									}
									items={
										isDisabled
											? [
													{
														value: 'default',
														label: 'Pending Action',
														disabled: true
													},
													{
														value: 'true',
														label: 'Approved',
														disabled: true
													},
													{
														value: 'false',
														label: 'Rejected',
														disabled: true
													}
											  ]
											: [
													{
														value: 'default',
														label: 'Pending Action',
														disabled: true
													},
													{
														value: 'true',
														label: 'Approve',
														disabled: !access?.permissions?.includes(
															`tags:${approverItem.name}:*`
														)
													},
													{
														value: 'false',
														label: 'Reject',
														disabled: !access?.permissions?.includes(
															`tags:${approverItem.name}:*`
														)
													}
											  ]
									}
								/>
								<Grid>
									<div>
										{approverItem.status === 1 && <p>Approved By  :  {approverItem.approverID}</p>}
										{approverItem.status === 0 && <p>Rejected By :  {approverItem.approverID}</p>}
									</div>
								</Grid>
							</Grid>
						)
					})}
			</ColumnLayout>
			{showApprovalNotification && (
				<>
					<br />
					<Alert
						statusIconAriaLabel={notification.statusIconAriaLabel}
						type={notification.type}
					>
						{notification.msg}
					</Alert>
				</>
			)}
		</>
	)
}

const ApproverOverview = React.memo(ApproverOverviewLocal)
export default ApproverOverview
