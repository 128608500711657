import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'

import type { UpdateAccessRequest } from '../../types/access'
const { ACCESS_MANAGEMENT_API_URL } = CONSTANT

const updatePermissionsForUser = async (
	updateAccessRequest: UpdateAccessRequest
): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(
		`${ACCESS_MANAGEMENT_API_URL}`,
		{
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				action: 'UPDATE'
			},
			body: JSON.stringify(updateAccessRequest)
		}
	)

	return response.json()
}

export default updatePermissionsForUser
