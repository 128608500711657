import { FC } from 'react'

import { Header, Table } from '@cloudscape-design/components'

import type { AccountApproval } from '../../../types/account'

interface AccountApprovalDetailsProps {
	accountApprovalDetails: AccountApproval['details']
}

type _tableItem = {
	variable: string
	value: string
}

const AccountApprovalDetails: FC<AccountApprovalDetailsProps> = ({
	accountApprovalDetails
}) => {
	const columnDefinitions = [
		{
			id: 'variable',
			header: 'Variable',
			cell: (data: _tableItem) => data.variable,
			isRowHeader: true
		},
		{
			id: 'value',
			header: 'Value',
			cell: (data: _tableItem) => data.value
		}
	]

	return (
		<Table
			header={<Header variant="h3">Approval Details</Header>}
			columnDefinitions={columnDefinitions}
			items={formatForTableItems(accountApprovalDetails)}
		/>
	)
}

const formatForTableItems = (
	accountApprovalDetails: AccountApproval['details']
): Array<_tableItem> => {
	const formattedTableItems: Array<_tableItem> = []

	for (let key in accountApprovalDetails) {
		formattedTableItems.push({
			variable: key,
			value: JSON.stringify(accountApprovalDetails[key], null, 2).replaceAll(
				'"',
				''
			)
		})
	}

	return formattedTableItems
}

export default AccountApprovalDetails
