import { FC } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useForm } from 'react-hook-form'

import {
	Button,
	Container,
	Form,
	SpaceBetween
} from '@cloudscape-design/components'

import LoadingSpinner from '../../LoadingSpinner'
import FormRequiredTags from '../../ControlledFormInputs/RequiredTags'

import { useApprovalFormStore } from '../../../stores/accounts/approvalFormStore'
import useSingleAccount from '../../../hooks/query/useSingleAccount'
import useAcceptAccount from '../../../hooks/mutate/useAcceptAccount'
import type { AccountApprovalRequest } from '../../../types/account'
import useApproveTags from '../../../hooks/mutate/useApproveTags'
import { ApproveTags } from '../../../types/tags'

const GENERAL_TAG_KEYS = ['Platform Subgroup Name', 'SSO Display Names']

const NETWORK_TAG_KEYS = [
	'VPC Name',
	'VPC CIDR Block',
	'VPC Availability Zones',
	'Private Subnets CIDR',
	'TGW Subnets CIDR',
	'Request TGW Attachment',
	'Is Core VPC',
	'Create Private Hosted Zone',
	'Associate DNS Resolver Rules',
	'Private Hosted Zone Name',
	'Use Centralized VPC Interface Endpoints'
]

interface ApprovalFormProps {
	thisAccountRequestID: string
	shouldShowApprovalForm: (shouldShow: boolean) => void
}

const ApprovalForm: FC<ApprovalFormProps> = ({
	thisAccountRequestID,
	shouldShowApprovalForm
}) => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const {
		formValues,
		actions: { setFormValues, clearFormValues }
	} = useApprovalFormStore()

	const { mutate: approveTagsById } = useApproveTags('123')

	const {
		control,
		trigger,
		formState: { isValid }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: formValues
	})

	const { mutate: acceptAccount } = useAcceptAccount(thisAccountRequestID)

	const { data: thisAccount, isLoading: singleAccountDataLoading } =
		useSingleAccount(thisAccountRequestID)

	if (!thisAccount || singleAccountDataLoading) return <LoadingSpinner />

	const _handleApprove = () => {
		trigger()

		if (!isValid) return

		const approvalDetails = JSON.parse(JSON.stringify(formValues))
		approvalDetails['SSOUserNames'] = thisAccount.details.SSOUserNames.map(
			(user: { displayName: string; userName: string }, index: number) => {
				return {
					displayName: formValues.ssoDisplayNames.split(',')[index].trim(),
					userName: user.userName
				}
			}
		)

		const approvalRequest: AccountApprovalRequest = {
			accountRequestID: thisAccountRequestID,
			action: 'APPROVE',
			approverID: thisUserEmail,
			details: approvalDetails
		}

		const tagRequest: ApproveTags = {
			action: 'UPDATE_APPROVE_STATUS',
			status: 'APPROVED',
			tagsID: thisAccount?.tagsID || ''
		}
		approveTagsById(tagRequest, {
			onSuccess: () => {
				console.log('approveTagsById- Success')
			},
			onError: () => {
				console.log('approveTagsById Error')
			}
		})

		acceptAccount(approvalRequest, {
			onSuccess: () => {
				shouldShowApprovalForm(false)
				clearFormValues()
			}
		})
	}

	return (
		<Form
			actions={
				<SpaceBetween direction="horizontal" size="xs">
					<Button variant="link" onClick={() => shouldShowApprovalForm(false)}>
						Cancel
					</Button>
					<Button variant="primary" loading={false} onClick={_handleApprove}>
						Approve
					</Button>
				</SpaceBetween>
			}
		>
			<Container>
				<SpaceBetween direction="vertical" size="l">
					<FormRequiredTags
						name="generalDetails"
						label="General Details:"
						control={control}
						tagKeys={GENERAL_TAG_KEYS}
						setFormValues={setFormValues}
					/>

					<FormRequiredTags
						name="networkDetails"
						label="Network Details:"
						control={control}
						tagKeys={NETWORK_TAG_KEYS}
						setFormValues={setFormValues}
					/>

					{/* <FormRequiredTags
						name="accountDetails"
						label="Account Details:"
						control={control}
						tagKeys={ACCOUNT_TAG_KEYS}
						setFormValues={setFormValues}
					/> */}
				</SpaceBetween>
			</Container>
		</Form>
	)
}

export default ApprovalForm
