import { FC, useEffect } from 'react'
import { ColumnLayout } from '@cloudscape-design/components'
import FormInput from '../Input'
import { useForm } from 'react-hook-form'
import FormSelect from '../Select'
import { useGenericTagsManagementFormStore } from '../../../stores/tags/genericTagsManagementFormStore'
import { usegcpProjectCreationFormStore } from '../../../stores/gcpProjects/newgcpProjectCreationFormStore';
import CONSTANT from '../../../constants'
import PORTFOLIO_LIST from '../../../portfolio'
 
const { COST_TYPE_LIST, PLATFORM_LIST } = CONSTANT
 
const GenericTagsNew: FC = () => {
    const {
        formValues,
        actions: { setFormValues, setFormValidity }
    } = useGenericTagsManagementFormStore()

	const { formValues: projectformValues } = usegcpProjectCreationFormStore();
 
    const {
        control,
        formState: { isValid },
        setValue
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: formValues,
        shouldUseNativeValidation: true
    })
 
    useEffect(() => {
        setFormValidity(isValid)
    }, [isValid, setFormValidity])
 
    useEffect(() => {
        if (formValues?.fundingSourceType && formValues.fundingSourceType?.value === 'opex') {
            setValue('WBSCode', '')
			setFormValues('WBSCode', '')
        } else if (formValues?.fundingSourceType && formValues.fundingSourceType?.value === 'capex') {
            setValue('costCenter', '')
			setFormValues('costCenter', '')
        }
    }, [formValues.fundingSourceType, setValue, setFormValues])

	useEffect(() => {
		if ((formValues.projectName !== undefined) && (projectformValues.gcpProjectType === "")) {
			setValue('projectName', formValues.projectName)
		}
		else {
			const groupValue = formValues.group?.value ?? '';
			let projectType = '';
			if (projectformValues.gcpProjectType === 'Develop') {
				projectType = 'dev';
			} else if (projectformValues.gcpProjectType === 'Staging') {
				projectType = 'stg';
			} else if (projectformValues.gcpProjectType === 'Production') {
				projectType = 'prod';
			} else if (projectformValues.gcpProjectType === 'Sandbox') {
				projectType = 'sbx';
					}
			const projectNameValue = `glo-${groupValue}-${formValues.systemAlias}-project-${projectType}`;
			setValue('projectName', projectNameValue);
			setFormValues('projectName', projectNameValue);
		}
	}, [formValues.group?.value, formValues.systemAlias, formValues?.projectName, projectformValues.gcpProjectType, setValue, setFormValues]);


	useEffect(() => {
		setValue('platformOwner', formValues.platformOwner)
		setValue('platformName', formValues.platformName)
		setValue('projectName', formValues.projectName)
		setValue('group', formValues.group)
		setValue('fundingSourceType', formValues.fundingSourceType)
		setValue('costCenter', formValues.costCenter)
		setValue('WBSCode', formValues.WBSCode)
	}, [formValues, setValue])
 
    return (
      	<ColumnLayout columns={2} variant="default">
				<FormSelect
					name="platformName"
					label="Platform Name"
					control={control}
					rules={{
						required: 'Please Select Option'
					}}
					setFormValues={setFormValues}
					options={PLATFORM_LIST}
					placeholder="Select Platform "
				/>
				<FormInput
					name="platformOwner"
					label="Platform Owner"
					type="text"
					placeholder="Enter Platform Owner"
					control={control}
					setFormValues={setFormValues}
				/>
				<FormInput
					name="systemAlias"
					label="Platform Alias"
					type="text"
					placeholder="Example: hipms, namax"
					control={control}
					rules={{
						required: 'System alias should be 5 letters, Example: hipms',
						pattern: {
							value: /^[A-Za-z][A-Za-z]{4,}$/g,
							message: 'System alias should be 5 letters, Example: hipms'
						},
						minLength: {
							value: 5,
							message: 'System alias should be 5 letters, Example: hipms'
						},
						maxLength: {
							value: 5,
							message: 'System alias should be 5 letters, Example: namax'
						}
					}}
					setFormValues={setFormValues}
				/>
				<FormSelect
					label="Globe Group"
					name="group"
					control={control}
					rules={{
						required: 'Please Select Option'
					}}
					setFormValues={setFormValues}
					options={PORTFOLIO_LIST}
					placeholder="Select Group"
				/>
				<FormInput
					name="projectName"
					label="GCP Project Name"
					type="text"
					control={control}
					setFormValues={setFormValues}
					placeholder=""
					disabled={true}
				/>
				<br />
				<FormSelect
					label="Funding Source"
					name="fundingSourceType"
					control={control}
					rules={{
						required: 'Please Select Option'
					}}
					setFormValues={setFormValues}
					options={COST_TYPE_LIST}
					placeholder="Select Funding Source Type"
				/>
				<br/>
				<FormInput
					name="WBSCode"
					label="WBS Code"
					type="text"
					control={control}
					rules={{
						pattern: {
							value: /^[A-Z]{2,5}-[A-Z]{1,15}-[0-9]{2}(?:-[A-Z0-9]{2,})?(?:-[A-Z0-9]{2,})?$/g, // /^[A-Z]{2,5}-[A-Z]{1,15}-[0-9]{2}$/g,
							message: 'Please Enter all in CAPS, Ex. OP20-G,ISG24-G,PSD-OMN-21'
						},
						minLength: {
							value: 3,
							message: 'Minimum length should be 3'
						}
					}}
					setFormValues={setFormValues}
					placeholder="Example: ISG-PROJECTA-23, ISG-PROJECTB-24, EDO-PROJECTC-24-SW-01"
					disabled={
						formValues?.fundingSourceType &&
						formValues.fundingSourceType.value === 'opex'
					}
				/>
				<FormInput
					name="costCenter"
					label="Cost Center"
					type="text"
					control={control}
					rules={{
						pattern: {
							value: /^[A-Z0-9]{1,7}-[A-Z0-9]{1,7}$/g,
							message: 'Please Enter all in CAPS, Ex. OP20-G,ISG24-G,PSD-OMN-21'
						},
						minLength: {
							value: 3,
							message: 'Minimum length should be 3'
						}
					}}
					setFormValues={setFormValues}
					placeholder="Example: ISG17-A, OP20-G, MP-CIV-20, HTT7-B,ISG01,PSD-TECCF/19"
					disabled={
						formValues?.fundingSourceType &&
						formValues.fundingSourceType.value === 'capex'
					}
				/>
				</ColumnLayout>
	)
}
 
export default GenericTagsNew