import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { gcpProjectRequest } from '../../types/gcpProject'
import api from '../../api'

const useCreateNewGcpProject = () => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (newgcpProject: gcpProjectRequest) => {
			return api.gcpProjects.createNewgcpProject(newgcpProject)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['projects'] })
		}
	})
}

export default useCreateNewGcpProject