import { FC, PropsWithChildren } from 'react'
import { useOktaAuth } from '@okta/okta-react'

import AppLayoutBase from '@aws-northstar/ui/components/AppLayout'
const AppLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
	const { authState, oktaAuth } = useOktaAuth()
	const email = authState?.idToken?.claims.email as string
	const username = authState?.idToken?.claims.name as string
	const isSignedOut = !authState || !authState.isAuthenticated

	const signout = async (): Promise<void> => {
		await oktaAuth.signOut()
	}

	return (
		<AppLayoutBase
			title="Self Service Portal"
			toolsHide
			navigationItems={[
				{
					type: 'link',
					text: `Dashboard`,
					href: '/dashboard'
				},
				{ type: 'divider' },
				{
					type: 'section-group',
					title: 'Cloud Service Providers (CSPs)',
					items: [
						{
							type: 'section',
							text: 'AWS',
							items: [
								{
									type: 'link',
									text: 'Accounts',
									href: '/accounts'
								},
								{
									type: 'link',
									text: 'Projects',
									href: '/projects'
								}
							]
						},
						{
							type: 'section',
							text: 'GCP',
							items: [
								{
									type: 'link',
									text: 'Projects',
									href: '/gcpProjects'
								},
								{
									type: 'link',
									text: 'Platforms',
									href: '/platforms'
								},
								{
									type: 'link',
									text: 'Applications',
									href: '/applications'
								},
								{
									type: 'section',
									text: 'RHOCP',
									items: [
										{
											type: 'link',
											text: 'Tenants',
											href: '/rhocpTenants'
										}
									]
								}
							]
						}
					]
				},
				{ type: 'divider' },
				{
					type: 'section-group',
					title: 'Administration',
					items: [
						{
							type: 'section',
							text: 'Access Management',
							items: [
								{
									type: 'link',
									text: 'User Access',
									href: '/access-management'
								}
							]
						}
					]
				}
			]}
			user={{
				email,
				username
			}}
			onSignout={signout}
			breadcrumbGroupHide
			navigationHide={isSignedOut}
		>
			{children}
		</AppLayoutBase>
	)
}

export default AppLayout
