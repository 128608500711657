import { FC, useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import {
	Button,
	CodeEditor,
	Container,
	SpaceBetween
} from '@cloudscape-design/components'
import 'ace-builds/css/ace.css'
import 'ace-builds/css/theme/dawn.css'
import 'ace-builds/css/theme/tomorrow_night_bright.css'

const i18nStrings = {
	loadingState: 'Loading code editor',
	errorState: 'There was an error loading the code editor.',
	errorStateRecovery: 'Retry',

	editorGroupAriaLabel: 'Code editor',
	statusBarGroupAriaLabel: 'Status bar',

	cursorPosition: (row: number, column: number) => `Ln ${row}, Col ${column}`,
	errorsTab: 'Errors',
	warningsTab: 'Warnings',
	preferencesButtonAriaLabel: 'Preferences',

	paneCloseButtonAriaLabel: 'Close',

	preferencesModalHeader: 'Preferences',
	preferencesModalCancel: 'Cancel',
	preferencesModalConfirm: 'Confirm',
	preferencesModalWrapLines: 'Wrap lines',
	preferencesModalTheme: 'Theme',
	preferencesModalLightThemes: 'Light themes',
	preferencesModalDarkThemes: 'Dark themes'
}

const JWT: FC = () => {
	const { oktaAuth, authState } = useOktaAuth()
	const [value, setValue] = useState(JSON.stringify(authState, null, 2))
	const [preferences, setPreferences] = useState({})
	const [loading, setLoading] = useState(true)
	const [ace, setAce] = useState()

	useEffect(() => {
		async function loadAce() {
			const ace = await import('ace-builds')
			await import('ace-builds/webpack-resolver')
			ace.config.set('useStrictCSP', true)

			return ace
		}

		loadAce()
			// @ts-ignore
			.then((ace) => setAce(ace))
			.finally(() => setLoading(false))
	}, [])

	const logout = async () => oktaAuth.signOut()

	return (
		<Container>
			<h2>Protected</h2>
			<SpaceBetween direction="vertical" size="m">
				<CodeEditor
					ace={ace}
					value={value}
					language="javascript"
					onDelayedChange={(event) => setValue(event.detail.value)}
					preferences={preferences}
					onPreferencesChange={(event) => setPreferences(event.detail)}
					loading={loading}
					i18nStrings={i18nStrings}
					// should match the imports on top of this file
					themes={{ dark: ['dawn'], light: ['tomorrow_night_bright'] }}
				/>
				<Button variant="primary" onClick={logout}>
					Logout
				</Button>
			</SpaceBetween>
		</Container>
	)
}

export default JWT
