import { produce } from 'immer'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'

type ApprovalFormState = {
	isFormValid: boolean
	formValues: Record<string, any>
}

type ApprovalFormActions = {
	actions: {
		clearFormValues: () => void
		setFormValues: (fieldName: string, value: any) => void
		setFormValidity: (isValid: boolean) => void
	}
}

const initialFormValues = {}

const ApprovalFromStore = create<ApprovalFormState & ApprovalFormActions>(
	(set, _get) => ({
		isFormValid: false,
		formValues: initialFormValues,
		actions: {
			clearFormValues: () => set(() => ({ formValues: initialFormValues })),

			setFormValidity: (isValid) => set(() => ({ isFormValid: isValid })),

			setFormValues: (fieldName, value) =>
				set((state) =>
					produce(state, (draft) => {
						draft.formValues[fieldName] = value
					})
				)
		}
	})
)

export const useApprovalFormStore = () => ApprovalFromStore((state) => state)

if (process.env.NODE_ENV !== 'production') {
	mountStoreDevtool('AccountsApprovalFormStore', ApprovalFromStore)
}
