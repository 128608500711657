import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { PlatformRejectionRequest } from '../../types/platforms'
import api from '../../api'

const useRejectPlatform = (platformID: string) => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (platformRejection: PlatformRejectionRequest) => {
			return api.platforms.rejectPlatform(platformRejection)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['platforms'] })
			queryClient.invalidateQueries({ queryKey: ['platforms', platformID] })
		}
	})
}

export default useRejectPlatform
