import { FC } from 'react';

import { Header, Table } from '@cloudscape-design/components';

import type { gcpProjectApproval } from '../../../types/gcpProject';

interface gcpProjectApprovalDetailsProps {
    gcpProjectApprovalDetails: gcpProjectApproval['details'];
}

type _tableItem = {
    variable: string;
    value: string;
};

const gcpProjectApprovalDetails: FC<gcpProjectApprovalDetailsProps> = ({
    gcpProjectApprovalDetails
}) => {
    return null;
};

export default gcpProjectApprovalDetails