import {
	Box,
	Button,
	Checkbox,
	Container,
	Form,
	Header,
	Popover,
	SpaceBetween
} from '@cloudscape-design/components'
import Icon from '@cloudscape-design/components/icon'

import { useOktaAuth } from '@okta/okta-react'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import FormInput from '../../../components/ControlledFormInputs/Input'
import FormSelect from '../../../components/ControlledFormInputs/Select'

import { useNewPlatformFormStore } from '../../../stores/platforms/newPlatformFormStore'
import { useGenericTagsManagementFormStore } from '../../../stores/tags/genericTagsManagementFormStore'
import useCreateNewPlatform from '../../../hooks/mutate/useCreateNewPlatform'
import useApprovedGcpProjects from '../../../hooks/query/useApprovedGcpProjects'
import CONSTANTS from '../../../constants'
import { gcpProject } from '../../../types/gcpProject'
import type { PlatformRequest } from '../../../types/platforms'
import api from '../../../api'
import { getTagsApprovalObject, TagsRequest } from '../../../types/tags'
import { v4 as uuidv4 } from 'uuid'
import useCreateNewTags from '../../../hooks/mutate/useCreateNewTags'
import GcpPlatformTagsNew from './GcpPlatformNewTags'

const ENVIRONMENT_LIST = [
	{ label: 'dev', value: 'dev' },
	{ label: 'staging', value: 'staging' },
	{ label: 'prod', value: 'prod' }
]

const SIZE_LIST = [
	{ label: 'small', value: 'small' },
	{ label: 'medium', value: 'medium' },
	{ label: 'large', value: 'large' }
]

const TYPE_LIST = [
	{ label: 'standard', value: 'standard' },
	{ label: 'autopilot', value: 'autopilot' }
]

const NewPlatformForm: FC = () => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const navigate = useNavigate()
	const {
		formValues,
		actions: { setFormValues, clearFormValues }
	} = useNewPlatformFormStore()

	const { formValues: tagFormValues,
		actions: { setFormValues: setTagFormValues, clearFormValues: clearTagFormValues }
	 } = useGenericTagsManagementFormStore()
	
	const { mutate: createNewPlatform, isLoading } = useCreateNewPlatform()
	const { mutate: createTags, isLoading: isCreateTagsLoading } = useCreateNewTags()
	const {
		control,
		setValue,
		trigger,
		formState: { isValid }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: formValues
	})

	const { data: gcpProjects, isLoading: isGcpProjectsLoading} = useApprovedGcpProjects()

	const [isInheritTagsChecked, setInheritTagsChecked] = useState(false)
	const [isGcpProjectIdFilled, setGcpProjectIdFilled] = useState(false)

	const [popFields, setPopFields] = useState(false)

	useEffect(() => {
		if (formValues.gcpProjectID !== undefined) {
			setGcpProjectIdFilled(true)
		}
		else {
			setGcpProjectIdFilled(false)
		}
	}, [formValues.gcpProjectID])

	useEffect(() => {
		const getProjectWithTags = async (gcpProjectID: {label: string, value: string}) => {
			let response = await api.gcpProjects.getSinglegcpProject(gcpProjectID.value, true)
			let project = response.body[0] as gcpProject
			console.log(project)

			setTagFormValues('platformName', project.tags?.details.platformName)
			setTagFormValues('group', project.tags?.details.group)
			setTagFormValues('fundingSourceType', project.tags?.details.fundingSourceType)
			setTagFormValues('WBSCode', project.tags?.details.WBSCode)
			setTagFormValues('costCenter', project.tags?.details.costCenter)
			setTagFormValues('projectName', gcpProjectID.label)

			setFormValues('wbsCode', project.tags?.details.WBSCode)
			setFormValues('costCenter', project.tags?.details.costCenter)
			setFormValues('group', project.tags?.details.group)
			setValue('group', project.tags?.details.group)
		}
		if (isInheritTagsChecked) {
			console.log(formValues.gcpProjectID)
			getProjectWithTags(formValues.gcpProjectID)
		}
		else {
			clearTagFormValues()
			
			setFormValues('wbsCode', '')
			setFormValues('costCenter', '')
			setFormValues('group', '')
			setValue('group', '')
		}
	},
	[formValues.gcpProjectID, 
		setTagFormValues,
		setValue,
		isInheritTagsChecked, 
		clearTagFormValues, setFormValues]
	)

	useEffect(() => {
		if (tagFormValues.WBSCode !== "") {
			setFormValues('wbsCode', tagFormValues.WBSCode)
		}

		if (tagFormValues.costCenter !== "") {
			setFormValues('costCenter', tagFormValues.costCenter)
		}
	},[tagFormValues.WBSCode, tagFormValues.costCenter, setFormValues])

	useEffect(() => {
		if (formValues.platformOwner !== "") {
			setTagFormValues('platformOwner', formValues.platformOwner)
		}

		if (formValues.platformName !== "") {
			setTagFormValues('platformName', formValues.platformName)
		}

		if (formValues.systemAlias !== "") {
			setTagFormValues('systemAlias', formValues.systemAlias)
		}
	}, [formValues, setTagFormValues])


	const handleSubmit = async () => {
		await trigger()
		if (!isValid) return

		let tagsId = uuidv4()

		let newTag: TagsRequest = {
			requestorID: thisUserEmail,
			tagsID: tagsId,
			approvalStatus: getTagsApprovalObject(),
			details: tagFormValues,
			action: 'CREATE',
			status: 'PENDING_APPROVAL'
		}

		const platform: PlatformRequest = {
			requestorID: thisUserEmail,
			tagsID: tagsId,
			action: 'CREATE',
			details: formValues,
			status: 'PENDING_APPROVAL',
			creationTime: new Date().toISOString()
		}

		createTags(newTag, {
			onSuccess: () => {
				createNewPlatform(platform, {
					onSuccess: () => {
						navigate('/platforms')
						clearFormValues()
						clearTagFormValues()
						window.location.reload()
					},
					onError: (e) => console.error(e)
				})
			},
			onError: (e) => console.error(e)
		})
	}
	useEffect(() => {
		setValue('region', 'Singapore (asia-southeast1)')
	}, [setValue])

	useEffect(() => {
		if (formValues?.platformType?.value === 'standard') {
		  setPopFields(true)
		} else {
		  setPopFields(false)
		}
	}, [formValues?.platformType?.value, setPopFields, formValues])

	return (
		<Form
			actions={
				<SpaceBetween direction="horizontal" size="xs">
					<Box>
						<Button
							variant="primary"
							onClick={handleSubmit}
							loading={isLoading || isCreateTagsLoading}
						>
							Submit
						</Button>
						<Button variant="link" onClick={() => navigate(-1)}>
							Cancel
						</Button>
					</Box>
				</SpaceBetween>
			}
			header={
				<Header variant="h1" description="">
					Request a New Platform
				</Header>
			}
		>
			<SpaceBetween direction="vertical" size="s">
				<Container>
					<SpaceBetween direction="vertical" size="l">
						<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
							<div style={{ width: '650px' }}>
								<FormInput
									name="platformName"
									label="Platform Name"
									type="text"
									control={control}
									placeholder="Enter Platform Name"
									setFormValues={setFormValues}
									description="Max 20 Characters"
									rules={{
										required: 'Please enter platform name',
										maxLength: {
											value: 20,
											message: 'Maximum length is 20 characters'
										},
										pattern: {
											value: /^[a-z0-9-]+$/,
											message:
												'Platform name should be lowercase only and whitespaces are not allowed.'
										}
									}}
								/>
							</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
							<div style={{ width: '650px' }}>
								<FormInput
									name="systemAlias"
									label="Platform Alias"
									type="text"
									control={control}
									setFormValues={setFormValues}
									placeholder="Enter System Alias"
									description="Short name of platform."
									rules={{
										required: 'Please enter system alias',
										minLength: {
											value: 5,
											message: 'Minumum length of 5 characters'
										},
										pattern: {
											value: /^[a-z0-9]+$/,
											message:
												'Platform name should be lowercase only and whitespaces are not allowed.'
										}
									}}/>
							</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
							<div style={{ width: '650px' }}>
								<FormInput
									name="platformOwner"
									label="Platform Owner"
									type="text"
									control={control}
									placeholder="Enter Platform Owner"
									setFormValues={setFormValues}
									description="For the Platform Owner enter the name of the person who will own the platform (not email, just the name)"
									rules={{
										required: 'Please enter platform owner',
										pattern: {
											value: /^[a-z-]+$/,
											message:
												'Platform Owner should be lowercase only and no mumbers allowed and whitespaces are not allowed.'
										}
									}}
								/>
							</div>
						</div>
						<div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
							<div style={{width: '650px'}}>
								<FormInput
									name="platformOwnerEmail"
									label="Platform Owner Email"
									type="email"
									control={control}
									setFormValues={setFormValues}
									description="To be used for the notification of the platform owner"
									placeholder="Enter Platform Owner Email"
									rules={{
										required: 'Please enter platform owner email',
										pattern: {
											value: /^[a-z0-9._%+-]+@globe\.com\.ph/,
											message: 'Please enter a valid Globe Telecom email address'
										}
									}}/>
							</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
							<div style={{ width: '650px' }}>
								<FormSelect
									name="gcpProjectID"
									label="GCP Project"
									description="Name of GCP project where platform will be deployed"
									control={control}
									placeholder="Select GCP Project"
									setFormValues={setFormValues}
									statusType={isGcpProjectsLoading ? 'loading' : 'finished'}
									options={gcpProjects?.map((p) => ({ label: p.gcpProjectName, value: p.gcpProjectID })) || []}
									rules={{
										required: 'Please select a GCP Project'
									}} />
							</div>
							<div style={{ marginLeft: '10px', marginTop: '25px' }}>
								<Popover
									fixedWidth
									header="Platform GCP Project ID tip"
									position="right"
									triggerType="custom"
									content={
										<p>
											Please see guide on how to enter your GCP Project ID:
											<a
												href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/asp/developer-enablement/platform-onboarding/identifying_gcp_project/`}
												target="_blank"
												rel="noopener noreferrer"
												aria-label="Plus"
												style={{
													marginLeft: '10px',
													marginTop: '25px'
												}}
											>
												LINK
											</a>
										</p>
									}
								>
									<Icon name="status-info" />
								</Popover>
							</div>
						</div>
						<div style={{ display: 'flex', flexWrap: 'wrap' }}>
							<Checkbox checked={isInheritTagsChecked}
								onChange={({ detail }) => { setInheritTagsChecked(detail.checked) }}
								disabled={!isGcpProjectIdFilled || isGcpProjectsLoading}>
								Use GCP Project Tags
							</Checkbox>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap'  }}>
							<div style={{ width: '650px' }}>
								<FormSelect
									label="Environment"
									name="environment"
									control={control}
									rules={{
										required: 'Please Select Option'
									}}
									description="GKE Cluster Environment (Dev, Staging, Prod)
							Recommendation: Always start with DEV env first, followed by STG, then PRD"
									setFormValues={setFormValues}
									options={ENVIRONMENT_LIST}
									placeholder="Select Environment"
								/>
							</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap'  }}>
							<div style={{ width: '650px' }}>
								<FormInput
									name="region"
									label="Region"
									type="text"
									control={control}
									setFormValues={setFormValues}
									description="Region of your GKE Cluster (by default Singapore)"
									disabled={true}
								/>
							</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
							<div style={{ width: '650px' }}>
								<FormInput
									name="platformViewer"
									label="Platform Viewer(s)"
									type="text"
									control={control}
									description="Email addresses for viewer-level permissions on the project (separated by commas)"
									placeholder="Enter Email Addresses"
									setFormValues={setFormValues}
									rules={{
										required: 'Please enter email address',
										pattern: {
											value:
												/^[a-z0-9._%+-]+@globe\.com\.ph(?:,[a-z0-9._%+-]+@globe\.com\.ph)*$/,
											message:
												'Please enter valid email addresses separated by commas, without any whitespaces, and ending with @globe.com.ph.'
										}
									}}
								/>
							</div>
						</div>

						<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
							<div style={{ width: '650px' }}>
								<FormSelect
									name="platformType"
									label="Platform Type"
									control={control}
									rules={{
										required: 'Please Select Option'
									}}
									setFormValues={setFormValues}
									options={TYPE_LIST}
									placeholder="Select GKE Cluster Type"
									description="Select cluster type based on the tool tip (Standard, Autopilot)"
								/>
							</div>
							<div style={{ marginLeft: '10px', marginTop: '38px' }}>
								<Popover
									fixedWidth
									header="Platform Type tool tip"
									position="right"
									triggerType="custom"
									content={
										<p>
											Please see guide on how to select your platform type:
											<a
												href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/asp/developer-enablement/platform-onboarding/platform_tshirt_type_size/`}
												target="_blank"
												rel="noopener noreferrer"
												aria-label="Plus"
												style={{
													marginLeft: '10px',
													marginTop: '25px'
												}}
											>
												LINK
											</a>
										</p>
									}
								>
								</Popover>
							</div>
						</div>
						{popFields && (
							<>
								<div style={{ maxWidth: 660 }}>
									<FormInput
										label="Minimum Nodes"
										name="minNode"
										control={control}
										type="number"
										setFormValues={setFormValues}
										disabled={false}
										placeholder="Add Minimum Nodes"
										rules={{
											required: 'Please enter a value',
											min: {
												value: 1,
												message: 'Minimum value is 1'
											}
										}}
									/>
								</div>
								<div style={{ maxWidth: 660 }}>
									<FormInput
										name="maxNode"
										label="Maximum Nodes"
										placeholder="Add Maximum Nodes"
										type="number"
										control={control}
										setFormValues={setFormValues}
										disabled={false}
										rules={{
											required: 'Please enter a value',
											min: {
												value: 1,
												message: 'Minimum value is 1'
											}
										}}
									/>
								</div>
								<div style={{ maxWidth: 660 }}>
								<FormInput
										name="machineType"
										label="Machine Type"
										placeholder="Machine Type"
										type="text"
										control={control}
										setFormValues={setFormValues}
										disabled={false}
										rules={{
											required: 'Please enter a value',
											pattern: {
												value: /^[a-zA-Z][a-zA-Z0-9-]*$/,
												message: 'Machine Type must be alphanumeric'
											}
										}}
									/>
								</div>
							</>
						)}

						<div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'  }}>
							<div style={{ width: '650px' }}>
								<FormSelect
									name="platformSize"
									label="Platform Size"
									control={control}
									rules={{
										required: 'Please Select Option'
									}}
									setFormValues={setFormValues}
									options={SIZE_LIST}
									placeholder="Select Cluster Size"
									description="Select cluster size based on the tool tip (Small, Medium, Large)"
								/>
							</div>
							<div style={{ marginLeft: '10px', marginTop: '38px' }}>
								<Popover
									fixedWidth
									header="Platform Cluster Size tool tip"
									position="right"
									triggerType="custom"
									content={
										<p>
											Please see guide on how to select the size for your GKE
											cluster:
											<a
												href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/asp/developer-enablement/platform-onboarding/platform_tshirt_type_size/`}
												target="_blank"
												rel="noopener noreferrer"
												aria-label="Plus"
												style={{
													marginLeft: '10px',
													marginTop: '25px'
												}}
											>
												LINK
											</a>
										</p>
									}
								>
									<Icon name="status-info" />
								</Popover>
							</div>
						</div>
					</SpaceBetween>
				</Container>

				<Container
					header={<Header variant='h3'>Tag Details</Header>}>
					<GcpPlatformTagsNew/>
				</Container>

			</SpaceBetween>
		</Form>
	)
}

export default NewPlatformForm