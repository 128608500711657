import { FC, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'

import {
	Button,
	Container,
	Form,
	SpaceBetween,
	Textarea
} from '@cloudscape-design/components'

import { ApplicationRejectionRequest } from '../../../types/applications'
import useRejectApplication from '../../../hooks/mutate/useRejectApplication'

interface RejectionFormProps {
	thisApplicationID: string
	shouldShowRejectionForm: (shouldShow: boolean) => void
}

const RejectionForm: FC<RejectionFormProps> = ({
	thisApplicationID,
	shouldShowRejectionForm
}) => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const [commentText, setCommentText] = useState('')

	const { mutate: rejectApplication, isLoading } =
		useRejectApplication(thisApplicationID)

	const _handleReject = () => {
		const rejectionRequest: ApplicationRejectionRequest = {
			action: 'REJECT',
			applicationID: thisApplicationID,
			commenterID: thisUserEmail,
			comment: commentText
		}

		rejectApplication(rejectionRequest, {
			onSuccess: () => {
				shouldShowRejectionForm(false)
				setCommentText('')
			}
		})
	}

	return (
		<Form
			actions={
				<SpaceBetween direction="horizontal" size="xs">
					<Button variant="link" onClick={() => shouldShowRejectionForm(false)}>
						Cancel
					</Button>
					<Button variant="primary" loading={isLoading} onClick={_handleReject}>
						Reject
					</Button>
				</SpaceBetween>
			}
		>
			<Container>
				<SpaceBetween direction="vertical" size="l">
					<Textarea
						onChange={({ detail }) => setCommentText(detail.value)}
						value={commentText}
						placeholder="Reason for rejection"
					/>
				</SpaceBetween>
			</Container>
		</Form>
	)
}

export default RejectionForm
