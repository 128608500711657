import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'

import { Access } from '../../types/access'
const { ACCESS_MANAGEMENT_API_URL } = CONSTANT

const getUserRoles = async (
	userEmail: string
): Promise<{
	status: number
	body: Array<Access>
}> => {
	const response = await fetchWithAuthorizationHeader(
		`${ACCESS_MANAGEMENT_API_URL}?userEmail=${userEmail}`
	)
	return response.json()
}

export default getUserRoles
