import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import Wizard, { WizardProps } from '@cloudscape-design/components/wizard';

import Step1 from '../../../components/gcpProjectCreation/Steps/Step1';
import Step2 from '../../../components/gcpProjectCreation/Steps/Step2';
import Tags from '../../../components/gcpProjectCreation/Steps/Tags';
import Review from '../../../components/gcpProjectCreation/Steps/Review';

import { usegcpProjectCreationFormStore } from '../../../stores/gcpProjects/newgcpProjectCreationFormStore';
import { gcpProjectRequest } from '../../../types/gcpProject';
import useCreateNewGcpProject from '../../../hooks/mutate/useCreateNewGcpProject';

import { useGenericTagsManagementFormStore } from '../../../stores/tags/genericTagsManagementFormStore';
import useCreateNewTags from '../../../hooks/mutate/useCreateNewTags';
import { TagsRequest, getTagsApprovalObject } from '../../../types/tags';
import { v4 as uuidv4 } from 'uuid';

const i18nStrings: WizardProps.I18nStrings = {
	stepNumberLabel: (stepNumber: number) => `Step ${stepNumber}`,
	collapsedStepsLabel: (stepNumber: number, stepsCount: number) =>
		`Step ${stepNumber} of ${stepsCount}`,
	skipToButtonLabel: (step: { title: string }, _stepNumber: number) =>
		`Skip to ${step.title}`,
	navigationAriaLabel: 'Steps',
	cancelButton: 'Cancel',
	previousButton: 'Previous',
	nextButton: 'Next',
	submitButton: 'Submit',
	optional: 'optional'
};

const steps: Array<WizardProps.Step> = [
	{
		title: 'Project Types',
		content: <Step1 />
	},
	{
		title: 'Project Details',
		content: <Step2 />
	},
	{
		title: 'Tags Details',
		content: <Tags />
	},
	{
		title: 'Review',
		content: <Review />
	}
];

const convertFormValuesTogcpProjectRequestDetails = (
	formValues: Record<any, any>
): Record<string, any> => {
	const details: Record<string, any> = {};

	const gcpProjectType = formValues.gcpProjectType;

	details[gcpProjectType] = {
		projectViewerDistributionList: formValues[`${gcpProjectType.value}ProjectViewerDistributionList`],
		budgetAlertsDistributionList: formValues[`${gcpProjectType.value}BudgetAlertsDistributionList`],
		monthlyBudgetCap: formValues[`${gcpProjectType.value}MonthlyBudgetCap`]
	};

	return details;
};

const GcpProjectCreationForm: FC = () => {
	const { authState } = useOktaAuth();
	const thisUserEmail = authState?.idToken?.claims.email as string;

	const navigate = useNavigate();

	const gcpProjectCreationFormStore = usegcpProjectCreationFormStore();
	const tagCreationFormStore = useGenericTagsManagementFormStore();

	const { mutate: createNewgcpProject, isLoading: isLoadinggcpProject } = useCreateNewGcpProject();
	const { mutate: createTags, isLoading: isLoadingTags } = useCreateNewTags();

	const handleSubmit = () => {
		let tagsID = '';

		const gcpProjectList = Object.keys(
			convertFormValuesTogcpProjectRequestDetails(
				gcpProjectCreationFormStore.formValues
			)
		);
		gcpProjectList.forEach((gcpProject) => {
			tagsID = tagsID === '' ? uuidv4() : tagsID + ',' + uuidv4();
		});

		const gcpProjectRequest: gcpProjectRequest = {
			requestorID: thisUserEmail,
			action: 'CREATE',
			status: 'PENDING_APPROVAL',
			tagsID: tagsID,
			details: convertFormValuesTogcpProjectRequestDetails(
				gcpProjectCreationFormStore.formValues
			)
		};

		const tagRequest: TagsRequest = {
			requestorID: thisUserEmail,
			action: 'CREATE',
			status: 'PENDING_APPROVAL',
			details: tagCreationFormStore.formValues,
			tagsID: tagsID,
			approvalStatus: getTagsApprovalObject()
		};

		createTags(tagRequest, {
			onSuccess: () => {
				createNewgcpProject(gcpProjectRequest, {
					onSuccess: () => navigate('/gcpProjects')
				});
			}
		});
	};

	const handleCancel = () => {
		gcpProjectCreationFormStore.actions.clearFormValues();
		gcpProjectCreationFormStore.actions.setCurrentStep(0);
		navigate(-1);
	};

	const handleWizardNavigate = ({
		detail
	}: {
		detail: WizardProps.NavigateDetail
	}) => {
		if (detail.reason === 'next') {
			if (!gcpProjectCreationFormStore.isFormValid) return;
		}
		if ('' + detail.requestedStepIndex === '3') {
			if (!tagCreationFormStore.isFormValid) return;
		}

		gcpProjectCreationFormStore.actions.setCurrentStep(detail.requestedStepIndex);
	};

	return (
		<Wizard
			i18nStrings={i18nStrings}
			steps={steps}
			activeStepIndex={gcpProjectCreationFormStore.currentStep}
			onNavigate={handleWizardNavigate}
			onSubmit={handleSubmit}
			isLoadingNextStep={isLoadinggcpProject && isLoadingTags}
			onCancel={handleCancel}
		/>
	);
};

export default GcpProjectCreationForm;
