import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
import type { AccountRequest } from '../../types/account'

const { ACCOUNTS_API_URL } = CONSTANT

const createNewAccount = async (newAccount: AccountRequest): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(`${ACCOUNTS_API_URL}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			action: 'CREATE'
		},
		body: JSON.stringify(newAccount)
	})

	return response.json()
}

export default createNewAccount
