import { FormEventHandler } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import {
	Button,
	Checkbox,
	CheckboxProps,
	Container,
	Form,
	FormField,
	Header,
	Input,
	InputProps,
	NonCancelableCustomEvent,
	SpaceBetween,
	Textarea
} from '@cloudscape-design/components'
import useDevOkkarForm from '../../stores/devOkkarFormStore'

const DevOkkar = () => {
	const { authState } = useOktaAuth()
	const formState = useDevOkkarForm()

	const handleOnChange = <
		T extends Partial<InputProps.ChangeDetail & CheckboxProps.ChangeDetail>
	>(
		fieldName: string,
		event: NonCancelableCustomEvent<T>
	) => {
		event.detail.value
			? // for any other input
			  formState.actions.updateField(fieldName, event.detail.value!)
			: // for checkbox
			  formState.actions.updateField(fieldName, event.detail.checked!)
	}

	const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
		event.preventDefault()
		alert(JSON.stringify(formState.formFields, null, 2))
	}

	return (
		<SpaceBetween direction={'vertical'} size={'m'}>
			<Container header={<Header variant={'h3'}>Okta's response</Header>}>
				<Textarea readOnly value={JSON.stringify(authState, null, 1)} />
			</Container>

			<form onSubmit={handleSubmit}>
				<Form
					actions={
						<SpaceBetween direction="horizontal" size="xs">
							<Button formAction="none" variant="link">
								Cancel
							</Button>
							<Button variant="primary">Submit</Button>
						</SpaceBetween>
					}
				>
					<Container
						header={<Header variant="h3">Form with two fields</Header>}
					>
						<SpaceBetween direction="vertical" size="l">
							<FormField label="First field">
								<Input
									name="firstField"
									onChange={(event) => handleOnChange('firstField', event)}
									value={formState.formFields.firstField}
								/>
							</FormField>

							<FormField label="Second field">
								<Checkbox
									name="secondField"
									onChange={(event) => handleOnChange('secondField', event)}
									checked={formState.formFields.secondField}
								/>
							</FormField>
						</SpaceBetween>
					</Container>
				</Form>
			</form>
		</SpaceBetween>
	)
}

export default DevOkkar
