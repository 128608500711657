import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'

import type { CreateAccessRequest } from '../../types/access'
const { ACCESS_MANAGEMENT_API_URL } = CONSTANT

const createPermissionsForUser = async (
	createAccessRequest: CreateAccessRequest
): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(
		`${ACCESS_MANAGEMENT_API_URL}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				action: 'CREATE'
			},
			body: JSON.stringify(createAccessRequest)
		}
	)

	return response.json()
}

export default createPermissionsForUser
