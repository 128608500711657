import { produce } from "immer"
import { create } from "zustand"
import { mountStoreDevtool } from "simple-zustand-devtools"

type ApprovalFormState = {
  isFormValid: boolean
  formValues: Record<any, any>
}

type ApprovalFormActions = {
  actions: {
    clearFormValues: () => void
    setFormValues: (fieldName: string, value: any) => void
    setFormValidity: (isValid: boolean) => void
  }
}

const initialFormValues = {
  jiraTicket: ''
}

const ApprovalFormStore = create<ApprovalFormState & ApprovalFormActions>(
  (set, _get) => ({
    isFormValid: false,
    formValues: initialFormValues,
    actions: {
      clearFormValues: () => set(() => ({ formValues: initialFormValues })),
      setFormValidity: (isValid) => set(() => ({ isFormValid: isValid })),
      setFormValues: (fieldName, value) =>
        set((state) => produce(state, (draft) => {
          draft.formValues[fieldName] = value
        }))
    }
  })
)

export const useApprovalFormStore = () => ApprovalFormStore((state) => state)

if (process.env.NODE_ENV !== 'production') {
  mountStoreDevtool('ApprovalFormStore', ApprovalFormStore)
}