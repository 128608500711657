import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'

const { GCPPROJECTS_API_URL } = CONSTANT

const getProjects = async (getApprovedProjects: boolean): Promise<{
	status: number
	body: Array<object>
}> => {	
	let url = ""
	
	if (getApprovedProjects === true) {
		url = `${GCPPROJECTS_API_URL}?getApprovedProjects=true`
	}
	else {
		url = `${GCPPROJECTS_API_URL}`
	}

	const response = await fetchWithAuthorizationHeader(url)
	if (response.status !== 200) {
		return {
			status: response.status,
			body: []
		}
	}
	return response.json()
}

export default getProjects
