import { useNavigate } from 'react-router-dom'
import { Cards, Link } from '@cloudscape-design/components'

const cards = [
	{
		name: 'Accounts',
		description: 'You can manage accounts here.',
		href: '/accounts'
	},
	{
		name: 'Projects',
		description: 'You can manage project creation here.',
		href: '/projects'
	},
	{
		name: 'Access Management',
		description: 'You can manage users and roles here.',
		href: '/access-management'
	}
]

const Dashboard = () => {
	const navigate = useNavigate()

	return (
		<Cards
			items={cards}
			cardDefinition={{
				header: (item) => (
					<Link fontSize="heading-m" onFollow={() => navigate(item.href)}>
						{item.name}
					</Link>
				),
				sections: [
					{
						header: 'Description:',
						content: (item) => item.description
					}
				]
			}}
		></Cards>
	)
}

export default Dashboard
