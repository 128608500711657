import CONSTANTS from "../../constants";
import fetchWithAuthorizationHeader from "../../helper/fetchWithAuthorizationHeader";

const { RHOCPTENANTS_API_URL } = CONSTANTS

const getRhocpTenants = async (): Promise<{ status: number, body: Array<object> }> => {
  const response = await fetchWithAuthorizationHeader(`${RHOCPTENANTS_API_URL}`)
  if (response.status !== 200) {
    return {
      status: response.status,
      body: []
    }
  }

  return response.json()
}

export default getRhocpTenants